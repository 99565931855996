import React, { useMemo } from 'react'
import * as Dropdown from '@radix-ui/react-dropdown-menu'
import { LuHeading1, LuHeading2, LuHeading3, LuList, LuListOrdered, LuListTodo, LuPilcrow } from 'react-icons/lu'
import { HiChevronDown } from 'react-icons/hi2'
import { DropdownButton } from '../../../ui/Dropdown'
import { Editor } from '@tiptap/react'
import { Card } from '@radix-ui/themes'
import { ToolbarButton } from '../../../ui/ToolbarButton'

export type ContentTypePickerOption = {
  label: string
  id: string
  type: 'option'
  disabled: () => boolean
  isActive: () => boolean
  onClick: () => void
  icon: React.ReactNode
}

export type ContentTypePickerCategory = {
  label: string
  id: string
  type: 'category'
}

export type ContentPickerOptions = Array<ContentTypePickerOption | ContentTypePickerCategory>

export type ContentTypePickerProps = {
  options: ContentPickerOptions
}

const isOption = (option: ContentTypePickerOption | ContentTypePickerCategory): option is ContentTypePickerOption =>
  option.type === 'option'
const isCategory = (option: ContentTypePickerOption | ContentTypePickerCategory): option is ContentTypePickerCategory =>
  option.type === 'category'

export const ContentTypePicker = ({ editor }: { editor: Editor }) => {
  const options = useMemo<ContentPickerOptions>(() => {
    return [
      {
        type: 'category',
        label: 'Hierarchy',
        id: 'hierarchy',
      },
      {
        icon: <LuPilcrow />,
        onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setParagraph().run(),
        id: 'paragraph',
        disabled: () => !editor.can().setParagraph(),
        isActive: () =>
          editor.isActive('paragraph') &&
          !editor.isActive('orderedList') &&
          !editor.isActive('bulletList') &&
          !editor.isActive('taskList'),
        label: 'Paragraph',
        type: 'option',
      },
      {
        icon: <LuHeading1 />,
        onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setHeading({ level: 1 }).run(),
        id: 'heading1',
        disabled: () => !editor.can().setHeading({ level: 1 }),
        isActive: () => editor.isActive('heading', { level: 1 }),
        label: 'Heading 1',
        type: 'option',
      },
      {
        icon: <LuHeading2 />,
        onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setHeading({ level: 2 }).run(),
        id: 'heading2',
        disabled: () => !editor.can().setHeading({ level: 2 }),
        isActive: () => editor.isActive('heading', { level: 2 }),
        label: 'Heading 2',
        type: 'option',
      },
      {
        icon: <LuHeading3 />,
        onClick: () => editor.chain().focus().lift('taskItem').liftListItem('listItem').setHeading({ level: 3 }).run(),
        id: 'heading3',
        disabled: () => !editor.can().setHeading({ level: 3 }),
        isActive: () => editor.isActive('heading', { level: 3 }),
        label: 'Heading 3',
        type: 'option',
      },
      {
        type: 'category',
        label: 'Lists',
        id: 'lists',
      },
      {
        icon: <LuList />,
        onClick: () => editor.chain().focus().toggleBulletList().run(),
        id: 'bulletList',
        disabled: () => !editor.can().toggleBulletList(),
        isActive: () => editor.isActive('bulletList'),
        label: 'Bullet list',
        type: 'option',
      },
      {
        icon: <LuListOrdered />,
        onClick: () => editor.chain().focus().toggleOrderedList().run(),
        id: 'orderedList',
        disabled: () => !editor.can().toggleOrderedList(),
        isActive: () => editor.isActive('orderedList'),
        label: 'Numbered list',
        type: 'option',
      },
      {
        icon: <LuListTodo />,
        onClick: () => editor.chain().focus().toggleTaskList().run(),
        id: 'todoList',
        disabled: () => !editor.can().toggleTaskList(),
        isActive: () => editor.isActive('taskList'),
        label: 'Todo list',
        type: 'option',
      },
    ]
  }, [editor, editor.state])
  const activeItem = useMemo(() => options.find((option) => isOption(option) && option.isActive()), [options]) as
    | ContentTypePickerOption
    | undefined

  return (
    <Dropdown.Root>
      <Dropdown.Trigger>
        <ToolbarButton active={activeItem?.id !== 'paragraph' && !!activeItem?.type}>
          {activeItem?.icon ?? <LuPilcrow />}
          <HiChevronDown className="h-2 w-2" />
        </ToolbarButton>
      </Dropdown.Trigger>
      <Dropdown.Content align="start">
        <Card className="gray-1 flex flex-col gap-1">
          {options.map((option) => {
            if (isOption(option)) {
              return (
                <DropdownButton key={option.id} onClick={option.onClick} isActive={option.isActive()}>
                  {option.icon}
                  {option.label}
                </DropdownButton>
              )
            } else if (isCategory(option)) {
              return (
                <div className="mt-2 first:mt-0" key={option.id}>
                  <div className="mb-1 px-1.5 text-[.65rem] font-semibold uppercase text-gray-11">{option.label}</div>
                </div>
              )
            }
          })}
        </Card>
      </Dropdown.Content>
    </Dropdown.Root>
  )
}
