// this file was automatically generated, do not edit
/* eslint-disable */

export interface UpsertTableRowsRequestHeaders {}

export interface UpsertTableRowsRequestQuery {}

export interface UpsertTableRowsRequestParams {
  table: string;
}

export interface UpsertTableRowsRequestBody {
  /**
   * @minItems 1
   * @maxItems 1000
   */
  rows: {
    id?: number;
    [k: string]: any;
  }[];
  /**
   * Determines if a row is inserted or updated. Defaults to "id".
   */
  keyColumn?: string;
  /**
   * Ensure computed columns are fully processed before returning the result. This is applicable only when the number of rows involved is fewer than 1.
   */
  waitComputed?: boolean;
}

export type UpsertTableRowsInput = UpsertTableRowsRequestBody & UpsertTableRowsRequestHeaders & UpsertTableRowsRequestQuery & UpsertTableRowsRequestParams

export type UpsertTableRowsRequest = {
  headers: UpsertTableRowsRequestHeaders;
  query: UpsertTableRowsRequestQuery;
  params: UpsertTableRowsRequestParams;
  body: UpsertTableRowsRequestBody;
}

export const parseReq = (input: UpsertTableRowsInput): UpsertTableRowsRequest & { path: string } => {
  return {
    path: `/v1/tables/${encodeURIComponent(input['table'])}/rows/upsert`,
    headers: {  },
    query: {  },
    params: { 'table': input['table'] },
    body: { 'rows': input['rows'], 'keyColumn': input['keyColumn'], 'waitComputed': input['waitComputed'] },
  }
}

export interface UpsertTableRowsResponse {
  inserted: {
    /**
     * Unique identifier for the row.
     */
    id: number;
    /**
     * Timestamp of row creation.
     */
    createdAt?: string;
    /**
     * Timestamp of the last row update.
     */
    updatedAt?: string;
    computed: {
      [k: string]: {
        status: string;
        error?: string;
        updatedBy?: string;
        updatedAt?: string;
      };
    };
    /**
     * [Read-only] List of stale values that are waiting to be recomputed.
     */
    stale?: string[];
    /**
     * Optional numeric value indicating similarity, when using findTableRows.
     */
    similarity?: number;
    [k: string]: any;
  }[];
  updated: {
    /**
     * Unique identifier for the row.
     */
    id: number;
    /**
     * Timestamp of row creation.
     */
    createdAt?: string;
    /**
     * Timestamp of the last row update.
     */
    updatedAt?: string;
    computed: {
      [k: string]: {
        status: string;
        error?: string;
        updatedBy?: string;
        updatedAt?: string;
      };
    };
    /**
     * [Read-only] List of stale values that are waiting to be recomputed.
     */
    stale?: string[];
    /**
     * Optional numeric value indicating similarity, when using findTableRows.
     */
    similarity?: number;
    [k: string]: any;
  }[];
  /**
   * Alerts for minor issues that don't block the operation but suggest possible improvements.
   */
  warnings?: string[];
  /**
   * Critical issues in specific elements that prevent their successful processing, allowing partial operation success.
   */
  errors?: string[];
}

