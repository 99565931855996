import { z } from 'zod'

// TODO: Add typing and schema validation for Next.js config here so we can rely on this as a central place for obtaining configuration safely.

const environmentVariablesSchema = z.object({
  usePat: z
    .enum(['true', 'false'])
    .default('false')
    .transform((value) => value === 'true'),
  appEnv: z.enum(['production', 'preview', 'development', 'local']).default('production'),
  sentryDSN: z.string().min(1).optional(),
  sentryEnvironment: z.string().min(1).optional(),
  sentryOrg: z.string().min(1).optional(),
  sentryProject: z.string().min(1).optional(),
  sentryAuthToken: z.string().min(1).optional(),
  unleashProxyClientKey: z.string().min(1),
  unleashProxyUrl: z.string().min(1),
  unleashProxyAppName: z.string().min(1).default('cdm'),
  studioBaseUrl: z.string().min(1),
  cdmBackendBaseUrl: z.string().min(1),
  /**
   * Only available on local
   */
  cdmInternalApiBaseUrl: z.string().optional(),
  usageApiBaseUrl: z.string().optional(),
  cdnBaseUrl: z.string().min(1),
  apiBaseUrl: z.string().min(1),
  hitlBaseUrl: z.string().min(1),
  webhookBaseUrl: z.string().min(1),
  ssoBaseUrl: z.string().min(1),
  mediaBaseUrl: z.string().min(1),
  userId: z.string().min(1).optional(),
  downgradeWorkspaceBotLimit: z.preprocess(
    (value) =>
      parseInt(
        z
          .string()
          .default('3')
          .parse(value, { path: ['VITE_MAX_BOTS_PER_WORKSPACE'] }),
        10
      ),
    z.number().positive()
  ),
  webchatUrl: z.string().min(1),
  dashboardUrl: z.string().min(1),
  segmentAnalyticsWriteKey: z.string().min(1).optional(),
  gmailClientId: z.string().min(1),
  linearClientId: z.string().min(1),
  stripePublishableKey: z.string().min(1),
  stripeProductIdCommunityPlan: z.string().min(1),
  stripeProductIdTeamPlan: z.string().min(1),
  maxBotsPerFreeWorkspace: z.preprocess(
    (value) =>
      parseInt(
        z
          .string()
          .default('5')
          .parse(value, { path: ['VITE_MAX_BOTS_PER_FREE_WORKSPACE'] }),
        10
      ),
    z.number().positive()
  ),
  maxBotsPerPremiumWorkspace: z.preprocess(
    (value) =>
      parseInt(
        z
          .string()
          .default('100')
          .parse(value, { path: ['MAX_BOTS_PER_PREMIUM_WORKSPACE'] }),
        10
      ),
    z.number().positive()
  ),
  novuAppId: z.string().min(1),
  typeformOnboardingFormId: z.string().optional(),
})

type EnvironmentVariables = Record<keyof z.infer<typeof environmentVariablesSchema>, string | undefined>
const environmentVariables: EnvironmentVariables = {
  usePat: import.meta.env.VITE_USE_PAT,
  appEnv: import.meta.env.VITE_APP_ENVIRONMENT,
  cdnBaseUrl: import.meta.env.VITE_CDN_BASE_URL,
  mediaBaseUrl: import.meta.env.VITE_MEDIA_BASE_URL,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  sentryEnvironment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  sentryOrg: import.meta.env.VITE_SENTRY_ORG,
  sentryProject: import.meta.env.VITE_SENTRY_PROJECT,
  sentryAuthToken: import.meta.env.VITE_SENTRY_AUTH_TOKEN,
  unleashProxyClientKey: import.meta.env.VITE_UNLEASH_PROXY_CLIENT_KEY,
  unleashProxyUrl: import.meta.env.VITE_UNLEASH_PROXY_URL,
  unleashProxyAppName: import.meta.env.VITE_UNLEASH_PROXY_APP_NAME,
  studioBaseUrl: import.meta.env.VITE_CDM_STUDIO_BASE_URL,
  cdmBackendBaseUrl: import.meta.env.VITE_CDM_BACKEND_BASE_URL,
  cdmInternalApiBaseUrl: import.meta.env.VITE_CDM_INTERNAL_API_BASE_URL,
  usageApiBaseUrl: import.meta.env.VITE_USAGE_API_BASE_URL,
  apiBaseUrl: import.meta.env.VITE_API_BASE_URL,
  hitlBaseUrl: import.meta.env.VITE_HITL_BASE_URL,
  webchatUrl: import.meta.env.VITE_WEBCHAT_URL,
  dashboardUrl: import.meta.env.VITE_DASHBOARD_URL,
  webhookBaseUrl: import.meta.env.VITE_WEBHOOK_BASE_URL,
  ssoBaseUrl: import.meta.env.VITE_SSO_BASE_URL,
  userId: import.meta.env.VITE_USER_ID,
  downgradeWorkspaceBotLimit: import.meta.env.VITE_DOWNGRADE_WORKSPACE_BOT_LIMIT,
  segmentAnalyticsWriteKey: import.meta.env.VITE_SEGMENT_ANALYTICS_WRITE_KEY,
  gmailClientId: import.meta.env.VITE_GMAIL_CLIENT_ID,
  linearClientId: import.meta.env.VITE_LINEAR_CLIENT_ID,
  stripePublishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
  stripeProductIdCommunityPlan: import.meta.env.VITE_STRIPE_COMMUNITY_PLAN_PRODUCT_ID,
  stripeProductIdTeamPlan: import.meta.env.VITE_STRIPE_TEAM_PLAN_PRODUCT_ID,
  maxBotsPerPremiumWorkspace: import.meta.env.VITE_MAX_BOTS_PER_PREMIUM_WORKSPACE,
  maxBotsPerFreeWorkspace: import.meta.env.VITE_MAX_BOTS_PER_FREE_WORKSPACE,
  novuAppId: import.meta.env.VITE_NOVU_APP_ID,
  typeformOnboardingFormId: import.meta.env.VITE_TYPEFORM_ONBOARDING_FORM_ID,
}

export const config = environmentVariablesSchema.parse(environmentVariables)

export function getCdmStudioUrl(botId?: string): string {
  let cdmStudioUrl = config.studioBaseUrl

  if (botId) {
    cdmStudioUrl += `/${botId}`
  }

  return cdmStudioUrl ?? ''
}

export function getMaxBotCount(workspaceType?: string): number {
  let maxBotCount: number
  if (workspaceType === 'premium') {
    maxBotCount = config.maxBotsPerPremiumWorkspace
  } else {
    maxBotCount = config.maxBotsPerFreeWorkspace
  }

  if (!maxBotCount) {
    throw new Error(
      `You need to set the MAX_BOTS_PER_[...]_WORKSPACE for ${workspaceType} workspaces environment variable`
    )
  }

  return typeof maxBotCount !== 'number' ? parseInt(maxBotCount as string) : maxBotCount
}
