// this file was automatically generated, do not edit
/* eslint-disable */

export interface SetWorkspacePaymentMethodRequestHeaders {}

export interface SetWorkspacePaymentMethodRequestQuery {}

export interface SetWorkspacePaymentMethodRequestParams {
  id: string;
}

export interface SetWorkspacePaymentMethodRequestBody {
  /**
   * ID of the Stripe PaymentMethod to attach to the workspace.
   */
  stripePaymentMethodId: string;
}

export type SetWorkspacePaymentMethodInput = SetWorkspacePaymentMethodRequestBody & SetWorkspacePaymentMethodRequestHeaders & SetWorkspacePaymentMethodRequestQuery & SetWorkspacePaymentMethodRequestParams

export type SetWorkspacePaymentMethodRequest = {
  headers: SetWorkspacePaymentMethodRequestHeaders;
  query: SetWorkspacePaymentMethodRequestQuery;
  params: SetWorkspacePaymentMethodRequestParams;
  body: SetWorkspacePaymentMethodRequestBody;
}

export const parseReq = (input: SetWorkspacePaymentMethodInput): SetWorkspacePaymentMethodRequest & { path: string } => {
  return {
    path: `/v1/admin/workspaces/${encodeURIComponent(input['id'])}/billing/payment-method`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: { 'stripePaymentMethodId': input['stripePaymentMethodId'] },
  }
}

export interface SetWorkspacePaymentMethodResponse {
  stripePaymentMethodId: string;
  paymentMethod: {
    type: string;
    lastDigits: string;
  } | null;
  status:
    | "succeeded"
    | "processing"
    | "canceled"
    | "requires_confirmation"
    | "requires_action"
    | "requires_payment_method";
  /**
   * If the payment needs to be confirmed, this will contain a URL to redirect the user to so they can complete the verification process to confirm it.
   */
  nextAction?: {
    redirectToUrl: string;
  };
}

