// this file was automatically generated, do not edit
/* eslint-disable */

export interface CreateTaskRequestHeaders {}

export interface CreateTaskRequestQuery {}

export interface CreateTaskRequestParams {}

export interface CreateTaskRequestBody {
  /**
   * Title describing the task
   */
  title?: string;
  /**
   * All the notes related to the execution of the current task
   */
  description?: string;
  /**
   * Type of the task
   */
  type: string;
  /**
   * Content related to the task
   */
  data?: {
    [k: string]: any;
  };
  /**
   * Parent task id is the parent task that created this task
   */
  parentTaskId?: string;
  /**
   * Conversation id related to this task
   */
  conversationId: string;
  /**
   * Specific user related to this task
   */
  userId?: string;
  /**
   * The timeout date where the task should be failed in the ISO 8601 format
   */
  timeoutAt?: string;
  /**
   * Tags for the [Task](#schema_task)
   */
  tags?: {
    [k: string]: string;
  };
}

export type CreateTaskInput = CreateTaskRequestBody & CreateTaskRequestHeaders & CreateTaskRequestQuery & CreateTaskRequestParams

export type CreateTaskRequest = {
  headers: CreateTaskRequestHeaders;
  query: CreateTaskRequestQuery;
  params: CreateTaskRequestParams;
  body: CreateTaskRequestBody;
}

export const parseReq = (input: CreateTaskInput): CreateTaskRequest & { path: string } => {
  return {
    path: `/v1/chat/tasks`,
    headers: {  },
    query: {  },
    params: {  },
    body: { 'title': input['title'], 'description': input['description'], 'type': input['type'], 'data': input['data'], 'parentTaskId': input['parentTaskId'], 'conversationId': input['conversationId'], 'userId': input['userId'], 'timeoutAt': input['timeoutAt'], 'tags': input['tags'] },
  }
}

export interface CreateTaskResponse {
  /**
   * Task definition
   */
  task: {
    /**
     * Id of the [Task](#schema_task)
     */
    id: string;
    /**
     * Title describing the task
     */
    title: string;
    /**
     * All the notes related to the execution of the current task
     */
    description: string;
    /**
     * Type of the task
     */
    type: string;
    /**
     * Content related to the task
     */
    data: {
      [k: string]: any;
    };
    /**
     * Status of the task
     */
    status: "pending" | "in_progress" | "failed" | "completed" | "blocked" | "paused" | "timeout" | "cancelled";
    /**
     * Parent task id is the parent task that created this task
     */
    parentTaskId?: string;
    /**
     * Conversation id related to this task
     */
    conversationId?: string;
    /**
     * Specific user related to this task
     */
    userId?: string;
    /**
     * The timeout date where the task should be failed in the ISO 8601 format
     */
    timeoutAt: string;
    /**
     * Creation date of the task in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the task in ISO 8601 format
     */
    updatedAt: string;
    /**
     * If the task fails this is the reason behind it
     */
    failureReason?: string;
    /**
     * Set of [Tags](/docs/developers/concepts/tags) that you can attach to a [Task](#schema_task). Individual keys can be unset by posting an empty value to them.
     */
    tags: {
      [k: string]: string;
    };
  };
}

