import cx from 'classnames'
import { format } from 'date-fns'
import * as React from 'react'
import { TbCalendar } from 'react-icons/tb'
import { z } from 'zod'
import { Calendar, Popover, Input } from '../elements'

type DateInputProps = {
  value: Date
  onChange: (date: Date) => void
  onValidChanged?: (valid: boolean) => void
  fromDate?: Date
}

const dateSchema = z.coerce.date()

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export const DateInput = ({ value, onChange, onValidChanged, fromDate }: DateInputProps) => {
  const [valid, setValid] = React.useState(true)

  const [inputValue, setInputValue] = React.useState<string>(format(value, 'y-LL-dd HH:KK:mm'))

  const icon = <TbCalendar className="ml-2 h-6 w-6 text-gray-11 hover:text-gray-12" />
  const button = <button>{icon}</button>

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const setValidAndNotify = (valid: boolean) => {
    setValid(valid)
    if (onValidChanged) {
      onValidChanged(valid)
    }
  }

  return (
    <>
      <Input
        onBlur={() => {
          if (valid) {
            onChange(dateSchema.parse(inputValue))
          }
        }}
        onChange={(e) => {
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const value = e.target.value.trim()
          if (!value) {
            setInputValue('')
            setValidAndNotify(false)
          } else {
            const result = dateSchema.safeParse(value)
            setValidAndNotify(result.success)
            setInputValue(value)
          }
        }}
        className={cx('border-1 bg-gray-11 text-gray-12', valid ? 'border-gray-9' : 'border-x-red-9 border-y-red-9')}
        value={inputValue}
      />
      <Popover>
        <Popover.Trigger children={button} asChild />
        <Popover.Content align="end">
          {({ setOpen }) => (
            <div className="flex flex-col gap-2 p-2">
              <Calendar
                initialFocus
                mode="single"
                selected={value}
                onSelect={(date) => {
                  if (date) {
                    onChange(date)
                    setValidAndNotify(true)
                  } else {
                    setValidAndNotify(false)
                  }
                  setInputValue(date ? format(date, 'y-LL-dd HH:KK:mm') : '')
                  setOpen(false)
                }}
                defaultMonth={value}
                fromDate={fromDate}
              />
            </div>
          )}
        </Popover.Content>
      </Popover>
    </>
  )
}
