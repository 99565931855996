import React, { SyntheticEvent, useState } from 'react'
import { Button, Modal } from '../elements'

type DeletionModalProps = {
  onDelete: () => Promise<boolean>
  title: string
  deleteButtonText: string
  open: boolean
  setOpen: (open: boolean) => void
}

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export function DeletionModal({
  setOpen,
  open,
  children,
  onDelete,
  title,
  deleteButtonText = 'Delete',
}: React.PropsWithChildren<DeletionModalProps>) {
  const [disabled, setDisabled] = useState(false)

  async function handleOnDelete(event: SyntheticEvent): Promise<boolean> {
    event.preventDefault()
    setDisabled(true)

    return await onDelete()
      .then((result) => {
        setDisabled(result)
        return result
      })
      .catch(() => {
        setDisabled(false)
        return false
      })
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        <h2 className="text-xl">{title}</h2>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div />
        <div className="flex">
          <Button variant="tertiary" color="error" className="mr-2" onClick={() => setOpen(false)} disabled={disabled}>
            Cancel
          </Button>
          <Button variant="primary" color="error" disabled={disabled} onClick={(e) => void handleOnDelete(e)}>
            {deleteButtonText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
