import { Navigate, createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/workspaces/$workspaceId/bots')({
  component: Component,
})

function Component() {
  const { workspaceId } = Route.useParams()
  return <Navigate to="/workspaces/$workspaceId/home" params={{ workspaceId }} replace />
}
