import { Client as AutoGeneratedClient } from './gen'
import axios from 'axios'
import * as config from './config'

export * as errors from './gen/errors'
export * as models from './gen/models'
export * as consts from './consts'
export * as config from './config'

export * as axios from 'axios'
export type {
  Message,
  Conversation,
  User,
  State,
  Event,
  File,
  Bot,
  Integration,
  Issue,
  IssueEvent,
  Account,
  Workspace,
  Usage,
} from './gen'
export * from './gen/errors'

const _100mb = 100 * 1024 * 1024
const maxBodyLength = _100mb
const maxContentLength = _100mb

type IClient = AutoGeneratedClient
type Simplify<T> = T extends (...args: infer A) => infer R
  ? (...args: Simplify<A>) => Simplify<R>
  : T extends Promise<infer R>
    ? Promise<Simplify<R>>
    : T extends object
      ? T extends infer O
        ? { [K in keyof O]: Simplify<O[K]> }
        : never
      : T

type AsyncFunc = (...args: any[]) => Promise<any>

export type Operations = Simplify<
  keyof {
    [K in keyof IClient as IClient[K] extends AsyncFunc ? K : never]: IClient[K]
  }
>

export type ClientParams = {
  [T in Operations]: Simplify<Parameters<IClient[T]>[0]>
}

export type ClientReturns = {
  [T in Operations]: Simplify<Awaited<ReturnType<IClient[T]>>>
}

export type ClientParam<T extends Operations> = ClientParams[T]
export type ClientReturn<T extends Operations> = ClientReturns[T]

export class Client extends AutoGeneratedClient {
  public readonly config: Readonly<config.ClientConfig>

  public constructor(clientProps: config.ClientProps = {}) {
    const clientConfig = config.getClientConfig(clientProps)
    const { apiUrl, headers, withCredentials, timeout } = clientConfig

    const axiosClient = axios.create({
      baseURL: apiUrl,
      headers,
      withCredentials,
      timeout,
      maxBodyLength,
      maxContentLength,
    })

    super(axiosClient)

    this.config = clientConfig
  }
}
