import { createFileRoute } from '@tanstack/react-router'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { Logs } from '~/components'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId/logs')({
  component: () => {
    const { workspaceId, integrationId } = Route.useParams()
    const [startDate, setStartDate] = useState(DateTime.now().startOf('day').toJSDate())
    const [endDate, setEndDate] = useState(DateTime.now().endOf('day').toJSDate())

    return (
      <Logs
        // Magic numbers bc I don't want to play in the logs component it's a rabbit hole
        className="relative h-[700px] [&>div]:mx-[-16px] [&>div]:my-0"
        id={integrationId}
        type="integration"
        workspaceId={workspaceId}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
    )
  },
})
