import Ansi from 'ansi-to-react'
import cx from 'classnames'
import { subDays } from 'date-fns'
import { motion } from 'framer-motion'
import { DateTime } from 'luxon'
import { useEffect, useRef, useState } from 'react'
import { HiChevronDown, HiChevronUp } from 'react-icons/hi2'
import { TbRefresh, TbSettings } from 'react-icons/tb'
import { EmptyState } from '../../components'
import { Button, ScrollArea, Tooltip } from '../../elements'
import { useIsInViewport } from '../../hooks'
import { DateInput } from '../DateInput'
import { DocumentationLink } from '../DocumentationLink'
import { LogsSettingsDropdown } from './LogsSettingsDropdown'
import { Log } from './types'
import { Spinner } from '~/elementsv2'

type LogLevel = keyof typeof COLOR_CLASS_BY_LEVEL

const COLOR_CLASS_BY_LEVEL = {
  debug: 'text-gray-11',
  info: '',
  warn: '!bg-[#d1ba4726]',
  error: '!bg-[#d1474726]',
}

function getColorClassByLevel(level: string): string {
  return COLOR_CLASS_BY_LEVEL[level.toLowerCase() as LogLevel] ?? COLOR_CLASS_BY_LEVEL.info
}

const DYNAMODB_LOGS_EXPIRY_DAYS = 30
const fromDate = subDays(new Date(), DYNAMODB_LOGS_EXPIRY_DAYS)

export function LogsPanel({
  logs,
  onRefresh,
  onLoadPrevious,
  isFetching,
  isFetchingPreviousPage,
  startDate,
  onStartDateChanged,
  endDate,
  onEndDateChanged,
  hasPreviousPage,
}: {
  logs: Log[]
  onRefresh: () => void
  onLoadPrevious: () => void
  isFetching: boolean
  isFetchingPreviousPage: boolean
  startDate: Date
  onStartDateChanged: (date: Date) => void
  endDate: Date
  onEndDateChanged: (date: Date) => void
  hasPreviousPage: boolean
}): JSX.Element {
  const rootRef = useRef<null | HTMLDivElement>(null)
  const topRef = useRef<null | HTMLDivElement>(null)
  const bottomRef = useRef<null | HTMLDivElement>(null)

  const isAtTop = useIsInViewport(topRef)
  const isAtBottom = useIsInViewport(bottomRef)

  const [showTimestamp, setShowTimestamp] = useState(true)
  const [shouldScrollToBottom, setShouldScrollToBottom] = useState(true)

  const [validDates, setValidDates] = useState(true)

  const animationVariants = {
    rotate: { rotate: [0, 180], transition: { duration: 1, repeatDelay: 0.2, ease: 'backInOut', repeat: Infinity } },
    stop: {},
  }

  const rows = logs.map(({ timestamp, level, message }, index) => (
    <LogRow
      key={index}
      index={index}
      date={new Date(timestamp)}
      level={level}
      message={message}
      showTimestamp={showTimestamp}
    />
  ))

  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' })
  }
  // refresh when r key is pressed
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'r' && !e.ctrlKey && !e.metaKey && !e.altKey) {
        onRefresh()
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [onRefresh])

  useEffect(() => {
    if (shouldScrollToBottom && !isFetching) {
      scrollToBottom()
      setShouldScrollToBottom(false)
    }
  }, [shouldScrollToBottom, isFetching])

  return (
    <div
      className={cx(
        'dark absolute inset-4 top-0 isolate m-2 flex flex-col gap-px overflow-hidden rounded-md border border-gray-6 bg-gray-1 text-gray-12'
      )}
    >
      <div className="bg flex items-center justify-between border-b border-b-gray-6 bg-gray-2 p-4">
        <div className="mr-auto text-base text-gray-12 ">Logs</div>
        <div className="flex flex-row">
          <div className="mr-4 flex flex-row items-center">
            <div className="mr-2">From</div>
            <DateInput
              value={startDate}
              onChange={(d) => {
                setShouldScrollToBottom(true)
                onStartDateChanged(d)
              }}
              onValidChanged={setValidDates}
              fromDate={fromDate}
            />
            <div className="ml-4 mr-2">To</div>
            <DateInput
              value={endDate}
              onChange={(d) => {
                setShouldScrollToBottom(true)
                onEndDateChanged(d)
              }}
              onValidChanged={setValidDates}
              fromDate={fromDate}
            />
          </div>
          <Tooltip className="mr-2 flex items-center" label={'Refresh (R)'}>
            <button
              className={cx('rounded-md p-1 text-gray-11 hover:bg-gray-4 hover:text-gray-12', {
                'cursor-pointer': validDates,
                'cursor-not-allowed': !validDates,
              })}
              onClick={() => onRefresh()}
              disabled={!validDates}
            >
              <motion.div variants={animationVariants} animate={isFetching ? 'rotate' : 'stop'}>
                <TbRefresh className="-scale-x-100" size={20} />
              </motion.div>
            </button>
          </Tooltip>
          <LogsSettingsDropdown showTimestamp={showTimestamp} setShowTimestamp={setShowTimestamp}>
            <div className="mr-2 cursor-pointer rounded-md p-1 text-gray-11 hover:bg-gray-4 hover:text-gray-12">
              <TbSettings className="-scale-x-100" size={20} />
            </div>
          </LogsSettingsDropdown>
        </div>
      </div>

      {logs.length === 0 && !isFetching ? (
        <EmptyState
          icon="searchDark"
          title="No logs found"
          message={<DocumentationLink page="/cloud/admin-dashboard/logs/" children="Learn more" />}
        />
      ) : (
        <ScrollArea ref={rootRef}>
          <div ref={topRef} />
          <div
            className={cx('flex h-16 items-center justify-center', {
              invisible: isFetching && !isFetchingPreviousPage,
            })}
          >
            {isFetchingPreviousPage ? (
              <Spinner />
            ) : hasPreviousPage ? (
              <Button onClick={() => onLoadPrevious()}>Load more</Button>
            ) : (
              <Button className="text-gray-11" disabled={true}>
                No more logs for selected period
              </Button>
            )}
          </div>

          <div
            onClick={scrollToTop}
            className={cx(
              'absolute left-0 right-0 top-2 z-50 ml-auto mr-6 flex h-10 w-10 items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-4',
              { hidden: isAtTop }
            )}
          >
            <HiChevronUp size={20} className={cx(' text-gray-1')} />
          </div>
          <div className="overflow-hidden bg-inherit px-0">
            <div className={cx('relative text-xs [&_*]:font-mono')}>{rows}</div>
            <div ref={bottomRef} />
          </div>
          <div
            onClick={scrollToBottom}
            className={cx(
              'absolute bottom-2 left-0 right-0 z-50 ml-auto mr-6 flex h-10 w-10 items-center justify-center rounded-full hover:cursor-pointer hover:bg-gray-4',
              { hidden: isAtBottom }
            )}
          >
            <HiChevronDown size={20} className={cx(' text-gray-1')} />
          </div>
        </ScrollArea>
      )}
    </div>
  )
}

function LogRow({
  index,
  date,
  level,
  message,
  showTimestamp,
}: {
  index: number
  date: Date
  message: string
  level: string
  showTimestamp: boolean
}): JSX.Element {
  const [showDetails, setShowDetails] = useState(false)
  const colorClass = getColorClassByLevel(level)

  const datetime = DateTime.fromJSDate(date)
  const timestamp = datetime.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)

  return (
    <>
      <div
        className={cx('group flex cursor-pointer', colorClass, showDetails ? 'bg-gray-1' : 'hover:bg-gray-4')}
        onClick={() => setShowDetails(!showDetails)}
      >
        <div className="mr-2 w-10 flex-none select-none text-center leading-5 text-gray-10 group-hover:text-gray-12">
          {index + 1}
        </div>
        {showTimestamp && (
          <div className="mr-2 text-center leading-5 text-gray-10 group-hover:text-gray-12">{timestamp}</div>
        )}
        <div className="flex-1 whitespace-pre-wrap leading-5">
          <Ansi className="break-all leading-5 [&>span]:inline-block [&>span]:leading-5">{formatMessage(message)}</Ansi>
        </div>
      </div>
      {showDetails && <LogDetails timestamp={timestamp} level={level} />}
    </>
  )
}

function formatMessage(message: string) {
  try {
    const json = JSON.parse(message)
    return JSON.stringify(json, null, 2)
  } catch {
    return message
  }
}

function LogDetails({ timestamp, level }: { timestamp: string; level: string }): JSX.Element {
  const entries = [
    <LogDetailEntry key={0} label={'Timestamp'} value={timestamp} />,
    <LogDetailEntry key={1} label={'Level'} value={level} />,
  ]

  return (
    <div className={'bg-gray-12 py-2 pl-10 pr-4'}>
      <div className={cx('grid grid-cols-[minmax(auto,25ch)1fr] rounded-md border border-gray-6 px-4 py-2')}>
        {entries}
      </div>
    </div>
  )
}

function LogDetailEntry({ label, value }: { label: string; value: string }): JSX.Element {
  return (
    <>
      <div className={'truncate pr-8 leading-5 text-gray-10'} title={label}>
        {label}
      </div>
      <div className="truncate leading-5" title={value}>
        {value}
      </div>
    </>
  )
}
