import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getGenericClient } from '../../client'
import { WorkspaceSummary } from '../../features/workspaces/types'
import { getQueryKey, showErrorToast } from '../../services'
import { handleHttpErrors } from '../../shared'

type DeleteWorkspaceParams = { onSuccess?: (workspaceId: string) => void } & Parameters<
  ReturnType<typeof getGenericClient>['deleteWorkspace']
>['0']

export function useDeleteWorkspace() {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ id }: DeleteWorkspaceParams) => {
      return await getGenericClient().deleteWorkspace({ id }).catch(handleHttpErrors)
    },
    onSuccess: async (_, { onSuccess, id }) => {
      queryClient.setQueryData<WorkspaceSummary[]>(
        getQueryKey('workspaces_'),
        (old: WorkspaceSummary[] | undefined) => {
          if (!!old) {
            return old.filter((w) => w.id !== id)
          }
          return old
        }
      )

      const workspaceQueryKey = getQueryKey('workspaces_/$workspaceId_', { workspaceId: id })
      queryClient.removeQueries({ queryKey: workspaceQueryKey })

      onSuccess?.(id)
    },
    onError: (error: Error) => {
      showErrorToast(error.message)
    },
  })
}
