import { Flex } from '@radix-ui/themes'
import React, { ReactNode } from 'react'
import { cn } from '../utils'

type Props = {
  rightSidebar?: ReactNode
  leftSidebar?: ReactNode
  main: ReactNode
  className?: string
  leftSidebarSize?: '1' | '2' | '3' | '4' | '5'
  rightSidebarSize?: '1' | '2' | '3' | '4' | '5'
}

const sidebarSizes = {
  1: '12rem',
  2: '16rem',
  3: '20rem',
  4: '24rem',
  5: '28rem',
} as const

export const SidebarLayout = ({
  rightSidebar,
  leftSidebar,
  main,
  leftSidebarSize = '2',
  rightSidebarSize = '2',
  ...props
}: Props) => {
  const singleSidebar = (rightSidebar || leftSidebar) && !(rightSidebar && leftSidebar)

  return (
    <Flex
      {...props}
      direction={{
        md: 'row',
        sm: singleSidebar ? 'row' : 'column',
        initial: 'column',
      }}
      gap={{ initial: '4', sm: '8' }}
    >
      {leftSidebar && (
        <Flex
          direction={'column'}
          width={singleSidebar ? { sm: sidebarSizes[leftSidebarSize] } : { md: sidebarSizes[leftSidebarSize] }}
          className={cn(singleSidebar ? 'r-sm:flex-none' : 'r-md:flex-none')}
        >
          {leftSidebar}
        </Flex>
      )}
      <Flex direction={'column'} flexGrow={{ sm: '1', initial: undefined }} className="@container">
        {main}
      </Flex>
      {rightSidebar && (
        <Flex
          direction={'column'}
          width={singleSidebar ? { sm: sidebarSizes[rightSidebarSize] } : { md: sidebarSizes[rightSidebarSize] }}
          className={cn(singleSidebar ? 'r-sm:flex-none' : 'r-md:flex-none')}
        >
          {rightSidebar}
        </Flex>
      )}
    </Flex>
  )
}
