import { getClient, Webchat, WebchatProvider, WebchatClient } from '@botpress/webchat'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { EmptyState } from '../../../components'
import { Button, Card, Spinner } from '../../../elements'
import { useIntegration, useUpdateBot, useWebchatTheme, useWebchatV2 } from '~/hooks'
import { BotObject } from '../../bots/types'
import { WebchatConfig, parseWebchatScript } from '../utils'
import { config as globalConfig } from '~/shared/configurations'
import { useQuery } from '~/services'

type Props = {
  workspaceId: string
  bot: BotObject
}

export type ChatHandle = {
  refetch: () => void
}

export const Chat = forwardRef<ChatHandle, Props>(({ bot, workspaceId }, ref) => {
  const { data: webchatIntegration } = useIntegration({ name: 'webchat', version: '0.2.0' })
  const { data: script, refetch } = useQuery('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
    botId: bot.id,
    workspaceId,
    type: 'fullscreen',
  })
  const { mutate: updateBot } = useUpdateBot()
  const [client, setClient] = useState<WebchatClient | undefined>()
  const [config, setConfig] = useState<WebchatConfig | undefined>()
  const { isWebchatV2Enabled } = useWebchatV2()

  useImperativeHandle(ref, () => ({ refetch }), [])

  const webchatInstalledIntegration = Object.values(bot.integrations).find(
    (integration) => integration.name === 'webchat'
  )

  useEffect(() => {
    if (isWebchatV2Enabled && script && webchatInstalledIntegration) {
      const webchatConfig = parseWebchatScript(script)
      if (!webchatConfig) {
        return
      }
      setConfig(webchatConfig)
      setClient(
        getClient({
          apiUrl: globalConfig.webchatUrl,
          clientId: webchatInstalledIntegration.webhookId,
        })
      )
    }
  }, [script, isWebchatV2Enabled])

  const { theme, style } = useWebchatTheme({ themeName: 'eggplant' })

  if (!bot.deployedAt) {
    return (
      <EmptyState
        icon="search"
        title="Chatbot not published"
        message="You must publish the chatbot from Botpress Studio to be able to interact with it."
      />
    )
  }

  function enableWebchat(webchatIntegrationId: string) {
    updateBot({
      id: bot.id,
      workspaceId,
      integrations: { [webchatIntegrationId]: { enabled: true, configuration: {} } },
    })
  }

  if (!(script && webchatIntegration)) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (!bot.integrations[webchatIntegration?.id ?? '']?.enabled) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <EmptyState
          icon="NoMessages"
          title="Webchat disabled"
          message="You must enable the Webchat integration to interact with it."
        />
        <div className="flex flex-1">
          <div>
            <Button variant="primary" color="action" onClick={() => enableWebchat(webchatIntegration.id)}>
              Enable Webchat
            </Button>
          </div>
        </div>
      </div>
    )
  }

  if (isWebchatV2Enabled && client && style && theme) {
    return (
      <div className="absolute inset-0 bottom-1">
        <style>{style}</style>
        <WebchatProvider theme={theme} configuration={config ?? {}} client={client}>
          <Webchat />
        </WebchatProvider>
      </div>
    )
  }

  return (
    <div className="absolute inset-4">
      <Card className="relative h-full w-full overflow-clip border px-0 py-0">
        <iframe title="webchat" width="100%" height="100%" srcDoc={`<body>${script}</body>`} />
        <div className="absolute bottom-0 left-0 top-0 w-px bg-gray-1" />
        <div className="absolute left-0 right-0 top-0 h-px bg-gray-1" />
      </Card>
    </div>
  )
})
