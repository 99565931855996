export const defaultApiUrl = 'https://api.botpress.cloud'
export const defaultTimeout = 60_000

export const apiUrlEnvName = 'BP_API_URL'
export const botIdEnvName = 'BP_BOT_ID'
export const integrationIdEnvName = 'BP_INTEGRATION_ID'
export const workspaceIdEnvName = 'BP_WORKSPACE_ID'
export const tokenEnvName = 'BP_TOKEN'

export const workspaceIdHeader = 'x-workspace-id'
export const botIdHeader = 'x-bot-id'
export const integrationIdHeader = 'x-integration-id'
export const authorizationHeader = 'Authorization'
