import { Portal } from '@radix-ui/themes'
import { usePortalStore } from '../stores'

type Props = {
  children?: React.ReactNode
}
export const BannerPortal = (props: Props) => {
  const bannerRef = usePortalStore((state) => state.bannerPortalRef)
  return <Portal {...props} container={bannerRef.current} />
}
