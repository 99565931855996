import { queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { listWorkspaces } from '../features/workspaces/services'
import { cacheDuration } from '../shared'
import { getGenericClient } from '../client'
import { getQueryKey } from '~/services'

export const workspacesQueryOptions = () => {
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getQueryKey('workspaces_'),
    queryFn: async () => {
      return listWorkspaces()
    },
    meta: {
      errorMessage: 'Failed to retrieve your workspaces, please try again later',
    },
  })
}

export const publicWorkspaceQueryOptions = (workspaceId: string) => {
  const { getPublicWorkspace } = useQueryKeys()

  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: getPublicWorkspace(workspaceId),
    queryFn: () => {
      if (!workspaceId) {
        throw new Error('No workspaceId provided')
      }
      return getGenericClient()
        .getPublicWorkspace({ id: workspaceId })
        .catch(() => undefined)
    },
    meta: {
      suppressError: true,
    },
  })
}

export const workspaceHandleAvailableQueryOptions = (handle: string) => {
  const { checkWorkspaceHandle } = useQueryKeys()

  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: checkWorkspaceHandle(handle),
    queryFn: async () => {
      if (!handle) {
        throw new Error('No handle provided')
      }
      return getGenericClient()
        .checkHandleAvailability({ handle })
        .catch(() => ({ available: false, suggestions: [], usedBy: undefined }))
    },
  })
}
