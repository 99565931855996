import { forwardRef, PropsWithChildren } from 'react'

import { Block, MessageObject, useWebchatStore } from '@botpress/webchat'
import { Avatar } from '../../../elementsv2'
import { Link } from '@tanstack/react-router'
import { cn } from '../../../utils'

type MessageProps = PropsWithChildren<Partial<MessageObject>> & { id: string; selected?: boolean }
export const Message = forwardRef<HTMLDivElement, MessageProps>(
  ({ direction, block, disableInput, children, sender, id, selected, ...props }, ref) => {
    const { message: styles } = useWebchatStore((s) => s.theme)
    const { botAvatar, botName } = useWebchatStore((s) => s.configuration)

    return (
      <div
        {...props}
        className={cn(styles?.container?.className)}
        data-disable-input={!!disableInput}
        data-direction={direction}
        ref={ref}
      >
        {direction === 'incoming' && (
          <Avatar size={'1'} variant="soft" name={sender?.name ?? botName} pictureUrl={sender?.avatar ?? botAvatar} />
        )}
        <Link
          resetScroll={false}
          preload={false}
          className={cn('flex justify-end rounded-sm', { 'ring-2 ring-offset-2': selected })}
          search={{ messageId: id }}
          params={{}}
        >
          {block ? (
            <Block
              block={block}
              styles={
                {
                  ...styles?.blocks,
                  bubble: {
                    className: styles?.blocks?.bubble?.className,
                    style: { maxWidth: 'none' },
                  },
                  text: {
                    ...styles?.blocks?.text,
                    pre: {
                      className: styles?.blocks?.text?.pre?.className,
                      style: { textWrap: 'pretty' },
                    },
                  },
                } as any
              }
            />
          ) : null}
        </Link>
        {children}
      </div>
    )
  }
)
