import { useMutation } from '@tanstack/react-query'
import { BillingApiClient } from '~/features/billing/services'
import { getQueryKey, showErrorToast, showSuccessToast } from '~/services'
import { queryClient } from '../../providers/ReactQuery'

type UpdateSubscriptionProps = {
  productQuantityConfig: {
    price?: string | undefined
    quantity: number
  }[]
  isUpgrading: boolean // TODO: This should not be provided by the frontend but this is our current logic
  subscriptionId: string
}
export function useUpdateSubscription(workspaceId: string) {
  return useMutation({
    gcTime: 0,
    mutationFn: async (props: UpdateSubscriptionProps) => {
      return BillingApiClient.updateSubscription(workspaceId, props)
    },
    onSuccess: () => {
      showSuccessToast('Your subscription has been updated successfully.')
      void queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })
    },
    onError: () => {
      showErrorToast('There was an error while updating your subscription. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}
