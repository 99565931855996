import { withJsonFormsControlProps } from '@jsonforms/react'
import { Checkbox } from '../../../elements'

export const CheckboxControl = withJsonFormsControlProps(
  ({ label, data, handleChange, path, description, enabled }) => {
    return (
      <div className="relative isolate">
        <Checkbox
          className="z-10"
          label={label}
          disabled={!enabled}
          checked={data ?? false}
          placeholder={description}
          onCheckedChange={(checked) => {
            handleChange(path, checked)
          }}
        />
      </div>
    )
  }
)
