import { z } from 'zod'

// This is copied from the backend
const themeNames = ['prism', 'galaxy', 'dusk', 'eggplant', 'dawn', 'midnight'] as const
export type ThemeName = (typeof themeNames)[number]

export const webchatIntegrationConfigSchema = z.object({
  botName: z.string().optional(),
  avatarUrl: z.string().optional(),
  botConversationDescription: z.string().optional(),
  phoneNumber: z.string().optional(),
  termsConditions: z.string().optional(),
  privacyPolicy: z.string().optional(),
  emailAddress: z.string().optional(),
  website: z.string().optional(),
  showBotInfoPage: z.boolean().optional(),
  enableConversationDeletion: z.boolean().optional(),
  composerPlaceholder: z.string().optional(),
  stylesheet: z.string().optional(),
  useSessionStorage: z.boolean().optional(),
  showPoweredBy: z.boolean().optional(),
  frontendVersion: z.enum(['v0', 'v1']).catch(() => 'v0' as const),
  theme: z.enum(themeNames).catch(() => 'prism' as const),
  themeColor: z.string().optional(),
  additionalStyles: z.string().optional(),
  allowedOrigins: z.array(z.string()).optional(),
})

export type WebchatIntegrationConfig = z.infer<typeof webchatIntegrationConfigSchema>
