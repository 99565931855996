import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { useQueryKeys } from './useQueryKeys'

export function useReadFile({ url, errorHandler }: { url: string; errorHandler?: (error: AxiosError) => void }) {
  const { readFile } = useQueryKeys()
  return useQuery({
    enabled: !!url,
    staleTime: 60_000,
    queryKey: readFile(url ?? ''),
    queryFn: async () => {
      const file = await axios
        .get(url, {
          withCredentials: false,
        })
        .catch((err) => {
          if (errorHandler) {
            errorHandler(err)
          } else {
            throw err
          }
        })

      return file?.data ?? ''
    },
    meta: {
      errorMessage: `Failed to read file from: ${url}, please try again later`,
    },
  })
}
