import { Theme } from '@botpress/webchat'
import { buildTheme } from '@botpress/webchat-generator'
import { useEffect, useState } from 'react'

type ThemeName = Parameters<typeof buildTheme>[0]['themeName']
export const useWebchatTheme = ({ themeName }: { themeName?: ThemeName } = {}) => {
  const [theme, setTheme] = useState<Theme>()
  const [style, setStyle] = useState<string>()

  useEffect(() => {
    const themeData = buildTheme({ themeColor: '#2563eb', themeName: themeName ?? 'prism' })
    setTheme(themeData.theme)
    setStyle(themeData.style)
  }, [])

  return { theme, style }
}
