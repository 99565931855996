// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListTasksRequestHeaders {}

export interface ListTasksRequestQuery {
  nextToken?: string;
  tags?: {
    [k: string]: string;
  };
  conversationId?: string;
  userId?: string;
  parentTaskId?: string;
  status?: ("pending" | "in_progress" | "failed" | "completed" | "blocked" | "paused" | "timeout" | "cancelled")[];
  type?: string;
}

export interface ListTasksRequestParams {}

export interface ListTasksRequestBody {}

export type ListTasksInput = ListTasksRequestBody & ListTasksRequestHeaders & ListTasksRequestQuery & ListTasksRequestParams

export type ListTasksRequest = {
  headers: ListTasksRequestHeaders;
  query: ListTasksRequestQuery;
  params: ListTasksRequestParams;
  body: ListTasksRequestBody;
}

export const parseReq = (input: ListTasksInput): ListTasksRequest & { path: string } => {
  return {
    path: `/v1/chat/tasks`,
    headers: {  },
    query: { 'nextToken': input['nextToken'], 'tags': input['tags'], 'conversationId': input['conversationId'], 'userId': input['userId'], 'parentTaskId': input['parentTaskId'], 'status': input['status'], 'type': input['type'] },
    params: {  },
    body: {  },
  }
}

export interface ListTasksResponse {
  tasks: {
    /**
     * Id of the [Task](#schema_task)
     */
    id: string;
    /**
     * Title describing the task
     */
    title: string;
    /**
     * All the notes related to the execution of the current task
     */
    description: string;
    /**
     * Type of the task
     */
    type: string;
    /**
     * Content related to the task
     */
    data: {
      [k: string]: any;
    };
    /**
     * Status of the task
     */
    status: "pending" | "in_progress" | "failed" | "completed" | "blocked" | "paused" | "timeout" | "cancelled";
    /**
     * Parent task id is the parent task that created this task
     */
    parentTaskId?: string;
    /**
     * Conversation id related to this task
     */
    conversationId?: string;
    /**
     * Specific user related to this task
     */
    userId?: string;
    /**
     * The timeout date where the task should be failed in the ISO 8601 format
     */
    timeoutAt: string;
    /**
     * Creation date of the task in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the task in ISO 8601 format
     */
    updatedAt: string;
    /**
     * If the task fails this is the reason behind it
     */
    failureReason?: string;
    /**
     * Set of [Tags](/docs/developers/concepts/tags) that you can attach to a [Task](#schema_task). Individual keys can be unset by posting an empty value to them.
     */
    tags: {
      [k: string]: string;
    };
  }[];
  meta: {
    /**
     * The token to use to retrieve the next page of results, passed as a query string parameter (value should be URL-encoded) to this API endpoint.
     */
    nextToken?: string;
  };
}

