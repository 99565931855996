import { Popover, Switch } from '../../elements'

type Props = {
  showTimestamp: boolean
  setShowTimestamp: React.Dispatch<React.SetStateAction<boolean>>
}

export function LogsSettingsDropdown({ children, showTimestamp, setShowTimestamp }: React.PropsWithChildren<Props>) {
  return (
    <Popover>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content className="flex flex-col">
        <Switch label="Show timestamps" checked={showTimestamp} onCheckedChange={setShowTimestamp} />
      </Popover.Content>
    </Popover>
  )
}
