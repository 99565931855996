import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient } from '~/client'
import { BotSummary } from '~/features/bots/types'
import { WorkspaceObject, WorkspaceSummary } from '~/features/workspaces/types'
import { getCdmStudioUrl, handleHttpErrors } from '~/shared'
import { getQueryKey, showConfirmationDialog } from '~/services'
import { trackEvent } from '~/providers'

export function useCreateBot(workspaceId: string) {
  const queryClient = useQueryClient()
  const listBotsQueryKey = getQueryKey('workspaces_/$workspaceId_/bots_', { workspaceId })

  return useMutation({
    mutationFn: async () => {
      return await getApiClient(workspaceId).createBot({}).catch(handleHttpErrors)
    },
    onSuccess: async (data) => {
      trackEvent({ type: 'create_bot', botId: data.bot.id })
      queryClient.setQueryData<BotSummary[]>(listBotsQueryKey, (bots) => {
        const prevBots = bots || []
        const { id, name, createdAt, updatedAt, deployedAt } = data.bot
        return [...prevBots, { id, name, createdAt, updatedAt, deployedAt }]
      })

      queryClient.setQueryData<WorkspaceSummary[]>(getQueryKey('workspaces_'), (workspaces) => {
        return workspaces?.map((workspace) => {
          if (workspace.id === workspaceId) {
            return {
              ...workspace,
              botCount: (workspace.botCount || 0) + 1,
            }
          }
          return workspace
        })
      })

      const workspaceQueryKey = getQueryKey('workspaces_/$workspaceId_', { workspaceId })
      queryClient.setQueryData<WorkspaceObject>(workspaceQueryKey, (workspace) => {
        if (!workspace) {
          return workspace
        }
        return {
          ...workspace,
          botCount: workspace.botCount + 1,
        }
      })

      showConfirmationDialog({
        title: 'Chatbot created',
        content: 'Your chatbot has been created successfully',
        onConfirm: () => {
          trackEvent({ type: 'open_bot_after_creation', botId: data.bot.id })
          window.open(getCdmStudioUrl(data.bot.id), '_blank')
        },
        confirmLabel: 'Open in Studio',
        cancelLabel: 'Close',
      })
    },
  })
}
