import { Outlet, createFileRoute } from '@tanstack/react-router'
import { SidebarLayout } from '~/layouts'
import { IntegrationIcon } from '~/features/integrations/components'
import { Badge, Container, Flex, Text } from '@radix-ui/themes'
import { Button, Icon, Link } from '~/elementsv2'
import { integrationQueryOptions, listWorkspaceIntegrationsQueryOptions } from '~/queries'
import cn from 'classnames'
import { Navbar, NavbarItem } from '~/componentsV2'
import { useCurrentRouteId } from '~/hooks'
import { PrivateIntegrationInstallPopover } from '../../../../features/integrations/components/PrivateIntegrationInstallPopover'
import { HiMiniShieldCheck, HiOutlineChevronDown, HiOutlineListBullet, HiOutlineTag } from 'react-icons/hi2'
import { useSuspenseQuery } from '@tanstack/react-query'
import _ from 'lodash'
import { setTag } from '@sentry/react'
import { isBotpressWorkspace } from '~/utils'

export const Route = createFileRoute('/workspaces/$workspaceId/integrations/$integrationId')({
  loader: ({ context, params: { integrationId, workspaceId } }) =>
    Promise.all([
      context.queryClient.ensureQueryData(listWorkspaceIntegrationsQueryOptions({ workspaceId })),
      context.queryClient.ensureQueryData(integrationQueryOptions({ integrationId, workspaceId })),
    ]),
  component: () => (
    <Container size={'4'} p={'5'}>
      <SidebarLayout main={<IntegrationComponent />} leftSidebar={<Sidebar />} />
    </Container>
  ),
})

const IntegrationComponent = () => {
  const currentRouteId = useCurrentRouteId()
  const { integrationId, workspaceId } = Route.useParams()
  const integration = useSuspenseQuery(integrationQueryOptions({ integrationId, workspaceId })).data
  const nVersions = useSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data.filter(
    (i) => i.name === integration.name
  ).length

  setTag('integrationId', integrationId)

  const items: NavbarItem[] = [
    {
      text: 'Details',
      to: '/workspaces/$workspaceId/integrations/$integrationId',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/',
    },
    {
      leadingIcon: HiOutlineListBullet,
      text: 'Logs',
      to: '/workspaces/$workspaceId/integrations/$integrationId/logs',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/logs',
    },
    {
      text: 'Settings',
      to: '/workspaces/$workspaceId/integrations/$integrationId/settings',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/settings',
    },
    {
      leadingIcon: HiOutlineTag,
      text: `${nVersions > 1 ? nVersions : ''} Versions`,
      to: '/workspaces/$workspaceId/integrations/$integrationId/versions',
      active: currentRouteId === '/workspaces/$workspaceId/integrations/$integrationId/versions',
    },
  ]

  return (
    <Flex direction={'column'} gap={'4'}>
      <Flex py={'2'} gap={'2'} align={'center'}>
        <IntegrationIcon className="w-12 text-gray-11" iconUrl={integration.iconUrl} />
        <Flex direction={'column'} gap={'1'}>
          <Text size={'6'} weight={'medium'}>
            {integration.name}
          </Text>
          <Flex gap={'2'} align={'center'}>
            <Text size={'1'} weight={'regular'} color={'gray'} className="italic">
              {integration.version}
            </Text>
            {integration.verificationStatus === 'approved' ||
              (isBotpressWorkspace(workspaceId) && (
                <Badge color="green" className="mr-1" size={'1'}>
                  Verified <Icon size="1" icon={HiMiniShieldCheck} />
                </Badge>
              ))}
          </Flex>
        </Flex>
        <Navbar className="ml-auto" workspaceId={workspaceId} integrationId={integration.id} items={items} />
        {integration ? (
          <PrivateIntegrationInstallPopover currentIntegration={integration} workspaceId={workspaceId}>
            <Button trailing={<HiOutlineChevronDown className="h-4" />}>Install</Button>
          </PrivateIntegrationInstallPopover>
        ) : null}
      </Flex>
      <Outlet />
    </Flex>
  )
}

export const Sidebar = () => {
  const { integrationId, workspaceId } = Route.useParams()
  const integrations = _(useSuspenseQuery(listWorkspaceIntegrationsQueryOptions({ workspaceId })).data)
    .groupBy('name')
    .mapValues((ints) => ints[0]!)
    .values()
    .value()

  return (
    <Flex direction="column" gap="4" className="max-h-[82vh] overflow-auto px-2 ">
      {integrations.map(({ name, id, iconUrl }) => (
        <Link
          key={id}
          to={'/workspaces/$workspaceId/integrations/$integrationId'}
          params={{ workspaceId, integrationId: id }}
          search={{}}
        >
          <Button
            variant="minimal"
            color="gray"
            className={cn('w-full justify-start rounded-md', { 'bg-gray-3': id === integrationId })}
          >
            <IntegrationIcon className="w-6 text-gray-11" iconUrl={iconUrl} />
            <Text>{name}</Text>
          </Button>
        </Link>
      ))}
    </Flex>
  )
}
