import { Outlet, createFileRoute } from '@tanstack/react-router'

import { botQueryOptions } from '~/queries/bots'
import { useLocalStore } from '~/stores'
import { userPreferencesQueryOptions } from '~/queries/user'
import { resolveKey, setPreference } from '~/services/preferences'
import { queryClient } from '~/providers/ReactQuery'
import { setTag } from '@sentry/react'
import { getQueryKey } from '~/services'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId')({
  beforeLoad: async ({ params }) => {
    const { setLastActiveWorkspaceAndBotIds } = useLocalStore.getState()
    setLastActiveWorkspaceAndBotIds(params.workspaceId, params.botId)
  },
  loader: async ({ context, params }) => context.queryClient.ensureQueryData(botQueryOptions(params)),
  onEnter: async ({ params, context }) => {
    const preferenceProps = { path: '$workspaceId/botHistory', params: { workspaceId: params.workspaceId } } as const
    const botHistory = await context.queryClient.ensureQueryData(userPreferencesQueryOptions(preferenceProps))
    const newHistory = [params.botId, ...botHistory].slice(0, 50)
    void setPreference({ ...preferenceProps, value: newHistory }).catch(() => {})

    const pathKey = resolveKey(preferenceProps)
    const queryKey = getQueryKey('preferences/$path', { path: pathKey })

    queryClient.setQueryData(queryKey, () => newHistory)
    setTag('botId', params.botId)
  },
  component: () => <Outlet />,
})
