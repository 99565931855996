// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetUpcomingInvoiceRequestHeaders {}

export interface GetUpcomingInvoiceRequestQuery {}

export interface GetUpcomingInvoiceRequestParams {
  id: string;
}

export interface GetUpcomingInvoiceRequestBody {}

export type GetUpcomingInvoiceInput = GetUpcomingInvoiceRequestBody & GetUpcomingInvoiceRequestHeaders & GetUpcomingInvoiceRequestQuery & GetUpcomingInvoiceRequestParams

export type GetUpcomingInvoiceRequest = {
  headers: GetUpcomingInvoiceRequestHeaders;
  query: GetUpcomingInvoiceRequestQuery;
  params: GetUpcomingInvoiceRequestParams;
  body: GetUpcomingInvoiceRequestBody;
}

export const parseReq = (input: GetUpcomingInvoiceInput): GetUpcomingInvoiceRequest & { path: string } => {
  return {
    path: `/v1/admin/workspaces/${encodeURIComponent(input['id'])}/billing/upcoming-invoice`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface GetUpcomingInvoiceResponse {
  /**
   * ID of the invoice.
   */
  id: string;
  /**
   * Total amount to pay of the invoice.
   */
  total: number;
}

