import { Container } from '@radix-ui/themes'
// import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Chat } from '~/features/bots/components'

import { useSuspenseQuery } from '~/services'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/chat')({
  component: () => {
    const { workspaceId, botId } = Route.useParams()
    const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data

    return (
      <Container size={'4'} px="5" my="4" className="*:relative *:h-[calc(100vh-16rem)]">
        <Chat bot={bot} workspaceId={workspaceId} />
      </Container>
    )
  },
})
