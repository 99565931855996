import { Container, Flex, Text, Separator } from '@radix-ui/themes'
import { Link, createFileRoute, useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import { Button, EmptyState, Link as LinkElement, Callout } from '~/elementsv2'
import { botQueryOptions, integrationQueryOptions } from '~/queries'
import { IntegrationIcon } from '~/features/integrations/components'
import { useUpdateBot } from '~/hooks'
import { capitalize } from 'lodash'
import { SidebarLayout } from '~/layouts'
import semver from 'semver'
import cn from 'classnames'
import { getIntegrationsFromBot } from '~/features/bots/services'
import { PublicIntegrationSummary } from '~/features/integrations'
import { getQueryOptions, showCustomDialog, showSuccessToast } from '~/services'
import { RemoveIntegrationDialog } from '~/features/integrations/components/RemoveIntegrationDialog'
import { queryOptions, useSuspenseQuery } from '@tanstack/react-query'
import { ThemeColor } from '../../../../../../providers/ThemeProvider'
import { openConfigureBotIntegrationStudioTab } from '~/features/integrations/services/utils'
import { HiArrowTopRightOnSquare } from 'react-icons/hi2'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/integrations/$integrationId')({
  beforeLoad: async ({ context, params }) => {
    const integration = await context.queryClient.ensureQueryData(integrationQueryOptions(params))
    const getLatestIntegrationVersion = (integrationName: string) => {
      if (!integration.public) {
        return queryOptions({
          staleTime: 0,
          queryFn: () => Promise.resolve([{ version: integration.version }] as PublicIntegrationSummary[]),
          queryKey: [integrationName, 'summary', integration.version],
        })
      }

      return getQueryOptions('integrations_/$integrationName/versions', { integrationName })
    }
    return { integration, getLatestIntegrationVersion }
  },
  loader: ({ context, params }) => {
    const { getLatestIntegrationVersion, integration } = context

    void Promise.all([
      context.queryClient.ensureQueryData(getLatestIntegrationVersion(integration.name)),
      context.queryClient.ensureQueryData(botQueryOptions({ botId: params.botId, workspaceId: params.workspaceId })),
    ])
  },
  component: () => {
    const { botId, workspaceId, integrationId } = Route.useParams()

    const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data
    const integration = useSuspenseQuery(integrationQueryOptions({ workspaceId, integrationId })).data

    const currentBotIntegration = getIntegrationsFromBot(bot).find((bi) => bi.id === integration.id)

    return (
      <Container size={'4'} p={'5'}>
        <Flex justify="between" align="center">
          <Text size={'5'}>Configure {integration.name}</Text>
          {currentBotIntegration && (
            <Button
              variant="outline"
              color="red"
              size={'1'}
              onClick={() =>
                showCustomDialog({
                  title: 'Remove Integration',
                  content: (
                    <RemoveIntegrationDialog integration={integration} workspaceId={workspaceId} botId={bot.id} />
                  ),
                })
              }
            >
              Uninstall
            </Button>
          )}
        </Flex>
        <Separator my={'4'} size="4" />
        <SidebarLayout main={<IntegrationConfiguration />} leftSidebar={<Sidebar />} />
      </Container>
    )
  },
})

const Sidebar = () => {
  const { integration } = Route.useRouteContext()
  const { botId, workspaceId } = Route.useParams()
  const botIntegrations = getIntegrationsFromBot(useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data!)

  return (
    <Flex direction="column" gap="4" className="max-h-[82vh] overflow-auto px-2 ">
      <LinkElement size={'1'} to={'/hub'} params={{}}>
        Find more on Botpress Hub
      </LinkElement>
      {botIntegrations.map(({ name, id, iconUrl, enabled }) => {
        const active = id === integration.id
        const children = (
          <Button
            key={id}
            variant="minimal"
            color="gray"
            className={cn('w-full justify-start rounded-md py-5', { 'bg-gray-3': active })}
          >
            <IntegrationIcon
              className={cn('w-6 text-gray-11 group-hover:opacity-100', {
                'opacity-70 grayscale': !enabled,
              })}
              iconUrl={iconUrl}
            />
            <Text>{name}</Text>
          </Button>
        )
        return active ? (
          children
        ) : (
          <Link
            key={id}
            to={'/workspaces/$workspaceId/bots/$botId/integrations/$integrationId'}
            params={{ botId, workspaceId, integrationId: id }}
          >
            {children}
          </Link>
        )
      })}
    </Flex>
  )
}

const IntegrationConfiguration = () => {
  const { workspaceId, botId, integrationId } = Route.useParams()
  const { getLatestIntegrationVersion } = Route.useRouteContext()
  const { mutate: updateBot, isPending: isInstalling } = useUpdateBot()
  const [isUpgrading, setIsUpgrading] = useState(false)
  const navigate = useNavigate()

  const integration = useSuspenseQuery(integrationQueryOptions({ workspaceId, integrationId })).data
  const latestIntegrationVersion = useSuspenseQuery(getLatestIntegrationVersion(integration.name)).data[0]
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data

  const botIntegration = getIntegrationsFromBot(bot).find((bi) => bi.id === integration.id)

  const newVersionAvailable = semver.gt(
    latestIntegrationVersion?.version ?? '0.0.0',
    botIntegration?.version ?? '100.0.0'
  )

  const upgradeIntegration = () => {
    if (!botIntegration || !latestIntegrationVersion) {
      return
    }
    const latestSem = semver.parse(latestIntegrationVersion.version)
    const currentSem = semver.parse(botIntegration.version)

    if (!latestSem || !currentSem) {
      return
    }

    const isMajorUpdate = latestSem.major !== currentSem.major
    if (isMajorUpdate) {
      return
    }

    const isPatchUpdate = latestSem.minor === currentSem.minor
    if (
      isPatchUpdate ||
      window.confirm(
        'Are you sure you want to update to a new version? Make sure you copy your configuration before updating.'
      )
    ) {
      updateBot({
        id: bot.id,
        workspaceId,
        integrations: {
          // We don't need to delete the old version, we can just add the new one
          // All the update logic is handled by the bridge
          // see packages/bridge/src/services/bot.ts updateIntegrations
          [latestIntegrationVersion.id]: {
            enabled: botIntegration.enabled,
          },
        },
        onSuccess: () => {
          showSuccessToast(`${integration.name} upgraded.`)
          navigate({
            to: '/workspaces/$workspaceId/bots/$botId/integrations/$integrationId',
            params: { botId, workspaceId, integrationId: latestIntegrationVersion.id },
          })
        },
      })
    }
  }

  const installIntegration = (integrationId: string) => {
    const botId = bot.id

    updateBot({
      id: botId,
      workspaceId,
      integrations: {
        [integrationId]: { enabled: false, configuration: {} },
      },
      onSuccess: () => {
        showSuccessToast(`${integration.name} installed.`)
      },
    })
  }

  return (
    <Flex direction="column" gap="4" className="max-h-[82vh] overflow-auto px-2 ">
      {!bot.deployedAt && (
        <Callout color="amber" className="mb-8">
          Your bot is not deployed yet. You can deploy it by clicking on the "Edit" button above and deploy it from our
          Studio.
        </Callout>
      )}
      {newVersionAvailable && (
        <Callout color={ThemeColor} className="mb-8">
          <div className="flex items-center justify-between">
            <span>
              Version <strong>{latestIntegrationVersion!.version}</strong> of {integration.name} is now available. You
              can update to get latest changes.
            </span>
            <Button
              loading={isUpgrading}
              onClick={() => {
                setIsUpgrading(true)
                upgradeIntegration()
                setIsUpgrading(false)
              }}
              className="ml-4"
              variant="soft"
              size={'1'}
            >
              Upgrade
            </Button>
          </div>
        </Callout>
      )}
      {botIntegration && integration.name === 'webchat' ? (
        <EmptyState
          title={`${integration.title}`}
          description="Webchat now has it's own home"
          primaryAction={
            <Button
              onClick={() =>
                navigate({ to: '/workspaces/$workspaceId/bots/$botId/webchat/general', params: { botId, workspaceId } })
              }
            >
              Go to Webchat settings
            </Button>
          }
        />
      ) : botIntegration && integration.name !== 'webchat' ? (
        <EmptyState
          title={`${integration.title}`}
          description="Integration configuration moved to studio"
          primaryAction={
            <Button onClick={() => openConfigureBotIntegrationStudioTab(bot.id, integrationId)}>
              <HiArrowTopRightOnSquare />
              Configure {integration.title} in Studio
            </Button>
          }
        />
      ) : (
        <EmptyState
          title={`${integration.title} is not installed`}
          description={`${capitalize(
            integration.name
          )} not installed in your bot. You can install it to enable new features and functionality.`}
          primaryAction={
            <Button loading={isInstalling} onClick={() => installIntegration(integration.id)}>
              Install {integration.name}
            </Button>
          }
        />
      )}
    </Flex>
  )
}
