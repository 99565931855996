import { isApiError } from 'botpress-client'
import { Suspense, useState } from 'react'
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { Toaster } from 'react-hot-toast'
import { EmptyState } from '../components'
import { logout } from '../services'
import { config, isHttpUnauthorizedError } from '../shared'
import { PartnerStackProvider } from './PartnerStack'
import { PatProvider } from './PatProvider'
import { ReactQueryProvider } from './ReactQuery'
import { UnleashProvider } from './Unleash'
import { UserProvider } from './UserProvider'
import { WorkspaceProvider } from './WorkspaceProvider'
import { SegmentProvider } from './SegmentProvider'
import { ThemeProvider } from './ThemeProvider'
import { captureException } from '@sentry/react'
import { DarkModeProvider } from '~/providers/DarkModeProvider'
import { BpSpinner } from '~/elementsv2'

type Props = {
  children: React.ReactNode
}

export const Providers = ({ children }: Props) => {
  const [errorId, setErrorId] = useState<string>()

  function handleError({ error }: FallbackProps) {
    if (isHttpUnauthorizedError(error) && !config.usePat) {
      // eslint-disable-next-line no-console
      console.debug('Unauthorized error received from API, logging out...')
      logout()
      return null
    }

    console.error(error)

    if (isApiError(error)) {
      setErrorId(error.id)
    }

    captureException(error)

    return (
      <EmptyState
        title="Sorry, something went wrong"
        message={`Try to refresh this page or please contact us if the problem persists.${
          errorId ? ` (Error ID: ${errorId})` : ''
        }`}
        icon="error"
      />
    )
  }

  return (
    <>
      <DarkModeProvider>
        <ThemeProvider>
          <ErrorBoundary fallbackRender={handleError}>
            <ReactQueryProvider>
              <Suspense
                fallback={
                  <div className="flex h-screen items-center justify-center">
                    <div className="size-20">
                      <BpSpinner />
                    </div>
                  </div>
                }
              >
                <Toaster />
                <PatProvider>
                  <UserProvider>
                    <SegmentProvider>
                      <UnleashProvider>
                        <WorkspaceProvider>{children}</WorkspaceProvider>
                        <PartnerStackProvider />
                      </UnleashProvider>
                    </SegmentProvider>
                  </UserProvider>
                </PatProvider>
              </Suspense>
            </ReactQueryProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </DarkModeProvider>
    </>
  )
}
