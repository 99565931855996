import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

type LocalStore = {
  theme: 'auto' | 'dark' | 'light'
  setTheme: (mode: 'auto' | 'dark' | 'light') => void
  useNewDashboard: boolean
  setUseNewDashboard: (version: boolean) => void
  lastActiveWorkspaceId?: string
  setLastActiveWorkspaceId: (id: string) => void
  lastActiveBotIds: Record<string, string>
  getLastActiveBotId: (workspaceId?: string) => string | undefined
  setLastActiveWorkspaceAndBotIds: (workspaceId: string, botId: string) => void
}

export const useLocalStore = create<LocalStore>()(
  devtools(
    persist(
      (set, get) => {
        return {
          theme: 'auto',
          setTheme: (mode: 'auto' | 'dark' | 'light') => {
            return set({ theme: mode })
          },
          useNewDashboard: false,
          setUseNewDashboard: (version: boolean) => set({ useNewDashboard: version }),
          lastActiveBotIds: {},
          setLastActiveWorkspaceAndBotIds: (lastActiveWorkspaceId, lastActiveBotId) => {
            set((state) => {
              return {
                ...state,
                lastActiveWorkspaceId,
                lastActiveBotIds: { ...state.lastActiveBotIds, [lastActiveWorkspaceId]: lastActiveBotId },
              }
            })
          },
          getLastActiveBotId: (overrideWorkspaceId) => {
            const workspaceId = overrideWorkspaceId ?? get().lastActiveWorkspaceId
            return workspaceId ? get().lastActiveBotIds?.[workspaceId] : undefined
          },
          setLastActiveWorkspaceId: (id) => {
            set((state) => {
              return { ...state, lastActiveWorkspaceId: id }
            })
          },
        }
      },
      {
        name: 'botpress-dashboard',
      }
    )
  )
)
