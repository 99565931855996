import { Container, Link as ThemeLink } from '@radix-ui/themes'
import { Link, Navigate, createFileRoute } from '@tanstack/react-router'
import { Button, Card, EmptyState } from '~/elementsv2'
import { getIntegrationsFromBot } from '~/features/bots/services'
import { HiOutlineCubeTransparent } from 'react-icons/hi2'
import { cn } from '~/utils'
import { useSuspenseQuery } from '@tanstack/react-query'
import { botQueryOptions } from '~/queries'

const Integrations = () => {
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data

  const [firstIntegration] = getIntegrationsFromBot(bot)

  return (
    <Container size={'3'} p="5">
      {firstIntegration ? (
        <Navigate
          to={'/workspaces/$workspaceId/bots/$botId/integrations/$integrationId'}
          params={{ botId, workspaceId, integrationId: firstIntegration.id }}
          replace
        />
      ) : (
        <Card className="py-10">
          <EmptyState
            className="max-w-md"
            mx={'auto'}
            icon={({ className, ...props }) => (
              <HiOutlineCubeTransparent strokeWidth="0.9" className={cn('text-accent-10', className)} {...props} />
            )}
            iconSize={5}
            title="No integrations installed"
            description="This bot does not have any integrations installed, you can install integrations to enable new features and functionality."
            primaryAction={
              <Button>
                <Link to="/hub">Browse hub integrations</Link>
              </Button>
            }
            secondaryAction={
              <ThemeLink href="https://botpress.com/docs/cloud/channels/overview/" size={'2'}>
                Learn more
              </ThemeLink>
            }
          />
        </Card>
      )}
    </Container>
  )
}

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/integrations/')({
  component: Integrations,
})
