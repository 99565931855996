import { nanoid } from 'nanoid'
import type { AnalyticsBoardConfig } from '../types'

export const minW = 2
export const minH = 2
export const version = 1
export const maxChartsPerPage = 15

const ids = [nanoid(), nanoid(), nanoid(), nanoid(), nanoid(), nanoid(), nanoid()] as const

export const defaultChartLayout: AnalyticsBoardConfig = {
  version,
  charts: [
    {
      id: ids[0],
      title: 'Last Month Users',
      type: 'metric',
      showLegend: true,
      dateRange: { shortcut: 'lastMonth' },
      values: ['Total Users'],
    },
    {
      id: ids[1],
      title: 'Users Graph',
      type: 'area',
      showLegend: false,
      dateRange: { shortcut: 'lastMonth' },
      values: ['New Users', 'Returning Users'],
    },
    {
      id: ids[2],
      title: 'Last Month User Types',
      type: 'donut',
      showLegend: true,
      dateRange: { shortcut: 'lastMonth' },
      values: ['New Users', 'Returning Users'],
    },
    {
      id: ids[3],
      title: 'Last Month Sessions',
      type: 'metric',
      showLegend: true,
      dateRange: { shortcut: 'lastMonth' },
      values: ['Bot Messages', 'User Messages', 'Sessions'],
    },
    {
      id: ids[4],
      title: 'Messages per Session',
      type: 'area',
      showLegend: false,
      dateRange: { shortcut: 'lastMonth' },
      values: ['Avg. Bot Messages per Session', 'Avg. User Messages per Session'],
    },
    {
      id: ids[5],
      title: 'Messages',
      type: 'bar',
      showLegend: false,
      dateRange: { shortcut: 'lastMonth' },
      values: ['Total Messages'],
    },
    {
      id: ids[6],
      title: 'Last 3 Months Overview',
      type: 'area',
      showLegend: true,
      dateRange: { shortcut: 'last3months' },
      values: ['Total Messages', 'Total Users', 'Sessions'],
    },
  ],
  layouts: {
    lg: [
      { i: ids[0], x: 0, y: 0, w: 3, h: 2, minW, minH },
      { i: ids[1], x: 3, y: 0, w: 6, h: 2, minW, minH },
      { i: ids[2], x: 9, y: 0, w: 3, h: 2, minW, minH },
      { i: ids[3], x: 6, y: 2, w: 6, h: 2, minW, minH },
      { i: ids[4], x: 0, y: 2, w: 6, h: 2, minW, minH },
      { i: ids[5], x: 0, y: 4, w: 5, h: 3, minW, minH },
      { i: ids[6], x: 5, y: 4, w: 7, h: 3, minW, minH },
    ],
    sm: [
      { i: ids[0], x: 0, y: 0, w: 2, h: 2, minW, minH },
      { i: ids[1], x: 0, y: 1, w: 2, h: 2, minW, minH },
      { i: ids[2], x: 0, y: 2, w: 2, h: 2, minW, minH },
      { i: ids[3], x: 0, y: 3, w: 2, h: 2, minW, minH },
      { i: ids[4], x: 0, y: 4, w: 2, h: 2, minW, minH },
      { i: ids[5], x: 0, y: 5, w: 2, h: 2, minW, minH },
      { i: ids[6], x: 0, y: 5, w: 2, h: 2, minW, minH },
    ],
  },
}
