import { Webchat, WebchatProvider } from '@botpress/webchat'
import { Text, Badge, Flex, ScrollArea, Switch } from '@radix-ui/themes'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { HiOutlineFunnel, HiOutlineTrash, HiOutlineXMark } from 'react-icons/hi2'
import { z } from 'zod'
import { ShadowRoot } from '~/componentsV2'
import { Button, Page, type MenuItem, DropdownMenu, Avatar, IconButton } from '~/elementsv2'
import { ConversationDetails, ConversationList } from '~/features/hitl/components'
import { conversationStatusesMap } from '~/features/hitl/constants'
import { conversationStatuses, type ConversationStatus } from '~/features/hitl/types'
import { useWebchatTheme } from '~/hooks'
import { SidebarLayout } from '~/layoutsV2'
import { listHitlConversationsInfiniteQuery } from '~/queries'
import { useSuspenseQuery } from '~/services'
import { getClient, HITLClient } from '~/features/hitl/client'
import { useState } from 'react'
import { useAsync, useLocalStorage } from 'react-use'

const paramsSchema = z.object({
  conversationId: z
    .string()
    .optional()
    .catch(() => undefined),
  assignee: z.string().optional(),
  status: z
    .enum(conversationStatuses)
    .optional()
    .catch(() => undefined),
})

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/hitl')({
  component: Component,
  validateSearch: paramsSchema,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { conversationId, status, assignee: assigneeId } = Route.useSearch()
  const navigate = useNavigate({ from: Route.fullPath })

  const [disableShadowRoot, setDisableShadowRoot] = useLocalStorage('disableShadowRoot', true)

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const assignee = workspaceMembers.find((m) => m.id === assigneeId)

  const { data: fetchedConversations } = useSuspenseInfiniteQuery(
    listHitlConversationsInfiniteQuery({ botId, workspaceId })
  )

  const [hitlClient, setHitlClient] = useState<HITLClient>()
  useAsync(async () => {
    if (hitlClient?.workspaceId === workspaceId && hitlClient?.botId === botId) {
      return
    }
    const client = await getClient({ workspaceId, botId })
    setHitlClient(client)
  }, [workspaceId, botId])

  const allConversations = fetchedConversations.pages?.flatMap((page) => page.conversations) ?? []
  const ongoingConversations = allConversations.filter((c) => c.status !== 'solved' && c.status !== 'cancelled')
  const conversations = ongoingConversations.filter((c) => {
    return (status ? c.status === status : true) && (assigneeId ? c.assignee?.workspaceMemberId === assigneeId : true)
  })
  const currentConversation = conversations.find((c) => c.id === conversationId)

  const conversationsFilterMenu: MenuItem[] = [
    {
      type: 'label',
      label: 'Filter by',
    },
    {
      type: 'submenu',
      content: 'Status',
      items: [
        {
          type: 'radioGroup',
          value: status,
          onValueChange: (value) =>
            navigate({
              params: { botId, workspaceId },
              search: { conversationId, assignee: assigneeId, status: value as ConversationStatus },
            }),

          items: Object.entries(conversationStatusesMap).map(([status, { title }]) => ({
            type: 'radioItem',
            value: status,
            content: title,
          })),
        },
      ],
    },
    {
      type: 'submenu',
      content: 'Assignee',
      items: [
        {
          type: 'radioGroup',
          onValueChange: (value) =>
            navigate({
              params: { botId, workspaceId },
              search: { conversationId, assignee: value, status },
            }),
          value: assigneeId,
          items: workspaceMembers.map(({ email, id, displayName, profilePicture }) => ({
            type: 'radioItem',
            value: id,
            content: displayName ?? email,
            leadingIcon: <Avatar size={'1'} name={email} pictureUrl={profilePicture} />,
          })),
        },
      ],
    },
    { type: 'separator' },
    {
      type: 'item',
      color: 'red',
      content: 'Clear filters',
      trailingIcon: <HiOutlineTrash />,
      onSelect: () =>
        navigate({
          params: { botId, workspaceId },
          search: { conversationId },
        }),
    },
  ]

  const { theme, style } = useWebchatTheme({ themeName: 'eggplant' })

  const WebchatAndProvider = () => (
    <WebchatProvider
      disableRestartConversation={true}
      closeWindow={undefined}
      theme={theme}
      client={hitlClient}
      conversationId={conversationId}
    >
      <Webchat />
    </WebchatProvider>
  )

  return (
    <Page title="Human in the loop">
      <div
        style={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: 1000,
        }}
      >
        <span>Disable Shadow Root</span>
        <Switch
          checked={disableShadowRoot}
          onCheckedChange={(disableShadowRoot) => {
            setDisableShadowRoot(disableShadowRoot)
          }}
        />
      </div>

      <SidebarLayout
        main={
          theme && hitlClient && conversationId ? (
            <Flex className="h-full min-h-200 " justify={'center'} align={'center'}>
              {disableShadowRoot ? (
                <>
                  <style>{style}</style>
                  <WebchatAndProvider />
                </>
              ) : (
                <ShadowRoot style={{ width: '100%', height: '100%' }}>
                  <style>{style}</style>
                  <WebchatAndProvider />
                </ShadowRoot>
              )}
            </Flex>
          ) : null
        }
        leftSidebarSize="4"
        leftSidebar={
          <Flex gap={'2'} direction={'column'} className="min-w-0">
            <Flex gap={'2'} wrap={'wrap'}>
              <DropdownMenu color="gray" content={conversationsFilterMenu}>
                <Button trailing={<HiOutlineFunnel />} variant={'outline'} color="gray" size={'2'}>
                  Filters
                </Button>
              </DropdownMenu>
              {status && (
                <Badge variant="surface" size={'2'} color={conversationStatusesMap[status].color}>
                  {conversationStatusesMap[status].title}
                  <IconButton
                    size={'1'}
                    className="my-0"
                    variant="ghost"
                    icon={HiOutlineXMark}
                    onClick={() =>
                      navigate({
                        params: { botId, workspaceId },
                        search: { conversationId, assignee: assigneeId, status: undefined },
                      })
                    }
                  />
                </Badge>
              )}
              {assignee && (
                <Badge className="min-w-0" variant="outline" size={'2'} color="gray">
                  <Flex gap={'2'} align={'center'}>
                    <Avatar size={'1'} className="size-4" name={assignee.email} pictureUrl={assignee.profilePicture} />
                    <Text truncate>{assignee.displayName ?? assignee.email}</Text>
                    <IconButton
                      size={'1'}
                      className="my-0"
                      variant="ghost"
                      icon={HiOutlineXMark}
                      onClick={() =>
                        navigate({
                          params: { botId, workspaceId },
                          search: { conversationId, assignee: undefined, status },
                        })
                      }
                    />
                  </Flex>
                </Badge>
              )}
            </Flex>
            <ScrollArea scrollbars="vertical" className="h-[calc(100vh-24rem)] [&>div>div]:!w-auto">
              <ConversationList
                conversations={conversations}
                botId={botId}
                workspaceId={workspaceId}
                currentConversationId={conversationId}
              />
            </ScrollArea>
          </Flex>
        }
        rightSidebar={
          currentConversation ? (
            <ConversationDetails botId={botId} workspaceId={workspaceId} conversation={currentConversation} />
          ) : (
            <div />
          )
        }
      />
    </Page>
  )
}
