// this file was automatically generated, do not edit
/* eslint-disable */

export interface GetEventRequestHeaders {}

export interface GetEventRequestQuery {}

export interface GetEventRequestParams {
  id: string;
}

export interface GetEventRequestBody {}

export type GetEventInput = GetEventRequestBody & GetEventRequestHeaders & GetEventRequestQuery & GetEventRequestParams

export type GetEventRequest = {
  headers: GetEventRequestHeaders;
  query: GetEventRequestQuery;
  params: GetEventRequestParams;
  body: GetEventRequestBody;
}

export const parseReq = (input: GetEventInput): GetEventRequest & { path: string } => {
  return {
    path: `/v1/chat/events/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface GetEventResponse {
  /**
   * The event object represents an action or an occurrence.
   */
  event: {
    /**
     * Id of the [Event](#schema_event)
     */
    id: string;
    /**
     * Creation date of the [Event](#schema_event) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Type of the [Event](#schema_event).
     */
    type: string;
    /**
     * Payload is the content of the event defined by the integration installed on your bot or one of the default events created by our api.
     */
    payload: {
      [k: string]: any;
    };
    /**
     * ID of the [Conversation](#schema_conversation) to link the event to.
     */
    conversationId?: string;
    /**
     * ID of the [User](#schema_user) to link the event to.
     */
    userId?: string;
    /**
     * ID of the [Message](#schema_message) to link the event to.
     */
    messageId?: string;
    status: "pending" | "processed" | "ignored" | "failed";
    /**
     * Reason why the event failed to be processed
     */
    failureReason: string | null;
  };
}

