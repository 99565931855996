import { QuotaType } from '@bpinternal/const'
import { roundPrice, toOptionalFixed } from '~/utils'

type StrokeColor<T extends string = string, U extends string = string> = `stroke-${T}-${U}`

const formatters = {
  // Takes in a number of bytes as input and returns a human-readable string (in SI system), e.g.
  // - 1000 bytes => 1 kB
  // - 1000000 bytes => 1 MB
  // - 1000000000 bytes => 1 GB
  byte(value: number) {
    const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB']
    const unitIndex = Math.floor(Math.log(value) / Math.log(1000))
    const unit = units[unitIndex]
    const unitValue = value / 1000 ** unitIndex
    return `${Intl.NumberFormat('en').format(toOptionalFixed(unitValue, 1))} ${unit}`
  },
  number(value: number) {
    return Intl.NumberFormat('en', { notation: 'compact' }).format(value)
  },
  numberDefault(value: number) {
    return Intl.NumberFormat('en').format(value)
  },
  nanodollars(value: number) {
    return `$${roundPrice(nanoToBase(value)).toFixed(2).toString()}`
  },
}

export function formatValueWithUnit(value: number, type?: QuotaType): string {
  if (!value) {
    return '0'
  }
  if (type === 'storage_count' || type === 'knowledgebase_vector_storage') {
    return formatters.byte(value)
  }
  if (type === 'ai_spend') {
    return formatters.nanodollars(value)
  }
  if (!type) {
    return formatters.numberDefault(value)
  }
  return formatters.number(value)
}

export function getColorClassForPercent(percent: number): StrokeColor {
  if (percent > 100) {
    return 'stroke-rose-600'
  }
  if (percent >= 90) {
    return 'stroke-red-500'
  }
  if (percent >= 75) {
    return 'stroke-yellow-500'
  }
  return 'stroke-blue-500'
}

export function getColorClassVariablesForPercent(percent: number): string {
  if (percent >= 100) {
    return '[--tremor-default:221_68_37] [--tremor-muted:255_220_211]'
  }
  if (percent >= 75) {
    return '[--tremor-default:255_220_0] [--tremor-muted:255_243_148]'
  }
  return '[--tremor-default:5_136_240] [--tremor-muted:213_239_255]'
}

export function nanoToBase(nano: number): number {
  const nanoDollarsPerDollar = 1_000_000_000
  return nano / nanoDollarsPerDollar
}
