import { Badge, Flex, Separator, Text } from '@radix-ui/themes'

import type { Conversation } from 'hitl-client'
import { HiOutlineArrowUturnRight, HiOutlineChevronDown, HiOutlineUser, HiOutlineUserMinus } from 'react-icons/hi2'
import { Identifier, UserBadge } from '~/componentsV2'
import { Avatar, Button, DataListItem, DataListRoot, DropdownMenu } from '~/elementsv2'
import { getClient } from '~/features/hitl/client'
import { useConversationParticipants } from '~/hooks'
import { ThemeColor } from '~/providers/ThemeProvider'
import { useSuspenseQuery } from '~/services'
import { cn } from '~/utils'

type Props = { className?: string; conversation: Conversation; botId: string; workspaceId: string }

export const ConversationDetails = ({ className, conversation, botId, workspaceId, ...props }: Props) => {
  const participants = useConversationParticipants({ botId, conversationId: conversation.id, workspaceId }).data

  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data
  const assigneeId = conversation.assignee?.workspaceMemberId
  const assignee = workspaceMembers?.find((member) => member.id === assigneeId)

  const shownParticipants = participants?.slice(0, 10) ?? []
  const hiddenParticipants = participants?.slice(10) ?? []

  return (
    <Flex direction={'column'} gap={'4'}>
      <DataListRoot orientation={'vertical'} {...props} className={cn(className)}>
        <DataListItem label="Conversation Id">
          <Identifier id={conversation.id} color={ThemeColor} />
        </DataListItem>
        <DataListItem label="Participants">
          <Flex gap={'2'} wrap="wrap">
            {shownParticipants.map((participant) => (
              <UserBadge
                key={participant.id}
                variant="soft"
                avatarName={participant.name ?? `Anonymous User ${participant.id}`}
                name={participant.name ?? 'Anonymous User'}
                pictureUrl={participant.pictureUrl}
              />
            ))}
            {hiddenParticipants.length > 0 && (
              <Badge variant="outline" color="gray">
                <Flex gap={'2'} align={'center'}>
                  <Text>+ {hiddenParticipants.length} more</Text>
                </Flex>
              </Badge>
            )}
          </Flex>
        </DataListItem>
        {assignee && (
          <DataListItem label="Assignee">
            <UserBadge
              variant="soft"
              name={assignee.displayName ?? assignee.email}
              pictureUrl={assignee.profilePicture}
            />
          </DataListItem>
        )}
      </DataListRoot>
      <Separator size={'4'} />

      <DropdownMenu
        color="gray"
        content={[
          {
            type: 'submenu',
            content: 'Assign to conversation',
            items: [
              {
                type: 'radioGroup',
                value: assigneeId,
                items: workspaceMembers.map(({ email, id, displayName, profilePicture }) => ({
                  type: 'radioItem',
                  value: id,
                  content: displayName ?? email,
                  onSelect: () => {
                    void getClient({ workspaceId, botId }).then((client) =>
                      client.setAssignee({
                        conversationId: conversation.id,
                        workspaceMemberId: id,
                      })
                    )
                  },
                  leadingIcon: (
                    <Avatar
                      variant="soft"
                      size={'1'}
                      className="size-4 h-4"
                      name={displayName ?? email}
                      pictureUrl={profilePicture}
                    />
                  ),
                })),
              },
              ...(assigneeId
                ? ([
                    { type: 'separator' },
                    {
                      type: 'item',
                      color: 'red',
                      content: 'Unassign from conversation',
                      leadingIcon: <HiOutlineUserMinus />,
                      onSelect: () => {
                        void getClient({ workspaceId, botId }).then((client) =>
                          client.setAssignee({
                            conversationId: conversation.id,
                            workspaceMemberId: null,
                          })
                        )
                      },
                    },
                  ] as const)
                : []),
            ],
          },
          { type: 'separator' },
          {
            type: 'item',
            color: 'red',
            content: 'Handover to bot',
            trailingIcon: <HiOutlineArrowUturnRight />,
            onSelect: () => {
              void getClient({ workspaceId, botId }).then((client) =>
                client.solveTicket({ conversationId: conversation.id })
              )
            },
          },
        ]}
      >
        <Button variant="soft" color="grass" size={'2'} trailing={<HiOutlineChevronDown />} leading={<HiOutlineUser />}>
          Update Assignee
        </Button>
      </DropdownMenu>
    </Flex>
  )
}
