import { useState } from 'react'
import { Button, Pane, Tooltip } from '../../../elements'
import { useDeleteWorkspace, useWorkspaces } from '../../../hooks'
import { showErrorToast, useQuery } from '../../../services'
import { WorkspaceObject } from '../../workspaces/types'
import { Permission } from '~/features/users/types'
import { wasResolved } from '~/shared/helpers'
import { isApiError } from 'botpress-client'
import { HttpStatusCode } from 'axios'
import { DeletionModal } from '~/components'

type DangerZoneProps = {
  workspace: WorkspaceObject
  isAuthorized: (permission: Permission) => boolean | undefined
  navigateToHome: () => Promise<void>
}

export function DangerZone({ workspace, isAuthorized, navigateToHome }: DangerZoneProps) {
  const { data: workspaces } = useWorkspaces()

  const { data: bots } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: workspace.id })
  const { mutateAsync: deleteWorkspace } = useDeleteWorkspace()

  const [modalOpen, setModalOpen] = useState(false)

  if (!isAuthorized('workspace.delete')) {
    return null
  }

  function getDeletionRestrictionMessage(): string | undefined {
    if (workspaces && workspaces.length <= 1) {
      return "This workspace cannot be deleted because it's the only workspace currently in your account."
    }
    if (bots?.length && bots.length > 0) {
      return 'Please delete all the chatbots in your workspace first in order to delete this workspace.'
    }
    return undefined
  }

  const cannotDeleteErrorMessage = getDeletionRestrictionMessage()
  const cannotDelete = cannotDeleteErrorMessage !== undefined

  function onClick() {
    if (cannotDelete) {
      showErrorToast(cannotDeleteErrorMessage)
      return
    }
    setModalOpen(true)
  }

  const onDelete = async () => {
    return wasResolved(
      deleteWorkspace(
        { id: workspace.id },
        {
          onSuccess: () => {
            void navigateToHome()
          },
          onError: (error) => {
            if (isApiError(error)) {
              if (error.code === (HttpStatusCode.PaymentRequired as number)) {
                showErrorToast(
                  'We were unable to process the payment for the outstanding balance on the workspace. ' +
                    'Please check the payment method on file and try to delete the workspace again. ' +
                    'If it looks fine, please contact us.'
                )
              } else {
                showErrorToast(`Sorry, an error occurred while trying to delete the workspace: ${error.message}`)
              }
            }
          },
        }
      )
    )
  }

  return (
    <>
      <Pane>
        <Pane.Body>
          <h3 className="pb-2 text-lg text-red-10"> Delete Workspace</h3>
          <p className="text-gray-11">
            Deleting a workspace is an irreversible process and will permanently erase all data associated with this
            workspace. Please download your invoices from the Billing section before proceeding.
          </p>
        </Pane.Body>
        <Pane.Footer className="flex justify-end">
          <Tooltip disabled={!cannotDelete} label={cannotDeleteErrorMessage}>
            <Button variant="secondary" color="error" disabled={cannotDelete} onClick={onClick}>
              Delete Workspace
            </Button>
          </Tooltip>
        </Pane.Footer>
      </Pane>

      <DeletionModal
        title="Delete Workspace"
        onDelete={onDelete}
        deleteButtonText="Delete"
        open={modalOpen}
        setOpen={setModalOpen}
      >
        <p>
          This will permanently delete the workspace &quot;{workspace.name}&quot;. If there's any outstanding balance on
          the workspace, the payment method configured for the workspace will be charged now
          <b> before the workspace can be deleted</b>.
        </p>
        <p>Are you sure you want to proceed?</p>
      </DeletionModal>
    </>
  )
}
