// this file was automatically generated, do not edit
/* eslint-disable */

export interface FindTableRowsRequestHeaders {}

export interface FindTableRowsRequestQuery {}

export interface FindTableRowsRequestParams {
  table: string;
}

export interface FindTableRowsRequestBody {
  /**
   * Limit for pagination, specifying the maximum number of rows to return.
   */
  limit?: number;
  /**
   * Offset for pagination, specifying where to start returning rows from.
   */
  offset?: number;
  /**
   * Provide a mongodb-like filter to apply to the query. Example: \{ "name": \{ "$eq": "John" \} \}
   */
  filter?: {
    [k: string]: any;
  };
  /**
   * Group the rows by a specific column and apply aggregations to them. Allowed values: key, avg, max, min, sum, count. Example: \{ "someId": "key", "orders": ["sum", "avg"] \}
   */
  group?: {
    [k: string]: any;
  };
  /**
   * Search term to apply to the row search. When using this parameter, some rows which doesn't match the search term will be returned, use the similarity field to know how much the row matches the search term.
   */
  search?: string;
  /**
   * Specifies the column by which to order the results. By default it is ordered by id. Build-in columns: id, createdAt, updatedAt
   */
  orderBy?: string;
  /**
   * Specifies the direction of sorting, either ascending or descending.
   */
  orderDirection?: "asc" | "desc";
}

export type FindTableRowsInput = FindTableRowsRequestBody & FindTableRowsRequestHeaders & FindTableRowsRequestQuery & FindTableRowsRequestParams

export type FindTableRowsRequest = {
  headers: FindTableRowsRequestHeaders;
  query: FindTableRowsRequestQuery;
  params: FindTableRowsRequestParams;
  body: FindTableRowsRequestBody;
}

export const parseReq = (input: FindTableRowsInput): FindTableRowsRequest & { path: string } => {
  return {
    path: `/v1/tables/${encodeURIComponent(input['table'])}/rows/find`,
    headers: {  },
    query: {  },
    params: { 'table': input['table'] },
    body: { 'limit': input['limit'], 'offset': input['offset'], 'filter': input['filter'], 'group': input['group'], 'search': input['search'], 'orderBy': input['orderBy'], 'orderDirection': input['orderDirection'] },
  }
}

export interface FindTableRowsResponse {
  rows: {
    /**
     * Unique identifier for the row.
     */
    id: number;
    /**
     * Timestamp of row creation.
     */
    createdAt?: string;
    /**
     * Timestamp of the last row update.
     */
    updatedAt?: string;
    computed: {
      [k: string]: {
        status: string;
        error?: string;
        updatedBy?: string;
        updatedAt?: string;
      };
    };
    /**
     * [Read-only] List of stale values that are waiting to be recomputed.
     */
    stale?: string[];
    /**
     * Optional numeric value indicating similarity, when using findTableRows.
     */
    similarity?: number;
    [k: string]: any;
  }[];
  /**
   * Flag indicating if there are more rows to fetch.
   */
  hasMore: boolean;
  offset: number;
  limit: number;
  /**
   * Alerts for minor issues that don't block the operation but suggest possible improvements.
   */
  warnings?: string[];
}

