import { createFileRoute } from '@tanstack/react-router'
import { useSuspenseInfiniteQuery, useSuspenseQuery } from '@tanstack/react-query'
import {
  conversationQueryOptions,
  getIntegrationByNameQueryOptions,
  listConversationsInfiniteQuery,
  listConversationUsersQueryOptions,
  messageQueryOptions,
} from '~/queries'
import {
  Breadcrumbs,
  Button,
  Card,
  DataListItem,
  DataListRoot,
  EmptyState,
  Icon,
  JSONTree,
  Link,
  Page,
  type BreadcrumbPage,
} from '~/elementsv2'
import { SidebarLayout } from '~/layouts'
import { getStateQueryOptions } from '~/queries/states'
import { Badge, Flex, Separator, Text, Grid, Tabs, Box } from '@radix-ui/themes'
import { DateTime } from 'luxon'
import { Tag } from '~/elementsv2/Tag'
import { WebchatProvider } from '@botpress/webchat'
import { buildEggplantTheme } from '@botpress/webchat-generator'
import { useWebchatMessages } from '~/hooks'
import { blue } from '@radix-ui/colors'
import { z } from 'zod'
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import { ImageRenderer, Message } from '~/features/conversations/components'
import { Boundrary, Identifier, IdentifierDropdownMenu, UserBadge } from '~/componentsV2'
import { HiOutlineChatBubbleLeft, HiOutlineChatBubbleLeftRight } from 'react-icons/hi2'
import { cn } from '~/utils'
import { match } from 'ts-pattern'

const conversationsSearchSchema = z.object({
  messageId: z
    .string()
    .optional()
    .catch(() => undefined),
})
export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/conversations/$conversationId')({
  validateSearch: conversationsSearchSchema.parse,
  component: Component,
})

function Component() {
  const { workspaceId, botId, conversationId } = Route.useParams()
  const breadcrumbs: BreadcrumbPage[] = [
    {
      name: 'Conversations',
      to: '/workspaces/$workspaceId/bots/$botId/conversations',
      params: { workspaceId, botId },
      search: {},
    },
    { name: conversationId, params: {}, search: {} },
  ]
  return (
    <Page title={<Breadcrumbs pages={breadcrumbs} size={'4'} />}>
      <Boundrary height={'200px'} loaderSize="6">
        <SidebarLayout leftSidebar={<LeftSidebar />} main={<Main />} />
      </Boundrary>
    </Page>
  )
}

const Main = () => {
  const { workspaceId, botId, conversationId } = Route.useParams()
  const { messageId } = Route.useSearch()
  const { conversation } = useSuspenseQuery(conversationQueryOptions({ botId, workspaceId, conversationId })).data
  const { users } = useSuspenseQuery(listConversationUsersQueryOptions({ conversationId, botId, workspaceId })).data
  const { state } = useSuspenseQuery(
    getStateQueryOptions({
      botId,
      workspaceId,
      id: conversationId,
      type: 'conversation',
      name: 'agentsConversationVariables',
    })
  ).data

  const integration = useSuspenseQuery(getIntegrationByNameQueryOptions({ name: conversation.integration })).data
  return (
    <Grid gap={'4'} className="@container @4xl:grid-cols-[1fr,auto] @4xl:grid-rows-[auto,1fr]">
      <Card className="flex flex-col gap-6 p-6">
        <Flex gap="2" align={'center'}>
          <Icon size="5" icon={(props) => <img src={integration.iconUrl} {...props} />} />
          <Text>{integration.title}</Text>
          <Text size={'2'} color="gray" ml={'auto'}>
            {DateTime.fromISO(conversation.updatedAt).toRelative()}
          </Text>
        </Flex>
        <Tabs.Root defaultValue="details">
          <Tabs.List>
            <Tabs.Trigger value="details">Details</Tabs.Trigger>
            <Tabs.Trigger value="json">JSON</Tabs.Trigger>
          </Tabs.List>
          <Box pt="4">
            <Tabs.Content value="details">
              <DataListRoot size={'2'}>
                {state?.payload.SummaryAgent?.summary && (
                  <DataListItem label="Summary">
                    <Text size={'1'} className="line-clamp-6">
                      {state?.payload.SummaryAgent?.summary}
                    </Text>
                  </DataListItem>
                )}
                <DataListItem align={'center'} label="Conversation ID">
                  <Identifier id={conversation.id} />
                </DataListItem>
                <DataListItem label="Channel">
                  <Badge variant="surface">{conversation.channel}</Badge>
                </DataListItem>
                <DataListItem label="Updated At">{DateTime.fromISO(conversation.updatedAt).toHTTP()}</DataListItem>
                {(users?.length ?? 0) > 0 && (
                  <DataListItem label="Participants">
                    <Flex wrap={'wrap'} gap={'2'}>
                      {users?.map(({ name, pictureUrl, id }) => (
                        <UserBadge className="max-w-36" key={id} name={name} pictureUrl={pictureUrl} userId={id} />
                      ))}
                    </Flex>
                  </DataListItem>
                )}
                <DataListItem align={'start'} label="Tags">
                  <Flex className="w-full" wrap={'wrap'} gap={'2'}>
                    {Object.entries(conversation.tags)
                      .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                      .map(([key, value]) => (
                        <IdentifierDropdownMenu key={key} path={key} value={value}>
                          <Tag clickable className="max-w-full" label={key} value={value} />
                        </IdentifierDropdownMenu>
                      ))}
                  </Flex>
                </DataListItem>
              </DataListRoot>
            </Tabs.Content>
            <Tabs.Content value="json">
              <JSONTree data={conversation} hideRoot />
            </Tabs.Content>
          </Box>
        </Tabs.Root>
      </Card>
      <MessageList
        className="max-h-200 min-h-36 @4xl:row-span-2 @4xl:min-h-full @4xl:w-[400px]"
        botId={botId}
        conversationId={conversation.id}
        workspaceId={workspaceId}
        messageId={messageId}
      />
      {messageId ? (
        <Boundrary className="h-64" loaderSize="6">
          <MessageCard messageId={messageId} className="min-h-36 p-6 pt-3" />
        </Boundrary>
      ) : (
        <Card className="flex min-h-36 items-center justify-center border-dashed">
          <Flex align={'center'} gap={'2'} className="opacity-60">
            <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeft} />
            <Text color="gray">No message selected</Text>
          </Flex>
        </Card>
      )}
    </Grid>
  )
}

const LeftSidebar = () => {
  const { workspaceId, botId, conversationId } = Route.useParams()
  const { conversation } = useSuspenseQuery(conversationQueryOptions({ botId, workspaceId, conversationId })).data
  const integration = useSuspenseQuery(
    getIntegrationByNameQueryOptions({ name: conversation.integration, workspaceId })
  ).data
  const { users } = useSuspenseQuery(listConversationUsersQueryOptions({ conversationId, botId, workspaceId })).data

  const { data: fetchedConversationsFromSameIntegration } = useSuspenseInfiniteQuery(
    listConversationsInfiniteQuery({ botId, workspaceId, integrationName: conversation.integration })
  )
  const { data: fetchedConversationsWithSameUser } = useSuspenseInfiniteQuery(
    listConversationsInfiniteQuery({ botId, workspaceId, participantIds: users.map((u) => u.id) })
  )

  const conversationsFromSameIntegration = (
    fetchedConversationsFromSameIntegration.pages?.flatMap((page) => page.conversations) ?? []
  ).filter((conv) => conv.id !== conversationId)

  const conversationsWithSameUser = (
    fetchedConversationsWithSameUser.pages?.flatMap((page) => page.conversations) ?? []
  )
    .filter(
      (conv) => conv.id !== conversationId && conversationsFromSameIntegration.findIndex((c) => c.id === conv.id) === -1
    )
    .sort((a, b) => DateTime.fromISO(b.updatedAt).toMillis() - DateTime.fromISO(a.updatedAt).toMillis())

  return (
    <Flex className="flex flex-col gap-4">
      <Flex direction={'column'} gap={'4'}>
        <Text size={'2'} weight={'medium'}>
          More from {integration.title}
        </Text>
        {conversationsFromSameIntegration.slice(0, 4).map((conv) => (
          <Flex key={conv.id} gap={'2'} align={'start'}>
            <Icon
              size="2"
              className="mt-1.5"
              variant={'surface'}
              color={'gray'}
              icon={(props) => <img src={integration.iconUrl} {...props} />}
            />
            <Flex className="truncate" direction={'column'}>
              <Link
                to="/workspaces/$workspaceId/bots/$botId/conversations/$conversationId"
                params={{ botId, conversationId: conv.id, workspaceId }}
                truncate
                size={'2'}
              >
                {conv.id}
              </Link>
              <Text truncate color="gray" size={'1'}>
                {DateTime.fromISO(conv.updatedAt).toRelative()}
              </Text>
            </Flex>
          </Flex>
        ))}
      </Flex>
      {conversationsWithSameUser.length > 0 && (
        <>
          <Separator size={'4'} />
          <Flex direction={'column'} gap={'4'}>
            <Text size={'2'} weight={'medium'}>
              More from the same participants
            </Text>
            {conversationsWithSameUser.slice(0, 4).map((conv) => (
              <Flex key={conv.id} gap={'2'} align={'start'}>
                <Icon
                  size="2"
                  className="mt-1.5"
                  variant={'surface'}
                  color={'gray'}
                  icon={(props) => <img src={integration.iconUrl} {...props} />}
                />
                <Flex className="truncate" direction={'column'}>
                  <Link
                    to="/workspaces/$workspaceId/bots/$botId/conversations/$conversationId"
                    params={{ botId, conversationId: conv.id, workspaceId }}
                    truncate
                    size={'2'}
                  >
                    {conv.id}
                  </Link>
                  <Text truncate color="gray" size={'1'}>
                    {DateTime.fromISO(conv.updatedAt).toRelative()}
                  </Text>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </>
      )}
    </Flex>
  )
}

type MessageCardProps = PropsWithChildren<{
  messageId: string
  className?: string
}>
const MessageCard = ({ messageId, ...props }: MessageCardProps) => {
  const { botId, workspaceId } = Route.useParams()
  const { message } = useSuspenseQuery(messageQueryOptions({ messageId, botId, workspaceId })).data
  return (
    <Card {...props}>
      <Tabs.Root defaultValue="details">
        <Tabs.List>
          <Tabs.Trigger value="details">Details</Tabs.Trigger>
          <Tabs.Trigger value="json">JSON</Tabs.Trigger>
        </Tabs.List>
        <Box pt="4">
          <Tabs.Content value="details">
            <DataListRoot size={'2'}>
              <DataListItem align={'center'} label="Message ID">
                <Identifier prefix="msg" id={message.id} />
              </DataListItem>
              <DataListItem label="Direction">
                <Badge variant="surface" color="gray">
                  {message.direction}
                </Badge>
              </DataListItem>
              <DataListItem label="Created At">{DateTime.fromISO(message.createdAt).toHTTP()}</DataListItem>
              <DataListItem align={'start'} label="Tags">
                <Flex className="w-full" wrap={'wrap'} gap={'2'}>
                  {Object.entries(message.tags)
                    .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                    .map(([key, value]) => (
                      <IdentifierDropdownMenu key={key} path={key} value={value}>
                        <Tag className="max-w-full hover:brightness-[.98]" label={key} value={value} />
                      </IdentifierDropdownMenu>
                    ))}
                </Flex>
              </DataListItem>
            </DataListRoot>
          </Tabs.Content>
          <Tabs.Content value="json">
            <JSONTree data={message} hideRoot />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </Card>
  )
}

type MessageListProps = {
  botId: string
  conversationId: string
  workspaceId: string
  messageId?: string
  className?: string
}
const MessageList = ({ botId, conversationId, workspaceId, messageId, className }: MessageListProps) => {
  const { theme, style } = useMemo(() => buildEggplantTheme(blue.blue3, { twin: { preflight: false } }), [])

  const {
    data: fetchedMessages,
    fetchNextPage,
    hasNextPage,
    isFetching,
  } = useWebchatMessages({
    botId,
    conversationId: conversationId,
    workspaceId,
  })

  const [initialized, setInitialized] = useState(!messageId)

  const [scrollHeight, setScrollHeight] = useState(0)
  const messages = fetchedMessages?.pages?.flatMap((page) => page.messages).reverse() ?? []

  const scrollToMessageRef = useRef<HTMLDivElement>(null)
  const scrollableMessagesRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (messageId && messages.findIndex((msg) => msg.id === messageId) === -1) {
      fetchNextPage()
    }
  }, [messageId, messages.length])

  useEffect(() => {
    const scrollableElement = scrollableMessagesRef.current

    if (scrollableElement && scrollHeight !== scrollableElement.scrollHeight) {
      const diff = scrollableElement.scrollHeight - scrollHeight

      if (diff) {
        scrollableElement.scrollTo({
          top: diff,
        })
      }
      setScrollHeight(scrollableElement.scrollHeight)
    }
  }, [scrollableMessagesRef, messages.length, scrollHeight])

  useEffect(() => {
    if ((messageId && messages.findIndex((msg) => msg.id === messageId) !== -1) || !hasNextPage) {
      setInitialized(true)
    }
  }, [isFetching, messages.length, messageId])

  if (!initialized) {
    return (
      <Card className={cn('flex items-center justify-center border-dashed', className)}>
        <EmptyState />
        <Flex align={'center'} gap={'2'} className="opacity-60">
          <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeftRight} />
          <Text color="gray">Loading messages...</Text>
        </Flex>
      </Card>
    )
  }

  if (!isFetching && !messages.length) {
    return (
      <Card className={cn('flex items-center justify-center border-dashed', className)}>
        <EmptyState />
        <Flex align={'center'} gap={'2'} className="opacity-60">
          <Icon color="gray" size="4" icon={HiOutlineChatBubbleLeftRight} />
          <Text color="gray">No messages in conversation</Text>
        </Flex>
      </Card>
    )
  }

  return (
    <Card ref={scrollableMessagesRef} className={cn('overflow-auto', className)}>
      <Flex direction={'column'} gap={'2'}>
        <WebchatProvider isReadOnly renderers={{ image: ImageRenderer }} theme={theme}>
          <Button
            loading={isFetching}
            variant="ghost"
            className="mx-auto my-2 w-fit"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage}
          >
            {hasNextPage ? 'Load more' : 'End of conversation'}
          </Button>
          {messages?.map((message, i) => (
            <Message
              ref={match({ messageId, i })
                .with({ messageId: message.id }, () => scrollToMessageRef)
                .with({ i: messages.length - 1, messageId: undefined }, () => scrollToMessageRef)
                .otherwise(() => undefined)}
              key={message.id}
              {...message}
              selected={messageId === message.id}
            />
          ))}
        </WebchatProvider>
      </Flex>
      <style>{style}</style>
    </Card>
  )
}
