import { Container } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { Logs } from '~/components'
import { useLogsStore } from '~/stores'
import { botQueryOptions } from '~/queries'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/logs')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data

  // Set the default value to yesterday
  const yesterday = new Date()
  yesterday.setDate(yesterday.getDate() - 1)

  const startDate = useLogsStore((state) => state.startDate[bot.id]) ?? yesterday
  const endDate = useLogsStore((state) => state.endDate[bot.id]) ?? new Date()

  const setStartDate = useLogsStore((state) => state.setStartDate)
  const setEndDate = useLogsStore((state) => state.setEndDate)

  return (
    // TODO: Fix this heigh hack once we remove the old logs from the cdm and we can change the logs component layout
    <Container px={'5'} className="*:relative *:mt-4 *:h-[calc(100vh-7.5rem)] *:max-w-[1800px]">
      <Logs
        id={bot.id}
        type="bot"
        workspaceId={workspaceId}
        startDate={startDate}
        setStartDate={(d: Date) => setStartDate(bot.id, d)}
        endDate={endDate}
        setEndDate={(d: Date) => setEndDate(bot.id, d)}
      />
    </Container>
  )
}
