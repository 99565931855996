import { ReactNode } from 'react'
import { create } from 'zustand'

export type DialogStore = {
  title: string
  content: ReactNode
  show: boolean
  cancelLabel: string
  confirmLabel: string
  variant: 'primary' | 'danger'
  disabled: boolean
  onConfirm: () => void
  onCancel: () => void
  setShow: (show: boolean) => void
  update: (options: Partial<DialogStore>) => void
}

export const useDialogStore = create<DialogStore>((set) => ({
  title: '',
  content: null,
  show: false,
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  variant: 'primary',
  disabled: false,
  onConfirm: () => {},
  onCancel: () => {},
  setShow: (show: boolean) => set({ show }),
  update: (options: Partial<DialogStore>) => set(options),
}))
