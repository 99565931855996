// this file was automatically generated, do not edit
/* eslint-disable */

export interface DeleteTaskRequestHeaders {}

export interface DeleteTaskRequestQuery {}

export interface DeleteTaskRequestParams {
  id: string;
}

export interface DeleteTaskRequestBody {}

export type DeleteTaskInput = DeleteTaskRequestBody & DeleteTaskRequestHeaders & DeleteTaskRequestQuery & DeleteTaskRequestParams

export type DeleteTaskRequest = {
  headers: DeleteTaskRequestHeaders;
  query: DeleteTaskRequestQuery;
  params: DeleteTaskRequestParams;
  body: DeleteTaskRequestBody;
}

export const parseReq = (input: DeleteTaskInput): DeleteTaskRequest & { path: string } => {
  return {
    path: `/v1/chat/tasks/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface DeleteTaskResponse {}

