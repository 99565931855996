import { ButtonBlock, RowBlock } from '@botpress/webchat'
import { z } from 'zod'
import { MessageObject } from '../block.types'
import { BaseMessage } from './BaseMessage'
import { ToMessage } from './Utils'
import { nanoid } from 'nanoid'

//TODO: Fix choice title that will not show up
type ChoiceBlockType = { blocks: ButtonBlock[] } & Omit<RowBlock, 'blocks'>
export const ChoiceSchema = BaseMessage.extend({
  type: z.literal('choice'),
  payload: z.object({
    text: z.string().min(1),
    options: z.array(
      z.object({
        label: z.string().min(1),
        value: z.string().min(1),
      })
    ),
  }),
})
  .transform(({ payload: { text: _text, options }, ...props }) => {
    return {
      ...props,
      block: {
        type: 'row' as const,
        blocks: options.map(({ label, value }) => ({
          type: 'button' as const,
          variant: 'action' as const,
          text: label,
          buttonValue: value,
          groupId: nanoid(),
        })),
      },
    }
  })
  .transform<MessageObject<ChoiceBlockType>>(ToMessage)
