import { Flex, TextField, Text } from '@radix-ui/themes'
import type { ComponentProps, ReactNode } from 'react'

type TextInputProps = {
  label?: ReactNode
} & Pick<
  ComponentProps<typeof TextField.Root>,
  'size' | 'variant' | 'color' | 'radius' | 'value' | 'onChange' | 'className' | 'children' | 'placeholder'
>

export const TextInput = ({ label, className, ...props }: TextInputProps) => {
  return (
    <Flex direction={'column'} gap={'1'} className={className}>
      {label && (
        <Text size={'2'} color="gray">
          {label}
        </Text>
      )}
      <TextField.Root {...props} />
    </Flex>
  )
}

export const TextInputSlot = TextField.Slot
