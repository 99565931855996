import type { CommonBlockProps, ImageBlock } from '@botpress/webchat'
import { forwardRef } from 'react'

type Props = CommonBlockProps & ImageBlock
export const ImageRenderer = forwardRef<HTMLImageElement, Props>(
  ({ url, type, styles, orientation = 'auto', ...props }, ref) => {
    return (
      <img
        data-orientation={orientation}
        {...props}
        {...styles?.[type]?.image}
        src={url}
        alt=""
        ref={ref}
        loading="lazy"
      />
    )
  }
)
