import { createFileRoute } from '@tanstack/react-router'
import { Button, TextArea, TextInput } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchatConfig, useWebchatV2 } from '~/hooks'
import { queryClient } from '~/providers/ReactQuery'
import { getQueryKey } from '~/services'
import { Flex, Text } from '@radix-ui/themes'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/styles')({
  component: Component,
})

function Component() {
  const { workspaceId, botId } = Route.useParams()
  const { config, isPending, setConfig, updateWebchatIntegration } = useWebchatConfig({ botId, workspaceId })
  const { isWebchatV2Enabled } = useWebchatV2()

  return (
    <ConfigSection title="Styles" description="Custom styles for your bot.">
      <Flex gap="4" direction={'column'}>
        {isWebchatV2Enabled ? (
          <TextArea
            rows={20}
            label="CSS Styles"
            value={config.additionalStyles}
            onChange={(e) => setConfig((prev) => ({ ...prev, additionalStyles: e.target.value }))}
          />
        ) : (
          <TextInput
            label="Stylesheet URL"
            value={config.stylesheet}
            onChange={(e) => setConfig((prev) => ({ ...prev, stylesheet: e.target.value }))}
          />
        )}
        <Button
          loading={isPending}
          onClick={() => {
            updateWebchatIntegration(config)
            void queryClient.invalidateQueries({
              queryKey: getQueryKey('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
                workspaceId,
                botId,
              }),
            })
          }}
          className="self-end"
        >
          <Text>Save</Text>
        </Button>
      </Flex>
    </ConfigSection>
  )
}
