import { Outlet, createFileRoute } from '@tanstack/react-router'
import { SidebarLayout } from '~/layouts'
import { Navbar, NavbarItem } from '~/componentsV2'
import { useCurrentRouteId } from '../../../hooks'
import { HiOutlineBuildingOffice2, HiOutlineCreditCard } from 'react-icons/hi2'
import { Container } from '@radix-ui/themes'

export const Route = createFileRoute('/workspaces/$workspaceId/settings')({
  component: () => (
    <Container size={'4'} p={'5'}>
      <SidebarLayout main={<Outlet />} leftSidebar={<Sidebar />} />
    </Container>
  ),
})

const Sidebar = () => {
  const { workspaceId } = Route.useParams()

  const currentRouteId = useCurrentRouteId() ?? ''

  const items = [
    { text: 'Details', leadingIcon: HiOutlineBuildingOffice2, to: '/workspaces/$workspaceId/settings/profile' },
    { text: 'Billing', leadingIcon: HiOutlineCreditCard, to: '/workspaces/$workspaceId/settings/billing' },
  ].map((i) => ({ ...i, active: currentRouteId.includes(i.to) }) as NavbarItem)

  return <Navbar workspaceId={workspaceId} direction="column" items={items} />
}
