import { ComponentProps, ReactNode } from 'react'
import toast, { Toast as ToastType } from 'react-hot-toast'
import { ThemeColor } from '../providers/ThemeProvider'
import { Toast } from '~/componentsV2'
import type { Callout } from '~/elementsv2'

type showToastProps = {
  color?: ComponentProps<typeof Callout>['color']
  showIcon?: boolean
}

export function showSuccessToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: 'grass', showIcon })
}

export function showInfoToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: ThemeColor, showIcon })
}

export function showWarningToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: 'amber', showIcon })
}

export function showErrorToast(content: ReactNode, { showIcon = true }: Omit<showToastProps, 'variant'> = {}) {
  return showToast(content, { color: 'red', showIcon })
}

export function showToast(
  content: ReactNode | ((currentToast: ToastType, toastClient: typeof toast) => JSX.Element),
  { color = 'gray' }: showToastProps = {}
) {
  return toast.custom((t) => (
    <Toast color={color} isVisible={t.visible} onDismiss={() => toast.dismiss(t.id)}>
      {typeof content === 'function' ? content(t, toast) : content}
    </Toast>
  ))
}
