import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useState } from 'react'
import { OAuthError, OAuthNeutral, OAuthSuccess } from '../../features/oauth/components'
import { z } from 'zod'
import { Button, Card } from '~/elementsv2'
import { match } from 'ts-pattern'

const searchParamsSchema = z.object({
  success: z
    .boolean()
    .optional()
    .catch(() => undefined),
  errorMessage: z
    .string()
    .optional()
    .catch(() => 'undefined'),
})

export const Route = createFileRoute('/oauth/interstitial')({
  component: OAuthInterstitialPage,
  validateSearch: searchParamsSchema,
})

export function OAuthInterstitialPage() {
  const { errorMessage, success } = Route.useSearch()

  const [seconds, setSeconds] = useState(5)
  // Buffer in case window doesn't close such as in case of "Scripts may close only the windows that were opened by them."
  const [timeWithBuffersUp, setTimeWithBuffersUp] = useState(false)

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1)
      }, 1000)

      return () => clearTimeout(timerId)
    }
    window.close()
  }, [seconds])

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeWithBuffersUp(true)
    }, 8000)

    return () => clearTimeout(timer)
  }, [])

  return (
    <div className="h-[100vh] p-4">
      <Card className="flex h-full w-full flex-col items-center p-5">
        {match(success)
          .with(true, () => <OAuthSuccess />)
          .with(false, () => <OAuthError errorMessage={errorMessage} />)
          .with(undefined, () => <OAuthNeutral />)
          .exhaustive()}
        {timeWithBuffersUp ? (
          <Button onClick={() => window.close()} className="mt-auto">
            Close Window and Continue
          </Button>
        ) : (
          <>
            <div className="text-md mb-3 mt-auto text-center text-gray-10">
              This window will automatically close in <span className="font-bold">{seconds}s</span>
            </div>
            <div className="h-2 w-full rounded-full bg-gray-2">
              <div className="h-full w-[0%] animate-grow-right rounded-full bg-accent-9" />
            </div>
          </>
        )}
      </Card>
    </div>
  )
}
