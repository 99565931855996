import { useRef, useEffect, useState, type ReactElement, type JSXElementConstructor } from 'react'
import { createRoot } from 'react-dom/client'

type Props = {
  children: ReactElement<any, string | JSXElementConstructor<any>>[]
  style?: React.CSSProperties
}

export const ShadowRoot = ({ children, ...props }: Props) => {
  const hostRef = useRef<HTMLDivElement | null>(null)
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null)

  useEffect(() => {
    if (hostRef.current && !hostRef.current.shadowRoot) {
      const shadow = hostRef.current.attachShadow({ mode: 'open' })
      setShadowRoot(shadow)
    } else if (hostRef.current?.shadowRoot) {
      setShadowRoot(hostRef.current.shadowRoot)
    }
  }, [])

  useEffect(() => {
    if (shadowRoot) {
      const root = createRoot(shadowRoot)
      root.render(children)
    }
  }, [children, shadowRoot])

  return <div ref={hostRef} {...props} />
}
