import { infiniteQueryOptions, queryOptions } from '@tanstack/react-query'
import { useQueryKeys } from '../hooks'
import { getApiClientForBot } from '../client'
import { cacheDuration } from '../shared'

type MessageQueryProps = {
  messageId: string
  workspaceId: string
  botId: string
}
export const messageQueryOptions = ({ messageId, ...props }: MessageQueryProps) => {
  const { getMessage } = useQueryKeys()
  const client = getApiClientForBot(props)
  return queryOptions({
    staleTime: cacheDuration.long,
    queryKey: getMessage(messageId),
    queryFn: async () => client.getMessage({ id: messageId }),
    meta: {
      suppressError: true,
    },
  })
}

export const listMessagesInfiniteQueryOptions = ({
  workspaceId,
  botId,
  conversationId,
}: {
  workspaceId: string
  botId: string
  conversationId: string
}) => {
  const { listMessages } = useQueryKeys()
  return infiniteQueryOptions({
    staleTime: cacheDuration.medium,
    queryKey: listMessages(workspaceId, botId, conversationId),
    queryFn: async ({ pageParam }) =>
      getApiClientForBot({ workspaceId, botId })
        .listMessages({ conversationId, nextToken: pageParam })
        .catch((e) => {
          console.error('Failed to fetch events', e)
          return { messages: [], meta: { nextToken: null } }
        }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
  })
}
