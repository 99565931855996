// this file was automatically generated, do not edit
/* eslint-disable */

export interface CreateTableRowsRequestHeaders {}

export interface CreateTableRowsRequestQuery {}

export interface CreateTableRowsRequestParams {
  table: string;
}

export interface CreateTableRowsRequestBody {
  /**
   * @minItems 1
   * @maxItems 1000
   */
  rows: {
    [k: string]: any;
  }[];
  /**
   * Ensure computed columns are fully processed before returning the result. This is applicable only when the number of rows involved is fewer than 1.
   */
  waitComputed?: boolean;
}

export type CreateTableRowsInput = CreateTableRowsRequestBody & CreateTableRowsRequestHeaders & CreateTableRowsRequestQuery & CreateTableRowsRequestParams

export type CreateTableRowsRequest = {
  headers: CreateTableRowsRequestHeaders;
  query: CreateTableRowsRequestQuery;
  params: CreateTableRowsRequestParams;
  body: CreateTableRowsRequestBody;
}

export const parseReq = (input: CreateTableRowsInput): CreateTableRowsRequest & { path: string } => {
  return {
    path: `/v1/tables/${encodeURIComponent(input['table'])}/rows`,
    headers: {  },
    query: {  },
    params: { 'table': input['table'] },
    body: { 'rows': input['rows'], 'waitComputed': input['waitComputed'] },
  }
}

export interface CreateTableRowsResponse {
  rows: {
    /**
     * Unique identifier for the row.
     */
    id: number;
    /**
     * Timestamp of row creation.
     */
    createdAt?: string;
    /**
     * Timestamp of the last row update.
     */
    updatedAt?: string;
    computed: {
      [k: string]: {
        status: string;
        error?: string;
        updatedBy?: string;
        updatedAt?: string;
      };
    };
    /**
     * [Read-only] List of stale values that are waiting to be recomputed.
     */
    stale?: string[];
    /**
     * Optional numeric value indicating similarity, when using findTableRows.
     */
    similarity?: number;
    [k: string]: any;
  }[];
  /**
   * Alerts for minor issues that don't block the operation but suggest possible improvements.
   */
  warnings?: string[];
  /**
   * Critical issues in specific elements that prevent their successful processing, allowing partial operation success.
   */
  errors?: string[];
}

