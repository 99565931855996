import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { Flex, TextField, Text } from '@radix-ui/themes'
import { Integration } from 'botpress-client'
import { useState } from 'react'
import { DialogFooter } from '~/componentsV2'
import { Button } from '~/elementsv2'
import { useUpdateBot } from '~/hooks'
import { showSuccessToast } from '~/services'
import { copyToClipboard } from '~/utils'

type RemoveIntegrationProps = {
  workspaceId: string
  botId: string
  integration: Integration
}

export const RemoveIntegrationDialog = ({
  integration: { id: integrationId, name: integrationName },
  workspaceId,
  botId,
}: RemoveIntegrationProps) => {
  const { mutate: updateBot } = useUpdateBot()

  const [integrationNameInput, setIntegrationNameInput] = useState('')
  const [removing, setRemoving] = useState(false)

  const removeIntegration = (integrationIdToRemove: string) => {
    setRemoving(true)

    updateBot({
      id: botId,
      workspaceId,
      integrations: {
        [integrationIdToRemove]: null,
      },
      onSuccess: () => {
        showSuccessToast('The integration has been removed.')
      },
      onSettled: () => {
        setRemoving(false)
      },
    })
  }

  return (
    <Flex direction="column" gap={'4'}>
      <Text color="gray" size={'2'} wrap={'pretty'}>
        Removing an integration is permanent and cannot be undone. All data will be lost.
      </Text>
      <Flex gap={'3'}>
        <Text color="gray" size={'2'}>
          To confirm, type the integration name below:
        </Text>
        <Button
          variant="ghost"
          color="gray"
          size={'2'}
          className="bg-accent-3 hover:bg-accent-4"
          onClick={() => void copyToClipboard(integrationName, 'Integration name')}
        >
          {integrationName}
          <ClipboardDocumentIcon className="h-4 " />
        </Button>
      </Flex>
      <TextField.Root
        value={integrationNameInput}
        onChange={(e) => setIntegrationNameInput(e.target.value)}
        placeholder="Integration name"
      />
      {/* TODO: Show loading animation (and tooltip) when removing=true */}
      <DialogFooter
        disabled={removing || integrationNameInput !== integrationName}
        variant="danger"
        onConfirm={() => removeIntegration(integrationId)}
        confirmLabel="Remove"
      />
    </Flex>
  )
}
