import { queryOptions } from '@tanstack/react-query'
import { getApiClientForBot } from '../client'
import { cacheDuration } from '../shared'
import { ClientParams } from 'botpress-client'
import { useQueryKeys } from '../hooks'

type StateNames =
  | 'context'
  | 'session'
  | 'tasks'
  | 'userVariables'
  | 'botVariables'
  | 'agentsWorkflowsVariables'
  | 'agentsUserVariables'
  | 'agentsConversationVariables'
  | 'agentsBotVariables'

export type GetStateProps = { workspaceId: string; botId: string; name: StateNames } & Pick<
  ClientParams['getState'],
  'id' | 'type'
>
export const getStateQueryOptions = ({ botId, workspaceId, ...props }: GetStateProps) => {
  const client = getApiClientForBot({ workspaceId, botId })
  return queryOptions({
    staleTime: cacheDuration.short,
    queryKey: useQueryKeys().getState({ botId, workspaceId, ...props }),
    queryFn: async () => {
      try {
        return await client.getState(props)
      } catch (e) {
        return { state: undefined }
      }
    },
    meta: {
      suppressError: true,
    },
  })
}
