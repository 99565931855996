import { useMemo } from 'react'
import { useBilling } from '~/features/usage/useBilling'
import { useMutation } from '@tanstack/react-query'
import { getQueryKey, showErrorToast } from '~/services'
import { isEqual } from 'lodash'
import { useUpdateSubscription } from './useUpdateSubscription'
import { queryClient } from '~/providers/ReactQuery'
import { computeTotalCost } from '~/features/billing/utils'

type useChdeckoutBillingProps = Pick<
  ReturnType<typeof useBilling>,
  | 'activePlanId'
  | 'hasActiveSubscription'
  | 'initialActivePlanId'
  | 'productQuantityMap'
  | 'initialProductQuantityMap'
  | 'subscription'
  | 'generateCheckoutSession'
>
export function useCheckout(workspaceId: string, billingProps: useChdeckoutBillingProps) {
  const {
    activePlanId,
    hasActiveSubscription,
    initialActivePlanId,
    productQuantityMap,
    initialProductQuantityMap,
    subscription,
    generateCheckoutSession,
  } = billingProps

  const { mutate: updateSubscription } = useUpdateSubscription(workspaceId)

  const totalPrice = useMemo(() => computeTotalCost(productQuantityMap), [productQuantityMap, activePlanId])
  const initialTotalPrice = useMemo(
    () => computeTotalCost(productQuantityMap),
    [initialProductQuantityMap, initialActivePlanId]
  )

  const subscriptionConfigHasChanged = useMemo(() => {
    return initialActivePlanId !== activePlanId || !isEqual(productQuantityMap, initialProductQuantityMap)
  }, [productQuantityMap, initialProductQuantityMap, activePlanId, initialActivePlanId])

  return useMutation({
    gcTime: 0,
    mutationFn: async () => {
      if (!activePlanId || (hasActiveSubscription && !subscriptionConfigHasChanged)) {
        return null
      }

      if (!subscription) {
        const { url } = await generateCheckoutSession()
        await queryClient.invalidateQueries({ queryKey: getQueryKey('workspaces_/$workspaceId_', { workspaceId }) })
        window.location.href = url
        return
      }

      const payload = Object.entries(productQuantityMap)
        .filter(([_, product]) => product?.subscriptionLineItemId || product?.quantity)
        .map(([_, product]) => ({
          price: product?.priceId,
          quantity: product?.quantity ?? 0,
        }))

      updateSubscription({
        productQuantityConfig: payload,
        isUpgrading: initialTotalPrice <= totalPrice,
        subscriptionId: subscription.id,
      })
    },

    onError: () => {
      showErrorToast('There was an error while updating your subscription. Please try again.')
    },
    meta: {
      suppressError: true,
    },
  })
}
