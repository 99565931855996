import { useLogs } from '../../hooks/useLogs'
import { LogsPanel } from './LogsPanel'

type Props = {
  id: string
  type: 'bot' | 'integration'
  workspaceId: string
  startDate: Date
  setStartDate: (date: Date) => void
  endDate: Date
  setEndDate: (date: Date) => void
  className?: string
}

export function Logs({
  id,
  type,
  workspaceId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  className,
}: Props): JSX.Element {
  const { data, refetch, isFetching, hasPreviousPage, fetchPreviousPage, isFetchingPreviousPage } = useLogs({
    type,
    id,
    workspaceId,
    startDate,
    endDate,
  })

  const logs = data?.pages.map((p) => p.logs).flat()

  return (
    <div className={className}>
      <LogsPanel
        isFetching={isFetching}
        isFetchingPreviousPage={isFetchingPreviousPage}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onRefresh={refetch}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onLoadPrevious={fetchPreviousPage}
        logs={logs ?? []}
        startDate={startDate}
        onStartDateChanged={setStartDate}
        endDate={endDate}
        onEndDateChanged={setEndDate}
        hasPreviousPage={hasPreviousPage ?? false}
      />
    </div>
  )
}
