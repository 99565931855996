import { DialogStore, useDialogStore } from '../stores'
import { DialogFooter, defaultDialogFooterProps } from '../componentsV2'
import { ComponentProps } from 'react'
import { QuotaType } from '@bpinternal/const'
import { Dialog, Text } from '@radix-ui/themes'
import { QUOTA_TYPE_USER_FACING_PROPERTIES_MAP } from '../features/usage/constants'
import { Link } from '../elementsv2'

type CustomDialogOptions = Partial<Pick<DialogStore, 'content' | 'title'>>

type ConfirmationDialogOptions = CustomDialogOptions & ComponentProps<typeof DialogFooter>

const defaultCustomDialogOptions: CustomDialogOptions = {
  content: null,
  title: '',
}

const defaultConfirmationDialogOptions: ConfirmationDialogOptions = {
  ...defaultCustomDialogOptions,
  ...defaultDialogFooterProps,
}

export function showCustomDialog(options?: CustomDialogOptions) {
  useDialogStore.setState({
    show: true,
    ...defaultCustomDialogOptions,
    ...(options ?? {}),
  })
}

export function showConfirmationDialog(options?: ConfirmationDialogOptions) {
  const { content, title, ...dialogOptions } = { ...defaultConfirmationDialogOptions, ...(options ?? {}) }

  useDialogStore.setState({
    show: true,
    ...dialogOptions,
    title,
    content: (
      <>
        {content}
        <DialogFooter {...dialogOptions} />
      </>
    ),
  })
}

//TODO: This could use some refinement, but it's a good start
export function showUpsellDialog({
  quota,
  workspaceId,
  onConfirm,
}: {
  quota: QuotaType
  workspaceId: string
  onConfirm: () => void
}) {
  showConfirmationDialog({
    title: 'Upgrade your plan',
    cancelLabel: 'Not now',
    confirmLabel: 'Upgrade',
    onConfirm,
    content: (
      <Text>
        You have reached your {QUOTA_TYPE_USER_FACING_PROPERTIES_MAP[quota].name} quota. Please{' '}
        <Dialog.Close>
          <Link to="/workspaces/$workspaceId/settings/billing/plans" params={{ workspaceId }}>
            upgrade your plan
          </Link>
        </Dialog.Close>{' '}
        or add an{' '}
        <Dialog.Close>
          <Link to="/workspaces/$workspaceId/settings/billing" params={{ workspaceId }}>
            add-on
          </Link>
        </Dialog.Close>{' '}
        to continue using this feature.
      </Text>
    ),
  })
}
