import { CheckIcon as SmallCheckIcon } from '@heroicons/react/20/solid'
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/24/outline'
import cx from 'classnames'
import React, { ComponentProps, PropsWithChildren, useEffect } from 'react'
import { Button, Separator, Spinner } from '../../../elements'
import { useInstallIntegration, useWorkspaces } from '../../../hooks'
import { WorkspaceSummary } from '../../workspaces/types'
import { BotSelector } from './BotSelector'
import { SuccessCheckmark } from './SuccessCheckmark'
import { WorkspaceSelectButton } from './WorkspaceSelectButton'
import { trackEvent } from '../../../providers'
import { Popover, Flex } from '@radix-ui/themes'
import { PublicIntegration } from '../types'
import { BotSummary } from '~/features/bots/types'
import { InstallPublicIntegrationDisclamer } from './InstallPublicIntegrationDisclamer'
import { openConfigureBotIntegrationStudioTab } from '../services/utils'

type StepProps = {
  label: string
  active: boolean
  completed: boolean
  step: number
}

const Step = ({ step, label, active, completed }: StepProps) => (
  <>
    {step > 1 && (
      <Separator
        className={cx('mx-4 w-8 transition-colors', {
          'bg-accent-9': active,
          'bg-grass-8': completed,
        })}
      />
    )}
    <div
      className={cx('all mr-2 flex aspect-square h-5 items-center justify-center rounded-full border  transition', {
        'border-gray-6  bg-gray-4 text-gray-12': !active && !completed,
        'border-accent-9 bg-accent-4 text-accent-12': active && !completed,
        'border-grass-9 bg-grass-9 text-white': completed,
      })}
    >
      {completed ? <SmallCheckIcon className={cx('aspect-square h-3 transition-all')} /> : `${step}`}
    </div>
    <span
      className={cx('whitespace-nowrap', {
        'text-gray-11': !active && !completed,
        'text-accent-11': active && !completed,
        'text-grass-12': completed,
      })}
    >
      {label}
    </span>
  </>
)

type BotSelectorPopoverProps = {
  currentIntegration: PublicIntegration
  navigateToBotIntegration: (workspaceId: string, botId: string, integrationId: string) => void
  navigateToHome: () => void
  navigateToHub: () => void
} & PropsWithChildren<ComponentProps<'div'>>

export const HubInstallPopover = ({ children, currentIntegration, ...props }: BotSelectorPopoverProps) => {
  const [selectedWorkspace, setSelectedWorkspace] = React.useState<WorkspaceSummary | undefined>(undefined)
  const [selectedBot, setSelectedBot] = React.useState<BotSummary>()
  const [formState, setFormState] = React.useState<'workspace' | 'bot' | 'authorize' | 'done'>('workspace')
  const { installIntegration, isInstallingIntegration, isErrorInstallingIntegration } = useInstallIntegration(() => {
    setFormState('done')
    trackEvent({ type: 'integration_installed', integration: currentIntegration })
  })
  useEffect(() => {
    if (isErrorInstallingIntegration) {
      setFormState('bot')
    }
  }, [isErrorInstallingIntegration])

  const { data: workspaces } = useWorkspaces()

  return (
    <Popover.Root>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content>
        <Flex direction={'column'} p={'4'}>
          <div className="mb-6 flex items-center self-center text-xs">
            <Step
              step={1}
              active={formState === 'workspace'}
              completed={!!selectedWorkspace}
              label="Select Workspace"
            />
            <Step step={2} active={formState === 'bot'} completed={!!selectedBot} label="Select Bot" />
            {/* TODO this should be the OAuth button whenever available */}
            <Step step={3} active={formState === 'authorize'} completed={isInstallingIntegration} label="Authorize" />
          </div>
          {formState === 'workspace' && (
            <>
              <p className="mb-4 px-2 text-sm text-gray-11">
                In which workspace do you want to install the integration?
              </p>
              <div className="flex flex-col gap-1">
                {workspaces?.map((workspace) => (
                  <WorkspaceSelectButton
                    key={workspace.id}
                    workspace={workspace}
                    onWorkspaceSelected={(newWorkspace) => {
                      setSelectedWorkspace(newWorkspace)
                      setFormState('bot')
                    }}
                  />
                ))}
              </div>
            </>
          )}
          {formState === 'bot' && selectedWorkspace && (
            <>
              <Button
                variant="tertiary"
                size="small"
                className="mb-4 pl-0"
                leading={<ArrowLeftIcon className=" h-4" />}
                onClick={() => {
                  setSelectedBot(undefined)
                  setSelectedWorkspace(undefined)
                  setFormState('workspace')
                }}
              >
                {selectedWorkspace.name}
              </Button>
              <BotSelector
                selectedBot={selectedBot}
                currentIntegration={currentIntegration}
                currentWorkspaceId={selectedWorkspace.id}
                setSelectedBot={(bot) => {
                  setSelectedBot(bot)
                  setFormState('authorize')
                }}
              />
            </>
          )}
          {formState === 'authorize' && selectedWorkspace && selectedBot && (
            <>
              <Button
                variant="tertiary"
                size="small"
                className="mb-4 pl-0"
                leading={<ArrowLeftIcon className=" h-4" />}
                onClick={() => {
                  setSelectedBot(undefined)
                  setFormState('bot')
                }}
              >
                {selectedBot.name}
              </Button>
              <InstallPublicIntegrationDisclamer integration={currentIntegration} bot={selectedBot} />
              <Button
                variant="primary"
                size="small"
                isLoading={isInstallingIntegration}
                disabled={!selectedBot}
                className="w-fit self-end"
                onClick={() => {
                  installIntegration(currentIntegration.id, selectedWorkspace.id, selectedBot.id)
                }}
              >
                Install integration
              </Button>
            </>
          )}
          {formState === 'done' &&
            (isInstallingIntegration ? (
              <Spinner className="self-center" />
            ) : (
              <>
                <div className="my-4 mb-6 h-12 w-12 self-center">
                  <SuccessCheckmark />
                </div>
                <h3 className="mb-2 self-center font-semibold text-gray-12">Integration successfully installed!</h3>
                <p className="mb-12 self-center text-sm text-gray-11">
                  You can now go back to the dashboard and configure it.
                </p>
                <div className="flex justify-end gap-2">
                  <Button variant="tertiary" size="small" onClick={() => props.navigateToHub()}>
                    Keep browsing
                  </Button>

                  <Button
                    onClick={() => {
                      openConfigureBotIntegrationStudioTab(selectedBot?.id ?? '', currentIntegration.id)
                      props.navigateToHub()
                    }}
                    trailing={<ArrowRightIcon className="h-4" />}
                    variant="primary"
                    size="small"
                  >
                    Configure in studio
                  </Button>
                </div>
              </>
            ))}
        </Flex>
      </Popover.Content>
    </Popover.Root>
  )
}
