import { Badge, Flex, Grid, Link, Separator, Text, TextField } from '@radix-ui/themes'
import { createFileRoute, Link as RouterLink } from '@tanstack/react-router'
import { Fragment, useMemo, useState } from 'react'
import { Button, Card, IconButton, Spinner, PageSection } from '~/elementsv2'
import { ColorAccentProvider } from '~/componentsV2'
import { HiOutlineCheckCircle, HiOutlineMinusSmall, HiOutlinePlusSmall, HiOutlineXMark } from 'react-icons/hi2'
import { ThemeColor } from '~/providers/ThemeProvider'
import { COMMUNITY_PLAN_PRODUCT_ID, TEAM_PLAN_PRODUCT_ID, useBilling } from '~/features/usage/useBilling'
import { cn } from '~/utils'
import { ProductIcon } from '~/features/billing/componentsV2'
import { useChangeAiSpendQuota, useCheckout, useCustomerPortal, useQueryKeys, useUsage } from '~/hooks'
import { nanoToBase } from '~/features/usage/helpers'
import { getQueryOptions, showConfirmationDialog } from '~/services'
import { billingPlan } from '~/features/billing/constants'
import { isEqual } from 'lodash'
import { Color } from '~/types'
import { computeTotalCost } from '~/features/billing/utils'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { upcomingInvoiceQueryOptions } from '~/queries/billing'
import { trackEvent } from '~/providers'
import { ClientReturn } from 'botpress-client'
import { AnalyticEvent } from '~/providers/SegmentProvider'

export const Route = createFileRoute('/workspaces/$workspaceId/settings/billing/')({
  component: Component,
})

type BillingProps = ReturnType<typeof useBilling>

function Component() {
  const { workspaceId } = Route.useParams()
  const billingProps = useBilling(workspaceId)
  //Avoid using cache since we can't invalidate it when customer changes plan in stripe customer portal
  const { plan } = useSuspenseQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  //TODO: This spinner has been done the lazy way, it should be replaced with a skeleton loader
  return billingProps.isLoading ? (
    <Flex width={'100%'} align={'center'} justify={'center'} height={'9'}>
      <Spinner />
    </Flex>
  ) : (
    <Flex direction={'column'} gap={'8'}>
      <BillingSummarySection plan={plan} {...billingProps} />
      <CurrentPlanSection plan={plan} />
      <AddOnsSection plan={plan} {...billingProps} />
    </Flex>
  )
}

// Backend currently throws an error when trying to fetch the billing information and the customer does not have a subscription, so we need to handle this case in the frontend
const BillingSummarySection = ({
  hasActiveSubscription,
  plan,
  ...rest
}: BillingProps & { plan: ClientReturn<'getWorkspace'>['plan'] }) => {
  const { workspaceId } = Route.useParams()
  const { ai_spend } = useUsage({
    workspaceId,
    quotas: ['ai_spend'],
    period: new Date().toISOString().split('T')[0] ?? '',
  })
  const { data } = useQuery(upcomingInvoiceQueryOptions(workspaceId))
  const { mutate: updateAiSpendQuota, isPending: isUpdatingAiSpendQuota } = useChangeAiSpendQuota()
  const { mutate: openCustomerPortal } = useCustomerPortal(workspaceId)
  const { mutate: checkout, isPending: isCheckingOut } = useCheckout(workspaceId, { hasActiveSubscription, ...rest })

  const [newAiSpendQuota, setNewAiSpendQuota] = useState<number | null>(null)

  function handleUpdateAiSpendQuota() {
    const baseEvent: AnalyticEvent = {
      type: 'update_ai_spend',
      workspaceId,
      newAiSpend: newAiSpendQuota ?? 0,
      prevAiSpend: nanoToBase(ai_spend.quota),
      plan,
      state: 'clicked',
    } as const
    trackEvent({ ...baseEvent, state: 'clicked' })
    if (hasActiveSubscription) {
      return showConfirmationDialog({
        title: 'Update AI Spend Limit',
        confirmLabel: 'Update',
        content: (
          <Text>
            Are you sure you want to update your AI Spend Limit to{' '}
            <Text weight={'bold'}>${Number(newAiSpendQuota ?? 0)}</Text>?
          </Text>
        ),
        onConfirm: () => {
          trackEvent({ ...baseEvent, state: 'confirmed' })
          updateAiSpendQuota({
            workspaceId,
            monthlySpendingLimit: newAiSpendQuota ?? undefined,
            onSuccess: () => setNewAiSpendQuota(null),
          })
        },
      })
    }

    trackEvent({ ...baseEvent, state: 'credit_card_required' })
    return showConfirmationDialog({
      confirmLabel: 'Subscribe',
      title: 'Credit card required',
      content: 'Please add a credit card to increase your AI Spend limit.',
      onConfirm: () => {
        trackEvent({ ...baseEvent, state: 'clicked_add_card' })
        checkout()
      },
    })
  }

  return (
    <PageSection title="Billing Summary">
      <Card className="p-4 @container">
        <Grid className="grid-cols-1 @lg:grid-cols-[1fr,auto,1fr,auto,1fr]" gap={'6'}>
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>Your next payment</Text>
            <Text size={'5'} weight={'medium'}>
              ${((data?.total ?? 0) / 100).toFixed(2)}
            </Text>
            <Text size={'1'}>
              <Text color="gray">This amount includes the current spend on metered </Text>
              <Link
                href="https://help.openai.com/en/articles/4936856-what-are-tokens-and-how-to-count-them"
                target="_blank"
                rel="noreferrer"
                color={ThemeColor}
              >
                AI usage.
              </Link>
            </Text>
          </Flex>
          <Separator className="@lg:h-full @lg:w-px" size="4" />
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>AI Spend Limit</Text>
            <Text size={'5'} weight={'medium'}>
              ${nanoToBase(ai_spend.quota).toFixed(2)}
            </Text>
            <Flex gap={'1'}>
              <TextField.Root
                className="grow"
                disabled={isUpdatingAiSpendQuota || isCheckingOut || !hasActiveSubscription}
                value={newAiSpendQuota ?? ''}
                onChange={(e) => setNewAiSpendQuota(e.target.value ? Number(e.target.value) : null)}
                type="number"
                size={'1'}
                placeholder="10.00"
              >
                <TextField.Slot>
                  <Text size={'1'}>$</Text>
                </TextField.Slot>
              </TextField.Root>
              <Button
                size={'1'}
                disabled={!newAiSpendQuota && hasActiveSubscription}
                loading={isUpdatingAiSpendQuota || isCheckingOut}
                onClick={handleUpdateAiSpendQuota}
                className="grid place-content-center"
              >
                <Text>{hasActiveSubscription ? 'Update' : 'Subscribe'}</Text>
              </Button>
            </Flex>
            <Text size={'1'}>
              <Text color="gray">Maximum monthly limit for bot actions that use AI. </Text>
              <Link color={ThemeColor} target="_blank" href="http://botpress.com/pricing" rel="noreferrer">
                Learn more
              </Link>
            </Text>
          </Flex>
          <Separator className="@lg:h-full @lg:w-px" size="4" />
          <Flex direction={'column'} gap={'3'}>
            <Text size={'2'}>Payment information</Text>
            <Link onClick={() => openCustomerPortal()} className="hover:cursor-pointer hover:underline" size={'1'}>
              View invoices and payment information
            </Link>
          </Flex>
        </Grid>
      </Card>
    </PageSection>
  )
}

const CurrentPlanSection = ({ plan }: { plan: ClientReturn<'getWorkspace'>['plan'] }) => {
  const { workspaceId } = Route.useParams()
  const { queryClient } = Route.useRouteContext()

  return (
    <PageSection
      title={
        <Flex align={'center'} gap={'3'}>
          <Text>Current Plan</Text>
          <Link ml={'auto'} size={'1'} href="https://botpress.com/pricing" target="_blank" rel="noreferrer">
            Compare Plans
          </Link>
          <RouterLink to="/workspaces/$workspaceId/settings/billing/plans" params={{ workspaceId }}>
            <Button
              size={'1'}
              //TODO: This is a workaround to invalidate the cache when the user changes the plan in the stripe customer portal
              onClick={() => {
                void queryClient.invalidateQueries({ queryKey: useQueryKeys().getAllWorkspaceUsages(workspaceId) })
              }}
            >
              {plan === 'community' ? 'Upgrade' : 'Update'}
            </Button>
          </RouterLink>
        </Flex>
      }
    >
      <ColorAccentProvider color={plan === 'team' ? 'grass' : plan === 'enterprise' ? 'iris' : 'gray'}>
        <Card
          data-enterprise={plan === 'enterprise' ?? undefined}
          className="group flex flex-col gap-3 border-accent-4 p-3 @container "
        >
          <div className="-mx-3 -mt-3 bg-accent-3 p-3">
            <Text weight={'medium'} className="text-accent-11">
              {plan === 'team' ? 'Team' : plan === 'community' ? 'Pay-as-you-go' : 'Enterprise'}
            </Text>
          </div>
          <Grid className="grid-cols-1 gap-8 p-2 @lg:grid-cols-2 @lg:gap-4">
            <Flex direction={'column'} gap={'3'}>
              {billingPlan[plan].inclusions.map((feature) => (
                <Flex key={feature} align={'start'} gap={'2'}>
                  <HiOutlineCheckCircle className="h-5 flex-none text-grass-9" />
                  <Text size={'2'}>{feature}</Text>
                </Flex>
              ))}
            </Flex>
            {billingPlan[plan].exclusions.length > 0 ? (
              <Flex direction={'column'} gap={'3'}>
                <Text size={'2'} weight={'medium'} color="gray">
                  Not included:
                </Text>
                {billingPlan[plan].exclusions.map((feature) => (
                  <Flex key={feature} align={'start'} gap={'2'}>
                    <HiOutlineXMark className="h-5 flex-none text-red-9" />
                    <Text size={'2'}>{feature}</Text>
                  </Flex>
                ))}
                <Link
                  size={'2'}
                  underline="always"
                  color={ThemeColor}
                  href="https://botpress.com/pricing"
                  target="_blank"
                  rel="noreferrer"
                >
                  See all features and compare plans
                </Link>
              </Flex>
            ) : null}
          </Grid>
        </Card>
      </ColorAccentProvider>
    </PageSection>
  )
}

const AddOnsSection = ({
  initialProductQuantityMap,
  productQuantityMap,
  updateProductQuantity,
  plan,
  ...billingProps
}: BillingProps & { plan: ClientReturn<'getWorkspace'>['plan'] }) => {
  const { workspaceId } = Route.useParams()
  const { queryClient } = Route.useRouteContext()
  const initialTotalCost = useMemo(() => computeTotalCost(initialProductQuantityMap), [productQuantityMap])
  const totalCost = useMemo(() => computeTotalCost(productQuantityMap), [productQuantityMap])

  const { mutate: handleCheckout, isPending } = useCheckout(workspaceId, {
    productQuantityMap,
    initialProductQuantityMap,
    ...billingProps,
  })

  const products = [...Object.entries(productQuantityMap)]
    .sort(([productId]) => (productId === TEAM_PLAN_PRODUCT_ID ? 1 : -1))
    .filter(([productId, _product]) => productId !== COMMUNITY_PLAN_PRODUCT_ID)
    .map(([productId, { quantity, productDetails }]) => {
      const initialQuantity = initialProductQuantityMap[productId]?.quantity ?? 0
      const countColor: Color | undefined =
        quantity > initialQuantity ? 'grass' : quantity < initialQuantity ? 'red' : quantity === 0 ? 'gray' : undefined

      return {
        quota: productDetails.metadata.quotaName,
        isTeamPlan: productId === TEAM_PLAN_PRODUCT_ID,
        quantity,
        initialQuantity,
        productId,
        countColor,
        description: productDetails.description,
        name: productDetails.name.replace(/Add-on - /i, ''),
        price: (productDetails.price.unit_amount ?? 0) / 100,
      }
    })

  return (
    <PageSection title="Plan & Add-ons">
      <Grid align={'center'} gapX={'4'} gapY={'5'} className="grid-cols-[auto,1fr,auto]">
        {products.map(
          ({ initialQuantity, isTeamPlan, name, price, quantity, quota, productId, description, countColor }) => (
            <Fragment key={productId}>
              <Flex align={'center'} gap={'2'} className={cn({ invisible: isTeamPlan })}>
                <IconButton
                  variant="minimal"
                  size={'1'}
                  color="gray"
                  disabled={!quantity}
                  onClick={(e) => updateProductQuantity(productId, e.shiftKey ? -10 : -1)}
                  icon={HiOutlineMinusSmall}
                />
                <Text className={cn({ 'opacity-75': !quantity })} color={countColor}>
                  {quantity}
                </Text>
                <IconButton
                  variant="minimal"
                  size={'1'}
                  color="gray"
                  onClick={(e) => updateProductQuantity(productId, e.shiftKey ? 10 : 1)}
                  icon={HiOutlinePlusSmall}
                />
              </Flex>
              {!isTeamPlan || (isTeamPlan && quantity) > 0 ? (
                <Flex gap={'4'} align={'center'}>
                  <Badge className="h-9 w-9 rounded" color={isTeamPlan ? 'grass' : undefined}>
                    <ProductIcon quota={isTeamPlan ? 'team_plan' : (quota as any)} className="h-full w-full" />
                  </Badge>
                  <Flex direction={'column'} gap={'1'}>
                    <Text size={'2'} weight={'medium'}>
                      {name}
                    </Text>
                    <Text size={'1'} color="gray">
                      {description}
                    </Text>
                  </Flex>
                </Flex>
              ) : null}
              <Flex gap={'2'} justify={'end'} pr={'4'}>
                {initialQuantity && quantity !== initialQuantity ? (
                  <Text size={'2'} color="gray" className="italic line-through opacity-50">
                    ${initialQuantity * price}{' '}
                  </Text>
                ) : null}
                {quantity || initialQuantity ? (
                  <Text size={'2'} color="gray">
                    ${quantity * price}
                  </Text>
                ) : null}
              </Flex>
            </Fragment>
          )
        )}

        <Flex gap={'1'} className="col-start-2" pt={'2'}>
          <Text size={'2'} weight={'medium'}>
            Total Add-ons cost per month
          </Text>
        </Flex>
        <Flex justify={'end'} align={'center'} pr={'4'} gap={'2'} pt={'2'}>
          {initialTotalCost !== totalCost && (
            <Text className="italic line-through opacity-60" color="gray">
              ${initialTotalCost}
            </Text>
          )}
          <Text weight={'medium'}>${totalCost}</Text>
        </Flex>
      </Grid>
      <Separator size="4" />
      <Button
        ml={'auto'}
        className="col-start-2 w-fit"
        disabled={isEqual(productQuantityMap, initialProductQuantityMap)}
        loading={isPending}
        onClick={() => {
          trackEvent({
            type: 'update_addons',
            state: 'clicked',
            upgrade: totalCost > initialTotalCost,
            workspaceId,
            plan,
          })
          return showConfirmationDialog({
            onConfirm: () => {
              trackEvent({
                type: 'update_addons',
                state: 'confirmed',
                upgrade: totalCost > initialTotalCost,
                workspaceId,
                plan,
              })
              //TODO: This is a workaround to invalidate the cache when the user changes the plan in the stripe customer portal
              void queryClient.invalidateQueries({ queryKey: useQueryKeys().getAllWorkspaceUsages(workspaceId) })
              handleCheckout()
            },
            title: 'Update plan',
            content: (
              <Text>
                Your new plan will cost <Text weight={'bold'}> ${totalCost}</Text> per month including add-ons and your
                plan. Are you sure you want to proceed?
              </Text>
            ),
          })
        }}
      >
        <Text>Update plan</Text>
      </Button>
    </PageSection>
  )
}
