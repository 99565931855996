import { Dialog, Text } from '@radix-ui/themes'
import { useDialogStore } from '../stores'

export const DialogProvider = () => {
  const title = useDialogStore((state) => state.title)
  const content = useDialogStore((state) => state.content)
  const isOpen = useDialogStore((state) => state.show)
  const setIsOpen = useDialogStore((state) => state.setShow)

  return (
    <Dialog.Root open={isOpen} onOpenChange={setIsOpen}>
      <Dialog.Content style={{ maxWidth: 450 }}>
        <Dialog.Title>{title}</Dialog.Title>
        <Text size={'2'}>{content}</Text>
      </Dialog.Content>
    </Dialog.Root>
  )
}
