import { useQueryClient } from '@tanstack/react-query'
import { sortBy } from 'lodash'
import { useState } from 'react'
import { ConfirmationModal } from '~/components'
import { Button } from '~/elements'
import { WorkspaceObject } from '~/features/workspaces/types'
import { useCurrentUserWorkspaceMember, useDeleteWorkspaceMember, useWorkspaces } from '~/hooks'
import { useLocalStore } from '~/stores'

type Props = {
  workspace: WorkspaceObject
  navigateToHome: () => Promise<void>
}

export function LeaveWorkspaceButton({ workspace, navigateToHome }: Props) {
  const queryClient = useQueryClient()
  const selfMember = useCurrentUserWorkspaceMember({ workspaceId: workspace.id })
  const { mutate: deleteWorkspaceMember, isSuccess: leaveWorkspaceSuccess } = useDeleteWorkspaceMember()

  const [isOpen, setIsOpen] = useState(false)

  const { data: workspaces } = useWorkspaces()
  const { setLastActiveWorkspaceId } = useLocalStore()

  if (!selfMember) {
    return null
  }

  const leaveWorkspace = () => {
    deleteWorkspaceMember({
      workspaceId: workspace.id,
      id: selfMember.id,
      requesterMemberId: selfMember.id,
      onSuccess: async () => {
        const newestWorkspace = sortBy(
          workspaces?.filter((w) => w.id !== workspace.id) ?? [], // Exclude the workspace that was abandoned, as it will still be in the cache.
          (x) => x.createdAt
        ).pop()
        if (newestWorkspace) {
          setLastActiveWorkspaceId(newestWorkspace.id)
        }

        await navigateToHome() // Important: Wait for route change before invalidating cache otherwise queries for the abandoned workspace will try to be refetched.

        await queryClient.invalidateQueries()
      },
    })

    return leaveWorkspaceSuccess
  }

  return (
    <>
      <Button
        variant="tertiary"
        disabled={selfMember.role === 'owner'}
        className="mr-2"
        onClick={() => setIsOpen(true)}
      >
        Leave Workspace
      </Button>

      <ConfirmationModal
        open={isOpen}
        setOpen={setIsOpen}
        onConfirm={leaveWorkspace}
        title="Leave Workspace"
        okButtonText="Yes, leave"
        cancelButtonText="No, go back"
        dialogVariant="warning"
      >
        <p>
          You're about to leave the workspace <span className="font-bold">{workspace.name}</span>. You will need to
          contact the owner or administrator(s) of this workspace if you need to regain access later.
        </p>
        <p className="mt-3">Are you sure you want to proceed?</p>
      </ConfirmationModal>
    </>
  )
}
