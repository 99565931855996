// this file was automatically generated, do not edit
/* eslint-disable */

export interface UpdateTaskRequestHeaders {}

export interface UpdateTaskRequestQuery {}

export interface UpdateTaskRequestParams {
  id: string;
}

export interface UpdateTaskRequestBody {
  /**
   * Title describing the task
   */
  title?: string;
  /**
   * All the notes related to the execution of the current task
   */
  description?: string;
  /**
   * Content related to the task
   */
  data?: {
    [k: string]: any;
  };
  /**
   * The timeout date where the task should be failed in the ISO 8601 format
   */
  timeoutAt?: string;
  /**
   * Status of the task
   */
  status?: "pending" | "in_progress" | "failed" | "completed" | "blocked" | "paused" | "timeout" | "cancelled";
  /**
   * Tags for the [Task](#schema_task)
   */
  tags?: {
    [k: string]: string;
  };
}

export type UpdateTaskInput = UpdateTaskRequestBody & UpdateTaskRequestHeaders & UpdateTaskRequestQuery & UpdateTaskRequestParams

export type UpdateTaskRequest = {
  headers: UpdateTaskRequestHeaders;
  query: UpdateTaskRequestQuery;
  params: UpdateTaskRequestParams;
  body: UpdateTaskRequestBody;
}

export const parseReq = (input: UpdateTaskInput): UpdateTaskRequest & { path: string } => {
  return {
    path: `/v1/chat/tasks/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: { 'title': input['title'], 'description': input['description'], 'data': input['data'], 'timeoutAt': input['timeoutAt'], 'status': input['status'], 'tags': input['tags'] },
  }
}

export interface UpdateTaskResponse {
  /**
   * Task definition
   */
  task: {
    /**
     * Id of the [Task](#schema_task)
     */
    id: string;
    /**
     * Title describing the task
     */
    title: string;
    /**
     * All the notes related to the execution of the current task
     */
    description: string;
    /**
     * Type of the task
     */
    type: string;
    /**
     * Content related to the task
     */
    data: {
      [k: string]: any;
    };
    /**
     * Status of the task
     */
    status: "pending" | "in_progress" | "failed" | "completed" | "blocked" | "paused" | "timeout" | "cancelled";
    /**
     * Parent task id is the parent task that created this task
     */
    parentTaskId?: string;
    /**
     * Conversation id related to this task
     */
    conversationId?: string;
    /**
     * Specific user related to this task
     */
    userId?: string;
    /**
     * The timeout date where the task should be failed in the ISO 8601 format
     */
    timeoutAt: string;
    /**
     * Creation date of the task in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the task in ISO 8601 format
     */
    updatedAt: string;
    /**
     * If the task fails this is the reason behind it
     */
    failureReason?: string;
    /**
     * Set of [Tags](/docs/developers/concepts/tags) that you can attach to a [Task](#schema_task). Individual keys can be unset by posting an empty value to them.
     */
    tags: {
      [k: string]: string;
    };
  };
}

