// this file was automatically generated, do not edit
/* eslint-disable */

export interface SearchFilesRequestHeaders {}

export interface SearchFilesRequestQuery {
  tags?: {
    [k: string]: string;
  };
  query: string;
  contextDepth?: number;
  limit?: number;
  includeBreadcrumb?: boolean;
}

export interface SearchFilesRequestParams {}

export interface SearchFilesRequestBody {}

export type SearchFilesInput = SearchFilesRequestBody & SearchFilesRequestHeaders & SearchFilesRequestQuery & SearchFilesRequestParams

export type SearchFilesRequest = {
  headers: SearchFilesRequestHeaders;
  query: SearchFilesRequestQuery;
  params: SearchFilesRequestParams;
  body: SearchFilesRequestBody;
}

export const parseReq = (input: SearchFilesInput): SearchFilesRequest & { path: string } => {
  return {
    path: `/v1/files/search`,
    headers: {  },
    query: { 'tags': input['tags'], 'query': input['query'], 'contextDepth': input['contextDepth'], 'limit': input['limit'], 'includeBreadcrumb': input['includeBreadcrumb'] },
    params: {  },
    body: {  },
  }
}

export interface SearchFilesResponse {
  passages: {
    /**
     * The content of the matching passage in the file including surrounding context, if any.
     */
    content: string;
    /**
     * The score indicating the similarity of the passage to the query. A higher score indicates higher similarity.
     */
    score: number;
    /**
     * The passage metadata.
     */
    meta: {
      /**
       * The type of passage
       */
      type?: "chunk";
      /**
       * The subtype of passage, if available.
       */
      subtype?: "title" | "subtitle" | "paragraph" | "list" | "blockquote" | "table" | "code";
      /**
       * Page number the passage is located on. Only applicable if the passage was extracted from a PDF file.
       */
      pageNumber?: number;
      /**
       * Position number of the passage in the file relative to the other passages, if available. Can be used to know the order of passages within a file.
       */
      position?: number;
    };
    file: {
      /**
       * File ID
       */
      id: string;
      /**
       * Unique key for the file. Must be unique across the bot (and the integration, when applicable).
       */
      key: string;
      /**
       * MIME type of the file's content
       */
      contentType: string;
      /**
       * The tags of the file as an object of key-value pairs.
       */
      tags: {
        [k: string]: string;
      };
      /**
       * File creation timestamp in ISO 8601 format
       */
      createdAt: string;
      /**
       * File last update timestamp in ISO 8601 format
       */
      updatedAt: string;
    };
  }[];
}

