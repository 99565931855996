import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getApiClient, getGenericClient } from '../../client'
import { handleHttpErrors } from '../../shared'
import { useQueryKeys } from '../useQueryKeys'
import { getQueryKey } from '~/services'

type UpdateWorkspaceParams = {
  workspaceId: string
  options?: { onSuccess?: () => Promise<void>; onError?: () => Promise<void>; onSettled?: () => Promise<void> }
} & Parameters<ReturnType<typeof getGenericClient>['createWorkspaceMember']>['0']

export function useCreateWorkspaceMember() {
  const queryClient = useQueryClient()
  const { getWorkspaceUsages, getAllWorkspaceUsages } = useQueryKeys()

  return useMutation({
    mutationFn: async ({ workspaceId, ...props }: UpdateWorkspaceParams) => {
      return await getApiClient(workspaceId)
        .createWorkspaceMember({ ...props })
        .catch(handleHttpErrors)
    },
    onSuccess: async (_, { workspaceId, options }) => {
      const listMembersKey = getQueryKey('workspaces_/$workspaceId_/members', { workspaceId })

      await queryClient.invalidateQueries({ queryKey: listMembersKey })
      await queryClient.invalidateQueries({ queryKey: getWorkspaceUsages(workspaceId) })
      await queryClient.invalidateQueries({ queryKey: getAllWorkspaceUsages(workspaceId) })

      await options?.onSuccess?.()
    },
    onSettled: (_data, _error, { options }) => {
      if (options?.onSettled) {
        void options.onSettled()
      }
    },
  })
}
