// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListFilePassagesRequestHeaders {}

export interface ListFilePassagesRequestQuery {
  nextToken?: string;
  limit?: number;
}

export interface ListFilePassagesRequestParams {
  fileId: string;
}

export interface ListFilePassagesRequestBody {}

export type ListFilePassagesInput = ListFilePassagesRequestBody & ListFilePassagesRequestHeaders & ListFilePassagesRequestQuery & ListFilePassagesRequestParams

export type ListFilePassagesRequest = {
  headers: ListFilePassagesRequestHeaders;
  query: ListFilePassagesRequestQuery;
  params: ListFilePassagesRequestParams;
  body: ListFilePassagesRequestBody;
}

export const parseReq = (input: ListFilePassagesInput): ListFilePassagesRequest & { path: string } => {
  return {
    path: `/v1/files/${encodeURIComponent(input['fileId'])}/passages`,
    headers: {  },
    query: { 'nextToken': input['nextToken'], 'limit': input['limit'] },
    params: { 'fileId': input['fileId'] },
    body: {  },
  }
}

export interface ListFilePassagesResponse {
  passages: {
    /**
     * Passage ID
     */
    id: string;
    /**
     * The content of the passage.
     */
    content: string;
    /**
     * The passage metadata.
     */
    meta: {
      /**
       * The type of passage
       */
      type?: "chunk";
      /**
       * The subtype of passage, if available.
       */
      subtype?: "title" | "subtitle" | "paragraph" | "list" | "blockquote" | "table" | "code";
      /**
       * Page number the passage is located on. Only applicable if the passage was extracted from a PDF file.
       */
      pageNumber?: number;
      /**
       * Position number of the passage in the file relative to the other passages, if available. Can be used to know the order of passages within a file.
       */
      position?: number;
    };
  }[];
  meta: {
    /**
     * The token to use to retrieve the next page of results, passed as a query string parameter (value should be URL-encoded) to this API endpoint.
     */
    nextToken?: string;
  };
}

