import {
  useFloating,
  useInteractions,
  useClick,
  useRole,
  useDismiss,
  FloatingPortal,
  FloatingOverlay,
  FloatingFocusManager,
} from '@floating-ui/react-dom-interactions'
import cx from 'classnames'
import { useUpdateEffect } from 'react-use'

type Props = {
  open: boolean
  className?: string
  setOpen: (open: boolean) => void
  onClose?: () => void
  children: React.ReactNode
  canEscapeKeyClose?: boolean
  canOutsideClickClose?: boolean
}

/**
 * @deprecated Components in /elements and deprecated in favor of elementsV2 or directly from Radix/themes if not in elementsV2
 */
export const Modal = ({
  className,
  open,
  setOpen,
  children,
  canOutsideClickClose,
  canEscapeKeyClose,
  onClose,
}: Props) => {
  const { floating, context } = useFloating({
    open,
    onOpenChange: setOpen,
  })

  useUpdateEffect(() => {
    if (!open && onClose) {
      onClose()
    }
  }, [open])

  const { getFloatingProps } = useInteractions([
    useClick(context),
    useRole(context),
    useDismiss(context, {
      outsidePointerDown: canOutsideClickClose,
      escapeKey: canEscapeKeyClose,
    }),
  ])

  return (
    <FloatingPortal>
      {open && (
        <FloatingOverlay
          lockScroll
          className={cx('z-[90] grid place-items-center bg-gray-12 bg-opacity-30 backdrop-blur-sm')}
        >
          <FloatingFocusManager context={context}>
            <div
              {...getFloatingProps({
                ref: floating,
                className: cx(
                  'mx-auto  mb-[20vh] flex max-h-[80vh] min-w-[500px] max-w-[800px] flex-col overflow-hidden rounded-md bg-gray-1 outline-none',
                  className
                ),
              })}
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingOverlay>
      )}
    </FloatingPortal>
  )
}

type HeaderProps = JSX.IntrinsicElements['div']

Modal.Header = (props: HeaderProps) => (
  <div {...props} className={cx('flex justify-between rounded-t-md border-b bg-gray-1 p-4')} />
)

Modal.Body = (props: JSX.IntrinsicElements['div']) => (
  <div {...props} className={cx('flex flex-col overflow-y-auto p-4')} />
)

Modal.Footer = (props: JSX.IntrinsicElements['div']) => (
  <div {...props} className={cx('flex justify-between border-t border-gray-4 px-4 py-3', props.className)} />
)
