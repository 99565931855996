import { useQuery } from '@tanstack/react-query'
import { useQueryKeys } from '../useQueryKeys'
import { BillingApiClient } from '../../features/billing/services'

export function useBillingSubscription(workspaceId: string) {
  const queryKeys = useQueryKeys()

  const { data, ...rest } = useQuery({
    enabled: Boolean(workspaceId),
    queryKey: queryKeys.getWorkspaceBillingSubscription(workspaceId),
    queryFn: async () => {
      if (!workspaceId) {
        throw new Error('No current workspace')
      }
      try {
        const subscription = await BillingApiClient.getSubscription(workspaceId)
        if (subscription && 'items' in subscription) {
          return subscription
        }
        throw new Error('Workspace has no active subscription')
      } catch (error) {
        throw new Error('Could not find an active subscription')
      }
    },
    // TODO: wrap useQuery in a type definition
    meta: { suppressError: true },
  })

  return { subscription: data, ...rest }
}
