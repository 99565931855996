import Stripe from 'stripe'
import { config, httpClient } from '../../../shared'
import { ProductWithPrice } from '../../usage/useBilling'
import { Invoice, SetupIntent } from '../types'

const CDM_BACKEND_URL = config.cdmBackendBaseUrl

export class BillingApiClient {
  static async getInvoices(workspaceId: string): Promise<Invoice[]> {
    return await httpClient.callApi<Invoice[]>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/invoices`,
      method: 'GET',
    })
  }

  static async createSetupIntent(workspaceId: string): Promise<SetupIntent> {
    return await httpClient.callApi<SetupIntent>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/setup-intent`,
      method: 'POST',
    })
  }

  static async setDefaultPaymentMethod(workspaceId: string, paymentMethodId: string): Promise<void> {
    await httpClient.callApi({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/default-payment-method/${paymentMethodId}`,
      method: 'POST',
    })
  }

  static async chargeUnpaidInvoices(workspaceId: string): Promise<void> {
    await httpClient.callApi<void>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/charge-unpaid-invoices`,
      method: 'POST',
    })
  }

  static async getSubscription(workspaceId: string) {
    return await httpClient.callApi<Stripe.Subscription | {}>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/subscription`,
      method: 'GET',
    })
  }
  static async updateSubscription(workspaceId: string, payload: any) {
    return await httpClient.callApi<any>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/subscription`,
      data: payload,
      method: 'PUT',
    })
  }

  static async generateCheckoutSession(workspaceId: string, productQuantityConfig: Record<string, any>, email: string) {
    return await httpClient.callApi<{ url: string }>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/checkout`,
      data: { productQuantityConfig, email },
      method: 'POST',
    })
  }

  static async generateCustomerPortalSession(workspaceId: string) {
    return await httpClient.callApi<{ url: string }>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/customer-portal`,
      method: 'POST',
    })
  }

  static async getBillingProducts(workspaceId: string) {
    return await httpClient.callApi<ProductWithPrice[]>({
      baseURL: CDM_BACKEND_URL,
      url: `/v1/billing/${workspaceId}/products`,
      method: 'GET',
    })
  }
}
