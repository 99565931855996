import { getClient, WebchatProvider, Webchat } from '@botpress/webchat'
import { Section } from '@radix-ui/themes'
import { Link, Outlet, createFileRoute } from '@tanstack/react-router'
import { EmptyState as LegacyEmptyState } from '~/components'
import { Boundrary, Navbar, type NavbarItem } from '~/componentsV2'
import { Button, Card, EmptyState, Page, Spinner } from '~/elementsv2'
import { parseWebchatScript } from '~/features/bots/utils'
import {
  useCurrentRouteId,
  useFeature,
  useIntegration,
  useIsAuthorized,
  useUpdateBot,
  useWebchatTheme,
  useWebchatV2,
} from '~/hooks'
import { SidebarLayout } from '~/layouts'
import { useSuspenseQuery } from '~/services'
import { getCdmStudioUrl, config as globalConfig } from '~/shared/configurations'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat')({
  component: () => <Component />,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const webchatIntegration = useIntegration({ name: 'webchat', version: 'latest' }).data

  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  if (!Object.values(bot.integrations).find((integration) => integration.name === 'webchat')) {
    return (
      <Page title={'Webchat'}>
        <Section size={'4'}>
          <EmptyState
            title="Webchat not installed"
            description="You must install the Webchat integration to interact with it."
            primaryAction={
              <Link to="/hub/integrations/$integrationId" params={{ integrationId: webchatIntegration?.id ?? '' }}>
                <Button>Install Webchat</Button>
              </Link>
            }
          />
        </Section>
      </Page>
    )
  }

  return (
    <Page title={'Webchat'}>
      <SidebarLayout
        main={<Outlet />}
        leftSidebar={<LeftSidebar />}
        rightSidebarSize="5"
        rightSidebar={
          <Boundrary>
            <RightSidebar />
          </Boundrary>
        }
      />
    </Page>
  )
}

const LeftSidebar = () => {
  const sidebarMenu = ['general', 'share', 'advanced-settings', 'styles'] as const
  const { workspaceId, botId } = Route.useParams()
  const location = useCurrentRouteId() ?? ''

  const items: NavbarItem[] = sidebarMenu.map((item) => ({
    text: item.replace('-', ' '),
    to: `/workspaces/$workspaceId/bots/$botId/webchat/${item}`,
    active: location.includes(item),
  }))

  return <Navbar className="capitalize" items={items} workspaceId={workspaceId} botId={botId} direction="column" />
}

const RightSidebar = () => {
  const { workspaceId, botId } = Route.useParams()
  const { user } = Route.useRouteContext()
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data

  const { data: webchatIntegration } = useIntegration({ name: 'webchat', version: '0.2.0' })
  const { data: script } = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_/webchat', {
    botId,
    workspaceId,
    type: 'fullscreen',
  })
  const { mutate: updateBot } = useUpdateBot()

  const { isWebchatV2Enabled } = useWebchatV2()
  const uploadEnabled = useFeature('dashboardFileUpload')

  const webchatInstalledIntegration = Object.values(bot.integrations).find(
    (integration) => integration.name === 'webchat'
  )

  const config = parseWebchatScript(script)
  const client = getClient({
    apiUrl: globalConfig.webchatUrl,
    clientId: webchatInstalledIntegration?.webhookId ?? '',
  })
  const isAuthorized = useIsAuthorized({ workspaceId, userId: user.id })
  const isAuthorizedToOpenStudio = isAuthorized('studio.view') || isAuthorized('studio.edit')

  const { theme, style } = useWebchatTheme({ themeName: 'eggplant' })

  if (!bot.deployedAt) {
    return (
      <Card className="flex h-200 items-center px-8">
        <EmptyState
          iconSize={3}
          title={'Chatbot not published'}
          description={'You must publish the chatbot from Botpress Studio to be able to interact with it.'}
          primaryAction={
            isAuthorizedToOpenStudio ? (
              <a href={getCdmStudioUrl(bot.id)} target="_blank" rel="noopener noreferrer">
                <Button disabled={!isAuthorizedToOpenStudio}>Edit in studio</Button>
              </a>
            ) : undefined
          }
        />
      </Card>
    )
  }

  function enableWebchat(webchatIntegrationId: string) {
    updateBot({
      id: bot.id,
      workspaceId,
      integrations: { [webchatIntegrationId]: { enabled: true, configuration: {} } },
    })
  }

  if (!(script && webchatIntegration)) {
    return (
      <div className="flex h-full items-center justify-center">
        <Spinner />
      </div>
    )
  }

  if (!bot.integrations[webchatIntegration?.id ?? '']?.enabled) {
    // TODO: Fix this emptyState
    return (
      <div className="flex flex-1 flex-col items-center justify-center">
        <LegacyEmptyState
          icon="NoMessages"
          title="Webchat disabled"
          message="You must enable the Webchat integration to interact with it."
        />
        <div className="flex flex-1">
          <div>
            <Button onClick={() => enableWebchat(webchatIntegration.id)}>Enable Webchat</Button>
          </div>
        </div>
      </div>
    )
  }

  if (isWebchatV2Enabled && client && style && theme) {
    return (
      <div className="h-200">
        <style>{style}</style>
        <WebchatProvider
          allowFileUpload={uploadEnabled}
          key={JSON.stringify(config)}
          theme={theme}
          configuration={config ?? {}}
          client={client}
        >
          <Webchat />
        </WebchatProvider>
      </div>
    )
  }

  return (
    <div className="relative h-full min-h-200 w-full overflow-clip px-0 py-0">
      <iframe title="webchat" width="100%" height="100%" srcDoc={`<body>${script}</body>`} />
    </div>
  )
}
