import { Flex, Text } from '@radix-ui/themes'
import { ComponentProps, type ReactNode } from 'react'
import { LinkProps } from '@tanstack/react-router'
import { Link } from '../elementsv2'

export type BreadcrumbPage = { name: string; leading?: ReactNode; trailing?: ReactNode } & Pick<
  LinkProps,
  'to' | 'params' | 'search'
>
type BreadcrumbsProps = { pages?: BreadcrumbPage[] } & Pick<ComponentProps<typeof Text>, 'size'> &
  Pick<ComponentProps<typeof Flex>, 'align' | 'gap' | 'role'>

export const Breadcrumbs = ({
  pages = [],
  align = 'center',
  gap = '1',
  role = 'list',
  size = '2',
  ...props
}: BreadcrumbsProps) => {
  return (
    <Flex {...props} role={role} align={align} gap={gap}>
      {pages.map(({ name, leading, trailing, ...pageProps }, i) => (
        <Flex key={name} gap={gap} align="center">
          {i > 0 && <Slash className="h-4 w-4 text-gray-8" />}
          {leading}
          {pageProps.to ? (
            <Link {...pageProps} size={size} color="gray">
              {name}
            </Link>
          ) : (
            <Text weight={'medium'} size={size}>
              {name}
            </Text>
          )}
          {trailing}
        </Flex>
      ))}
    </Flex>
  )
}

const Slash = (props: { className?: string }) => (
  <svg {...props} fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
  </svg>
)
