import { DateTime, Duration } from 'luxon'
import { ChartDateShortcut, dateShortcutDetails } from '../types'
import { useSuspenseQuery } from '@tanstack/react-query'
import { analyticsOverviewQueryOptions } from '../../../queries'
import { match, P } from 'ts-pattern'

export const defaultDataKeys = [
  'Total Users',
  'New Users',
  'Returning Users',
  'Sessions',
  'Bot Messages',
  'User Messages',
  'Total Messages',
]

type AnalyticsData = {
  timestamp: Date & Record<keyof typeof defaultDataKeys, number>
} & Omit<Record<string, number>, 'timestamp'>

type Props = {
  botId: string
  workspaceId: string
  children?: React.ReactNode | ((props: { data: AnalyticsData[]; resolution: Resolution }) => React.ReactNode)
  dateShortcut?: ChartDateShortcut
}
export const ChartDataProvider = ({ botId, workspaceId, children, dateShortcut = 'lastMonth' }: Props) => {
  const startDate = DateTime.now().minus({ days: dateShortcutDetails[dateShortcut].days }).toJSDate()
  const endDate = DateTime.now().toJSDate()
  const analytics = useSuspenseQuery(
    analyticsOverviewQueryOptions({ botId, workspaceId, startDate, endDate })
  ).data.records.map(({ endDateTimeUtc, startDateTimeUtc: _, eventTypes, customEvents, ...record }) => ({
    timestamp: DateTime.fromISO(endDateTimeUtc).toJSDate(),
    'Total Users': record.newUsers + record.returningUsers,
    'New Users': record.newUsers,
    'Returning Users': record.returningUsers,
    Sessions: record.sessions,
    'Bot Messages': record.botMessages,
    'User Messages': record.userMessages,
    'Total Messages': record.botMessages + record.userMessages,
    'Avg. Bot Messages per Session': (record.sessions ? record.botMessages / Math.max(1, record.sessions) : 0).toFixed(
      2
    ),
    'Avg. User Messages per Session': (record.sessions
      ? record.userMessages / Math.max(1, record.sessions)
      : 0
    ).toFixed(2),
    'Avg. Sessions per User': (record.sessions
      ? record.sessions / Math.max(1, record.newUsers + record.returningUsers)
      : 0
    ).toFixed(2),
    ...eventTypes,
    ...customEvents,
  })) as any as AnalyticsData[]

  return (
    <>
      {' '}
      {typeof children === 'function'
        ? children({
            data: fillGapsWithZeros(analytics, startDate, endDate),
            resolution: getResolution(startDate, endDate),
          })
        : children}
    </>
  )
}

function fillGapsWithZeros(data: AnalyticsData[], startDate: Date, endDate: Date): AnalyticsData[] {
  const [first] = data
  const last = data[data.length - 1]

  if (!first || !last) {
    return data
  }

  const keys = [...new Set([...data.flatMap((d) => Object.keys(d))])]

  const resolution = getResolution(startDate, endDate)

  // Sort data by timestamp
  data.sort((a, b) => a.timestamp.getTime() - b.timestamp.getTime())

  //if the first element in data is not older than startDate plus 1 resolution, add a zero filled element to the beginning of the data
  if (
    DateTime.fromJSDate(first.timestamp)
      .minus({ [resolution]: 1 })
      .toJSDate() > startDate
  ) {
    data.unshift(createZeroFilledData(startDate, Object.keys(first)))
  }

  //if the last element in data is not older that endDate minus 1 resolution, add a zero filled element to the end of the data
  if (
    DateTime.fromJSDate(last.timestamp)
      .plus({ [resolution]: 1 })
      .toJSDate() < endDate
  ) {
    data.push(createZeroFilledData(endDate, keys))
  }

  const roundedTimestampsData: AnalyticsData[] = data.map((d) => {
    const dt = DateTime.fromJSDate(d.timestamp)
    return { ...d, timestamp: dt.startOf(resolution).toJSDate() } as AnalyticsData
  })

  const filledData = roundedTimestampsData.reduce((acc: AnalyticsData[], curr, idx, src) => {
    // Always add the current item
    acc.push(curr)

    if (idx < src.length - 1) {
      const next = src[idx + 1]
      if (!next) {
        return acc
      }
      let currentTime = DateTime.fromJSDate(curr.timestamp)
      const nextTime = DateTime.fromJSDate(next.timestamp)
      // Check if there is a gap
      while (
        Duration.fromMillis(nextTime.toMillis() - currentTime.toMillis()) >= Duration.fromObject({ [resolution]: 2 })
      ) {
        currentTime = currentTime.plus({ [resolution]: 1 })
        acc.push(createZeroFilledData(currentTime.toJSDate(), keys))
      }
    }

    return acc
  }, [])
  return filledData
}

function createZeroFilledData(timestamp: Date, keys: string[]): AnalyticsData {
  const zeroFilledData = { timestamp } as AnalyticsData
  for (const key of keys) {
    if (key !== 'timestamp') {
      zeroFilledData[key] = 0
    }
  }
  return zeroFilledData
}

export type Resolution = 'hour' | 'day' | 'week' | 'month'
function getResolution(startDate: Date, endDate: Date): Resolution {
  const daySpan = getDaySpan(startDate, endDate)
  return match(daySpan)
    .with(P.number.gt(120), () => 'month' as const)
    .with(P.number.gt(60), () => 'week' as const)
    .with(P.number.gt(3), () => 'day' as const)
    .otherwise(() => 'hour' as const)
}

function getDaySpan(startDate: Date, endDate: Date) {
  return DateTime.fromJSDate(endDate).diff(DateTime.fromJSDate(startDate), 'days').days
}
