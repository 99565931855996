import React from 'react'
import { useAnalyticsOverview } from '../../../hooks'
import { SparkAreaChart } from '@tremor/react'
import { Tooltip } from '@radix-ui/themes'

type Props = {
  botId: string
  workspaceId: string
}

export const AnalyticsWidget = ({ botId, workspaceId }: Props) => {
  const analytics = useAnalyticsOverview({ botId, workspaceId }).data.records

  if (analytics.length < 5) {
    return null
  }

  return (
    <Tooltip content={'Messages'}>
      <SparkAreaChart
        data={analytics}
        colors={['green']}
        index={'endDateTimeUtc'}
        categories={['messages']}
        curveType="monotone"
      />
    </Tooltip>
  )
}
