import { Dialog, Flex, Button } from '@radix-ui/themes'
import React from 'react'
import { DialogStore } from '../stores'
import { cn } from '~/utils'

type Props = Partial<
  Pick<DialogStore, 'cancelLabel' | 'confirmLabel' | 'onCancel' | 'onConfirm' | 'variant' | 'disabled'> & {
    showCancel?: boolean
  }
>

export const defaultDialogFooterProps: Props = {
  cancelLabel: 'Cancel',
  confirmLabel: 'Confirm',
  onCancel: () => {},
  onConfirm: () => {},
  variant: 'primary',
  disabled: false,
  showCancel: true,
}

export const DialogFooter = (props: Props) => {
  const { cancelLabel, confirmLabel, onCancel, onConfirm, variant, disabled, showCancel } = {
    ...defaultDialogFooterProps,
    ...props,
  }

  return (
    <Flex gap="3" mt="4" justify="end">
      {showCancel && (
        <Dialog.Close>
          <Button onClick={onCancel} variant="soft" color="gray">
            {cancelLabel}
          </Button>
        </Dialog.Close>
      )}
      <Dialog.Close disabled={disabled}>
        <Button
          onClick={onConfirm}
          variant="solid"
          className={cn({ '!bg-blue-500': variant === 'primary', '!bg-red-500': variant === 'danger' })}
        >
          {confirmLabel}
        </Button>
      </Dialog.Close>
    </Flex>
  )
}
