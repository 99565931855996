import { infiniteQueryOptions } from '@tanstack/react-query'
import { getClient } from '~/features/hitl/client'
import { useQueryKeys } from '~/hooks'
import { cacheDuration } from '~/shared'

export type ListHITLConversationsProps = { botId: string; workspaceId: string }
export const listHitlConversationsInfiniteQuery = ({ botId, workspaceId, ...props }: ListHITLConversationsProps) => {
  const { listHitlConversations } = useQueryKeys()
  return infiniteQueryOptions({
    staleTime: cacheDuration.short,
    queryKey: listHitlConversations({ botId, workspaceId, ...props }),
    queryFn: async ({ pageParam }) =>
      (await getClient({ botId, workspaceId })).listConversations({ nextToken: pageParam, ...props }).catch((e) => {
        console.error('Failed to fetch conversations', e)
        return { conversations: [], meta: { nextToken: null } }
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta.nextToken
    },
    initialPageParam: undefined as string | undefined,
    meta: {
      suppressError: true,
    },
  })
}
