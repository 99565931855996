import { Link as ThemeLink } from '@radix-ui/themes'
import { Link as RouterLink, LinkProps } from '@tanstack/react-router'

import { ComponentProps, forwardRef } from 'react'

type Props = Pick<
  ComponentProps<typeof ThemeLink>,
  'size' | 'weight' | 'trim' | 'underline' | 'color' | 'highContrast' | 'truncate'
> &
  Omit<LinkProps, 'search'> &
  Partial<Pick<LinkProps, 'search'>>

export const Link = forwardRef<HTMLAnchorElement, Props>(
  ({ size, weight, trim, underline, color, highContrast, search = {}, truncate, ...props }, ref) => {
    return (
      <ThemeLink {...{ size, weight, trim, underline, color, highContrast, truncate }} asChild ref={ref}>
        <RouterLink {...props} search={search} />
      </ThemeLink>
    )
  }
)
