// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListWorkspaceInvoicesRequestHeaders {}

export interface ListWorkspaceInvoicesRequestQuery {}

export interface ListWorkspaceInvoicesRequestParams {
  id: string;
}

export interface ListWorkspaceInvoicesRequestBody {}

export type ListWorkspaceInvoicesInput = ListWorkspaceInvoicesRequestBody & ListWorkspaceInvoicesRequestHeaders & ListWorkspaceInvoicesRequestQuery & ListWorkspaceInvoicesRequestParams

export type ListWorkspaceInvoicesRequest = {
  headers: ListWorkspaceInvoicesRequestHeaders;
  query: ListWorkspaceInvoicesRequestQuery;
  params: ListWorkspaceInvoicesRequestParams;
  body: ListWorkspaceInvoicesRequestBody;
}

export const parseReq = (input: ListWorkspaceInvoicesInput): ListWorkspaceInvoicesRequest & { path: string } => {
  return {
    path: `/v1/admin/workspaces/${encodeURIComponent(input['id'])}/billing/invoices`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: {  },
  }
}

export interface ListWorkspaceInvoicesResponse {
  invoices: {
    id: string;
    period: {
      month: number;
      year: number;
    };
    /**
     * Date on which the invoice was generated.
     */
    date: string;
    /**
     * Total amount to pay of the invoice.
     */
    amount: number;
    /**
     * Currency of the invoice amount.
     */
    currency: string;
    paymentStatus: ("deleted" | "draft" | "open" | "paid" | "uncollectible" | "void") | null;
    /**
     * Date on which the invoice is due.
     */
    dueDate?: string;
    /**
     * Number of times payment has been unsuccessfully attempted on the invoice.
     */
    paymentAttemptCount: number | null;
    /**
     * Date on which the next payment attempt will be made.
     */
    nextPaymentAttemptDate: string | null;
    /**
     * URL to download the PDF file of the invoice.
     */
    pdfUrl: string;
  }[];
}

