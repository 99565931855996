import ChevronLeftIcon from '@heroicons/react/24/outline/ChevronLeftIcon'
import ChevronRightIcon from '@heroicons/react/24/outline/ChevronRightIcon'
import classNames from 'classnames'
import { ComponentProps, useState } from 'react'
import { useDebounce } from 'react-use'
import { Button, Card } from '../elementsv2'
import { Text } from '@radix-ui/themes'

const MONTHS = Array.from({ length: 12 }, (_, i) => new Date(0, i).toLocaleString('en', { month: 'long' }))

const isMoreThanNow = (date: Date) => date.valueOf() > Date.now()

function increment(date: Date, reverse?: boolean) {
  const newDate = new Date(date)
  newDate.setMonth(newDate.getMonth() + (reverse ? -1 : 1))
  return newDate
}

type Props = {
  selectedMonth: Date
  debouncedOnChange?: (month: Date) => void
  onChange?: (month: Date) => void
} & Omit<ComponentProps<typeof Card>, 'onChange'>

export const UsageMonthSelector = ({ selectedMonth, onChange, debouncedOnChange, ...props }: Props) => {
  const [_selectedMonth, setSelectedMonth] = useState<Date>(selectedMonth)

  useDebounce(() => debouncedOnChange?.(_selectedMonth), 500, [_selectedMonth])

  function handleOnChange(date: Date) {
    if (isMoreThanNow(date)) {
      return
    }

    setSelectedMonth(new Date(date))
    onChange?.(date)
  }

  return (
    <Card {...props} className={classNames('flex items-center gap-1 p-1', props.className)}>
      <Button
        variant="minimal"
        size={'1'}
        color="gray"
        data-analytics="usage/month-selector/previous"
        onClick={() => handleOnChange(increment(_selectedMonth, true))}
      >
        <ChevronLeftIcon className="size-4" />
      </Button>

      {/* <CalendarIcon className="mr-2 size-4" /> */}
      <Text>
        {MONTHS[_selectedMonth.getMonth()]?.slice(0, 3)} - {_selectedMonth.getFullYear()}
      </Text>
      <Button
        variant="minimal"
        size={'1'}
        color="gray"
        data-analytics="usage/month-selector/next"
        onClick={() => handleOnChange(increment(_selectedMonth))}
        disabled={isMoreThanNow(increment(_selectedMonth))}
      >
        <ChevronRightIcon className="size-4" />
      </Button>
    </Card>
  )
}
