import { quotaTypes } from '@bpinternal/const'
import { Box, Container, Flex, Grid, HoverCard, Separator, Text, Tooltip } from '@radix-ui/themes'
import { useSuspenseQuery as useTanstackSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute, useRouter } from '@tanstack/react-router'
import { ClientReturn } from 'botpress-client'
import { partition } from 'lodash'
import { PlusCircle } from 'lucide-react'
import { DateTime } from 'luxon'
import { useMemo, useState } from 'react'
import {
  HiListBullet,
  HiOutlineArrowTopRightOnSquare,
  HiOutlineBolt,
  HiOutlineBoltSlash,
  HiOutlineClock,
  HiOutlineCubeTransparent,
  HiOutlineEllipsisVertical,
  HiOutlineExclamationTriangle,
  HiOutlineMagnifyingGlass,
  HiOutlinePlus,
  HiOutlineXMark,
} from 'react-icons/hi2'
import { match } from 'ts-pattern'
import EmptyBotsIcon from '~/assets/ai-30.svg?react'
import { Boundrary, UsageProgressBar, UserAvatar } from '~/componentsV2'
import { BotIcon } from '~/componentsV2/BotIcon'
import {
  Avatar,
  Button,
  Card,
  ContextMenu,
  DropdownMenu,
  EmptyState,
  History,
  IconButton,
  Input,
  Link,
  List,
  MenuItem,
} from '~/elementsv2'
import { AnalyticsWidget } from '~/features/analytics'
import { auditMessages } from '~/features/audits/components'
import { Action } from '~/features/audits/types'
import { ContributionsSidebarSection, ViewAsSection, WorkspaceSocialHeader } from '~/features/workspaces/components'
import { useCreateBot, useDeleteBot, useIsAuthorized, useRecentAudits, useUpdateAlwaysAlive, useUsage } from '~/hooks'
import { SidebarLayout } from '~/layouts'
import { listAllIssuesQueryOptions, workspacesQueryOptions } from '~/queries'
import { getCdmStudioUrl } from '~/shared'
import { Icon } from '../../../elementsv2/Icon'
import { computeBotHistory } from '../../../features/bots/services'
import { DYNAMIC_QUOTAS } from '../../../features/usage/constants'
import { trackEvent } from '../../../providers'
import { userPreferencesQueryOptions } from '../../../queries/user'
import {
  getQueryOptions,
  showConfirmationDialog,
  showUpsellDialog,
  useQuery,
  useSuspenseQuery,
} from '../../../services'
import { cn } from '../../../utils'

export const Route = createFileRoute('/workspaces/$workspaceId/home')({
  component: WorkspaceComponent,
})

const BOTS_PER_PAGE = 10

function WorkspaceComponent() {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const { data: workspaces } = useTanstackSuspenseQuery(workspacesQueryOptions())
  const { data: bots = [] } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: workspace.id })

  return (
    <Container size={'4'} p={'5'}>
      {bots.length > 0 && (
        <>
          <WorkspaceSocialHeader workspace={workspace} />
          <Separator size={'4'} my={'6'} />
        </>
      )}
      <SidebarLayout
        main={
          <Boundrary showErrorIcon errorIconSize={5}>
            <BotList />
          </Boundrary>
        }
        rightSidebar={
          <>
            <Boundrary className="h-48" loaderSize={'7'}>
              <ViewAsSection currentRoute={Route.id} userWorkspaces={workspaces} />
            </Boundrary>
            <Boundrary className="h-48" loaderSize={'7'}>
              <UsagePreview count={4} />
              <Separator size={'4'} mt={'6'} mb={'4'} />
            </Boundrary>
            <Boundrary className="h-80" loaderSize={'7'}>
              <AuditsPreview />
              <Separator size={'4'} mt={'6'} mb={'4'} />
            </Boundrary>
            <Boundrary className="h-80" loaderSize={'7'}>
              <ContributionsSidebarSection userWorkspaces={workspaces} workspace={workspace} />
              <Separator size={'4'} mt={'6'} mb={'4'} />
            </Boundrary>
            <Boundrary className="h-24" loaderSize={'7'}>
              <MembersPreview />
            </Boundrary>
          </>
        }
      />
    </Container>
  )
}

const BotList = () => {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const { mutate: createBot, isPending } = useCreateBot(workspace.id)
  const [filter, setFilter] = useState('')
  const [nBots, setNBots] = useState(BOTS_PER_PAGE)
  const prefsProps = { path: '$workspaceId/botHistory', params: { workspaceId: workspace.id } } as const

  const { data: bots = [] } = useQuery('workspaces_/$workspaceId_/bots_', { workspaceId: workspace.id })
  const recentBots = useTanstackSuspenseQuery(userPreferencesQueryOptions(prefsProps)).data.filter(
    (b) => !!bots.find((bot) => bot.id === b)
  )
  const botHistory = useMemo(() => computeBotHistory(recentBots), [recentBots])

  if (bots.length === 0) {
    return (
      <Card className="py-2">
        <EmptyState
          icon={EmptyBotsIcon}
          className="h-80"
          title="Workspace has no bots"
          description="Your workspace has no bots. Get started by creating a new bot"
          primaryAction={
            <Button color="grass" onClick={() => createBot()} loading={isPending} size="3" className="cursor-pointer">
              <PlusCircle size="18" />
              Create Bot
            </Button>
          }
        />
      </Card>
    )
  }

  return (
    <Flex direction={'column'} gap={'4'} className="@container">
      {botHistory.length > 0 && (
        <Flex direction={'column'} gap={'2'}>
          <Flex align={'center'} gap={'1'}>
            <Icon icon={HiOutlineClock} color="gray" />
            <Text size={'2'} weight={'regular'}>
              Recent
            </Text>
          </Flex>
          <Grid gap={'2'} className="grid-cols-1 @xl:grid-cols-2">
            {botHistory.map((botId) => (
              <Card key={botId} className={cn('p-0', { '@xl:first:col-span-2': botHistory.length % 2 !== 0 })}>
                <BotPreview location="recent_bots" id={botId} />
              </Card>
            ))}
          </Grid>
        </Flex>
      )}
      <Flex direction={'column'} gap={'2'}>
        {botHistory.length > 0 && (
          <Flex align={'center'} gap={'1'}>
            <Icon icon={HiListBullet} color="gray" />
            <Text size={'2'} weight={'regular'}>
              Bots
            </Text>
          </Flex>
        )}
        <Flex width={'100%'} gap={'2'}>
          <Input
            leading={<HiOutlineMagnifyingGlass />}
            placeholder="Find a bot"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          <Button loading={isPending} leading={<HiOutlinePlus strokeWidth={'3'} />} onClick={() => createBot()}>
            <Text>New bot</Text>
          </Button>
        </Flex>
      </Flex>
      <List items={bots.filter((b) => b.name.toLowerCase().includes(filter.toLowerCase())).slice(0, nBots)}>
        {(bot) => <BotPreview location="all_bots" {...bot} />}
      </List>
      {bots.length > nBots && (
        <Button variant="ghost" className="mx-auto w-fit" onClick={() => setNBots((n) => n + BOTS_PER_PAGE)}>
          Load more
        </Button>
      )}
    </Flex>
  )
}

type BotPreviewProps = {
  id: string
  name?: string
  location?: 'recent_bots' | 'all_bots'
}
const BotPreview = ({ id: botId, name, location }: BotPreviewProps) => {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data
  const { user } = Route.useRouteContext()
  const authorize = useIsAuthorized({ userId: user.id, workspaceId: workspace.id })
  const { data: bot } = useQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId: workspace.id })
  const { mutate: deleteBot } = useDeleteBot(workspace.id)
  const { mutate: updateAlwaysAlive, isPending: alwaysAlivePending } = useUpdateAlwaysAlive()

  const router = useRouter()

  const isAuthorizedToUpdateBot = authorize('bots.update')
  const isAuthorizedToOpenStudio = authorize('studio.view') || authorize('studio.edit')
  const { always_alive: alwaysAliveUsage } = useUsage({ workspaceId: workspace.id, quotas: ['always_alive'] })
  const alwaysAliveDisabled = alwaysAliveUsage.value >= alwaysAliveUsage.quota && !bot?.alwaysAlive

  const integrations = Object.entries(bot?.integrations ?? {}).map(([botIntegrationId, integration]) => ({
    botIntegrationId,
    ...integration,
  }))
  const issues = useTanstackSuspenseQuery(listAllIssuesQueryOptions({ botId, workspaceId: workspace.id })).data

  const botActionsMenuItems: MenuItem[] = useMemo(
    () => [
      {
        type: 'item',
        hidden: !isAuthorizedToUpdateBot,
        content: bot?.alwaysAlive ? 'Disable Always Alive' : 'Enable Always Alive',
        onSelect: () => {
          alwaysAliveDisabled
            ? showUpsellDialog({
                quota: 'always_alive',
                workspaceId: workspace.id,
                onConfirm: () =>
                  void router.navigate({
                    to: '/workspaces/$workspaceId/settings/billing',
                    params: { workspaceId: workspace.id },
                  }),
              })
            : showConfirmationDialog({
                title: bot?.alwaysAlive ? 'Disable Always Alive' : 'Enable Always Alive',
                content: (
                  <Text>
                    Are you sure you want to {bot?.alwaysAlive ? 'disable' : 'enable'} the always alive mode for the bot{' '}
                    <Text weight={'bold'}>{name ?? bot?.name}</Text>?
                  </Text>
                ),
                onConfirm: () =>
                  updateAlwaysAlive({
                    botId,
                    workspaceId: workspace.id,
                    alwaysAlive: !bot?.alwaysAlive,
                  }),
              })
        },
        trailingIcon: bot?.alwaysAlive ? <HiOutlineBoltSlash /> : <HiOutlineBolt />,
      },
      {
        type: 'item',
        content: 'Edit in studio',
        trailingIcon: <HiOutlineArrowTopRightOnSquare />,
        hidden: !isAuthorizedToOpenStudio,
        onSelect: () => {
          window.open(getCdmStudioUrl(botId), '_blank')
        },
      },
      { type: 'separator', hidden: !isAuthorizedToUpdateBot },
      {
        type: 'item',
        hidden: !isAuthorizedToUpdateBot,
        content: 'Delete',
        trailingIcon: <HiOutlineXMark />,
        color: 'red',
        onSelect: () =>
          showConfirmationDialog({
            title: 'Delete bot',
            variant: 'danger',
            content: `Are you sure you want to delete the bot "${name ?? bot?.name}"?`,
            onConfirm: () =>
              deleteBot({
                id: botId,
                options: {},
              }),
          }),
      },
    ],
    [botId, name ?? bot?.name, deleteBot, bot?.alwaysAlive]
  )

  return (
    <ContextMenu content={botActionsMenuItems} variant="soft" color="gray">
      <Flex justify={'between'} gap={'4'} p="2" className="@container">
        <Flex gap={'4'} p="2" align={'center'}>
          <BotIcon id={botId} className="flex-none" />
          <Flex direction={'column'} gap={'1'}>
            <Flex align={'center'} gap={'2'}>
              <Link
                weight={'medium'}
                to="/workspaces/$workspaceId/bots/$botId"
                params={{ workspaceId: workspace.id, botId }}
                onClick={() => trackEvent({ type: 'navigate_to_bot', from: location })}
              >
                {name ?? bot?.name}
              </Link>
              <Tooltip content={bot?.alwaysAlive ? 'Always alive' : 'Not always alive'}>
                {match({ alwaysAlive: !!bot?.alwaysAlive, alwaysAlivePending })
                  .with({ alwaysAlivePending: true, alwaysAlive: false }, () => (
                    <Icon variant="soft" muted className="animate-pulse" icon={HiOutlineBolt} />
                  ))
                  .with({ alwaysAlivePending: true, alwaysAlive: true }, () => (
                    <Icon variant="soft" color="gray" muted className="animate-pulse" icon={HiOutlineBoltSlash} />
                  ))
                  .with({ alwaysAlive: true }, () => <Icon variant="soft" icon={HiOutlineBolt} />)
                  .with({ alwaysAlive: false }, () => <Icon icon={HiOutlineBoltSlash} padding muted color="gray" />)
                  .exhaustive()}
              </Tooltip>
            </Flex>
            <Flex align={'center'} gap={'4'}>
              <Link
                to="/workspaces/$workspaceId/bots/$botId/integrations"
                color="blue"
                className="text-gray-11 no-underline hover:text-accent-11"
                params={{ workspaceId: workspace.id, botId }}
              >
                <Flex align={'center'} gap={'1'}>
                  <HiOutlineCubeTransparent className="-mt-0.5" />
                  <Text size={'1'}>{integrations.length}</Text>
                </Flex>
              </Link>
              <Link
                color="blue"
                className="text-gray-11 no-underline hover:text-accent-11"
                to="/workspaces/$workspaceId/bots/$botId/issues"
                params={{ botId, workspaceId: workspace.id }}
              >
                <Flex
                  align={'center'}
                  gap={'1'}
                  className={cn({
                    '-m-0.5 -mx-1 rounded bg-red-3 p-0.5 px-1 text-red-11 hover:bg-red-4': issues.length > 0,
                  })}
                >
                  <HiOutlineExclamationTriangle />
                  <Text size={'1'}>{issues.length}</Text>
                </Flex>
              </Link>
              <Text size={'1'} className="hidden @md:block">
                {bot?.deployedAt ? <>Deployed {DateTime.fromISO(bot?.deployedAt).toRelative()}</> : <>Not deployed</>}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex>
          <Boundrary className="ml-auto" suspenseFallback={null} onError={() => null}>
            <Box className="self-center">
              <AnalyticsWidget botId={botId} workspaceId={workspace.id} />
            </Box>
          </Boundrary>
          <DropdownMenu content={botActionsMenuItems} variant="soft" color="gray">
            <IconButton size={'1'} variant="minimal" color="gray" icon={HiOutlineEllipsisVertical} />
          </DropdownMenu>
        </Flex>
      </Flex>
    </ContextMenu>
  )
}

const AuditsPreview = ({ count = 3 }) => {
  const { workspaceId } = Route.useParams()
  const audits = useRecentAudits(workspaceId).data.slice(0, count)
  const historyItems = audits.map((audit, index) => {
    return {
      timelinePointElement: audit.userEmail && (
        <HoverCard.Root>
          <HoverCard.Trigger>
            <UserAvatar size={'1'} userId={audit.userId ?? undefined} workspaceId={workspaceId} />
          </HoverCard.Trigger>
          <HoverCard.Content side="top">
            <Flex gap={'2'} align={'center'}>
              <UserAvatar size={'2'} userId={audit.userId ?? undefined} workspaceId={workspaceId} />
              <Text size={'2'} weight={'medium'}>
                {audit.userEmail}
              </Text>
            </Flex>
          </HoverCard.Content>
        </HoverCard.Root>
      ),
      content: (
        <Flex direction={'column'} gap="4">
          <Text className="text-xs">
            {(auditMessages[audit.action as Action] ?? auditMessages.UNKNOWN).render(audit)}
          </Text>
          {index === audits.length - 1 ? (
            <Link size={'1'} to="/workspaces/$workspaceId/audits" params={{ workspaceId }}>
              View all audits
            </Link>
          ) : null}
        </Flex>
      ),
      timestamp: audit.recordedAt,
    }
  })

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight={'medium'}>
        Recent changes
      </Text>
      <History items={historyItems} />
    </Flex>
  )
}

const MembersPreview = ({ count = 6 }) => {
  const { workspaceId } = Route.useParams()
  const workspaceMembers = useSuspenseQuery('workspaces_/$workspaceId_/members', { workspaceId }).data

  const shownMemebers = workspaceMembers.slice(0, count)
  const hiddenMembers = workspaceMembers.slice(count)

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight={'medium'}>
        Members
      </Text>
      <Flex gap={'2'} wrap={'wrap'}>
        {shownMemebers?.map((member) => (
          <HoverCard.Root key={member.id}>
            <HoverCard.Trigger>
              <UserAvatar
                className="h-8 w-8 cursor-pointer text-base"
                userId={member.userId ?? undefined}
                workspaceId={workspaceId}
              />
            </HoverCard.Trigger>
            <HoverCard.Content side="top">
              <Flex gap={'2'}>
                <Avatar className="h-9 w-9 text-base" name={member.email} />
                <Flex direction={'column'}>
                  <Text size={'2'} weight={'medium'}>
                    {member.email}
                  </Text>
                  <Text size={'1'} color={'gray'}>
                    {member.role}
                  </Text>
                </Flex>
              </Flex>
            </HoverCard.Content>
          </HoverCard.Root>
        ))}
      </Flex>
      <Link size={'1'} to="/workspaces/$workspaceId/members" params={{ workspaceId }}>
        {hiddenMembers.length === 0 ? 'View all members' : `+ ${hiddenMembers.length} more members`}
      </Link>
    </Flex>
  )
}

const UsagePreview = ({ count }: { count: number }) => {
  const { workspaceId } = Route.useParams()
  const workspace = useTanstackSuspenseQuery(getQueryOptions('workspaces_/$workspaceId_', { workspaceId })).data

  // We don't want to show these quotas in the UI
  const excludedQuotas: ClientReturn<'getUsage'>['usage']['type'][] = ['bing_search_spend', 'openai_spend']

  const usagesRecord = useUsage({
    workspaceId: workspace.id,
    quotas: [...DYNAMIC_QUOTAS, ...quotaTypes].filter((q) => !excludedQuotas.includes(q)),
  })

  const usages = [...Object.values(usagesRecord).filter((usage) => usage.value !== 0)].sort(
    (a, b) => b.percentage - a.percentage
  )

  const [highDynamicUsage, restUsages] = partition(
    usages,
    (usage) => DYNAMIC_QUOTAS.includes(usage.type as any) && usage.percentage > 50
  )

  return (
    <Flex direction={'column'} gap={'4'}>
      <Text size={'2'} weight={'medium'}>
        Usages
      </Text>
      {usages.length > 0 ? (
        <>
          <Flex direction={'column'} gap="2" pr="4">
            {highDynamicUsage.map((usage) => (
              <UsageProgressBar key={usage.type} {...usage} />
            ))}
            {restUsages.slice(0, count - highDynamicUsage.length).map((usage) => (
              <UsageProgressBar key={usage.type} {...usage} />
            ))}
          </Flex>

          <Link size={'1'} to="/workspaces/$workspaceId/usage" params={{ workspaceId: workspace.id }}>
            View all usages
          </Link>
        </>
      ) : (
        <Text size={'1'} className="text-gray-11">
          {workspace.name} has no usage.
          <br />
          <br /> Start building and using bots, to see overview of your billing limits usage here.
        </Text>
      )}
    </Flex>
  )
}
