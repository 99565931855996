import { getApiClientForBot } from '~/client'
import cookie from 'js-cookie'
import { config, PAT_KEY } from '../../../shared'
import * as hitl from 'hitl-client'
import * as webchat from './webchat'
import * as errors from './errors'
import { EventEmitter } from './eventEmitter'
import * as adapter from './adapter'

export type HITLClientArgs = {
  workspaceId: string
  botId: string
}

export const getClient = async ({ workspaceId, botId }: HITLClientArgs) =>
  await HITLClient.create({ workspaceId, botId })

type DisonnectedConversationState = {
  type: 'disconnected'
}
type ConnectingConversationState = {
  type: 'connecting'
  id: string
}
type ConnectedConversationState = {
  type: 'connected'
  id: string
  listener: hitl.SignalListener
}

type ConversationState = DisonnectedConversationState | ConnectingConversationState | ConnectedConversationState

type HITLClientState = {
  workspaceId: string
  botId: string
  webhookId: string
  userId: string
  userKey: string
  apiUrl: string
  emitter: EventEmitter<webchat.Events>
  client: hitl.Client
  conversation: ConversationState
}

export class HITLClient implements webchat.Client {
  public readonly mode = 'pushpin'

  private constructor(private readonly state: HITLClientState) {}

  public readonly on: EventEmitter<webchat.Events>['on'] = (...args) => {
    return this.state.emitter.on(...args)
  }

  public static async create({ workspaceId, botId }: HITLClientArgs): Promise<HITLClient> {
    const bpClient = getApiClientForBot({ workspaceId, botId })
    const { bot } = await bpClient.getBot({ id: botId })

    const integrationsByName = Object.entries(bot.integrations).map(([intId, int]) => ({ ...int, id: intId }))
    const hitlIntegration = integrationsByName.find((int) => int.name === 'hitl')

    if (!hitlIntegration || !hitlIntegration.enabled) {
      throw new errors.HITLIntegrationNotEnabledError()
    }

    const { webhookId } = hitlIntegration

    const apiUrl = `${config.hitlBaseUrl}/${webhookId}`
    const client = new hitl.Client({
      baseURL: apiUrl,
      withCredentials: true,
    })

    const token = cookie.get(PAT_KEY)
    const { key: userKey } = await client.login({ 'x-access-token': token })
    const { user } = await client.getUser({ 'x-user-key': userKey })

    const emitter = new EventEmitter<webchat.Events>()

    return new HITLClient({
      workspaceId,
      botId,
      webhookId,
      userId: user.id,
      userKey,
      apiUrl,
      emitter,
      client,
      conversation: { type: 'disconnected' },
    })
  }

  public get apiUrl(): string {
    return this.state.apiUrl
  }

  public get clientId(): string {
    return this.state.webhookId
  }

  public get userId(): string {
    return this.state.userId
  }

  public get conversationId(): string | undefined {
    if (this.state.conversation.type === 'disconnected') {
      return
    }
    return this.state.conversation.id
  }

  public get workspaceId(): string {
    return this.state.workspaceId
  }

  public get botId(): string {
    return this.state.botId
  }

  public async connect(): Promise<webchat.UserCredentials | undefined> {
    return { userId: this.state.userId, userToken: this.state.userKey }
  }

  public async disconnect(): Promise<void> {
    if (this.state.conversation.type === 'connected') {
      await this._disconnectConversation(this.state.conversation.listener)
      this.state.conversation = { type: 'disconnected' }
    }
    this.state.emitter.removeAllListeners() // remove listeners on self
  }

  public async getUser(): Promise<webchat.User> {
    throw new errors.UnsuportedFeatureError('Getting user')
  }

  public async updateUser(_user: webchat.User): Promise<webchat.User> {
    throw new errors.UnsuportedFeatureError('Updating user')
  }

  public async sendFile(_file: File): Promise<{ fileUrl: string; name: string; type: webchat.FileType }> {
    throw new errors.UnsuportedFeatureError('Sending files')
  }

  public async listConversations(
    input: Omit<hitl.ClientInputs['listConversations'], 'x-user-key'>
  ): Promise<hitl.ClientOutputs['listConversations']> {
    return await this.state.client.listConversations({
      'x-user-key': this.state.userKey,
      nextToken: input.nextToken,
    })
  }

  public async setAssignee(props: { conversationId: string; workspaceMemberId: string | null }): Promise<void> {
    const { conversationId, workspaceMemberId } = props
    await this.state.client.updateConversation({
      'x-user-key': this.state.userKey,
      id: conversationId,
      status: 'assigned',
      assignee:
        workspaceMemberId === null
          ? null
          : {
              workspaceMemberId,
            },
    })
  }

  public async solveTicket(props: { conversationId: string }): Promise<void> {
    const { conversationId: id } = props
    logUsage('solveTicket', id)
    await this.state.client.updateConversation({
      'x-user-key': this.state.userKey,
      status: 'solved',
      id,
    })
  }

  public async sendMessage(message: string): Promise<void> {
    if (!this.conversationId) {
      throw new errors.InvalidStateError('send message')
    }
    await this.state.client.createMessage({
      'x-user-key': this.state.userKey,
      conversationId: this.conversationId,
      payload: {
        type: 'text',
        text: message,
      },
    })
  }

  public async sendEvent(): Promise<void> {
    throw new errors.UnsuportedFeatureError('Sending events')
  }

  public async switchConversation(id: string): Promise<void> {
    logUsage('switchConversation', id)
    if (this.conversationId === id) {
      return // already connected
    }
    if (this.state.conversation.type === 'connecting') {
      return // already connecting
    }

    let current: hitl.SignalListener | undefined
    if (this.state.conversation.type === 'connected') {
      current = this.state.conversation.listener
    }

    this.state.conversation = { type: 'connecting', id }

    if (current) {
      await this._disconnectConversation(current)
    }

    this.state.conversation = {
      type: 'connected',
      id,
      listener: await this._connectConversation(id),
    }
  }

  public async conversationExists(id: string): Promise<boolean> {
    logUsage('conversationExists', id)
    const exists = await this.state.client
      .getConversation({
        'x-user-key': this.state.userKey,
        id,
      })
      .then(() => true)
      .catch((thrown) => {
        if (hitl.errors.isApiError(thrown) && thrown.code === 404) {
          return false
        }
        throw thrown
      })
    return exists
  }

  public async newConversation(): Promise<void> {
    logUsage('newConversation')
  }

  public async listMessages(): Promise<webchat.Message[]> {
    if (!this.conversationId) {
      throw new errors.InvalidStateError('send message')
    }
    logUsage('listMessages', this.conversationId)

    const messages: webchat.Message[] = []
    let nextToken: string | undefined = undefined
    do {
      const response = await this.state.client.listConversationMessages({
        'x-user-key': this.state.userKey,
        id: this.conversationId,
        nextToken,
      })
      nextToken = response.meta.nextToken

      const currentPage: webchat.Message[] = response.messages.map(adapter.mapMessage)
      messages.push(...currentPage)
    } while (nextToken)

    return messages
  }

  private async _connectConversation(id: string): Promise<hitl.SignalListener> {
    const listener = await this.state.client.listenConversation({
      id,
      'x-user-key': this.state.userKey,
    })
    listener.on('unknown', (ev) => {
      if (typeof ev === 'string' && ev === 'ping') {
        return
      }
      // eslint-disable-next-line no-console
      console.debug('unknown event', ev)
    })
    listener.on('message_created', (ev) => {
      if (ev.userId === this.state.userId) {
        return
      }

      this.state.emitter.emit('message', adapter.mapMessage(ev))
    })
    listener.on('error', () => {
      this.state.conversation = { type: 'disconnected' }
      this.state.emitter.emit('error', new Error('Connection to conversation lost'))
    })
    listener.on('webchat_visibility', (ev) => {
      this.state.emitter.emit('webchatVisibility', ev.visibility)
    })
    listener.on('webchat_config', (ev) => {
      this.state.emitter.emit('webchatConfig', ev.config)
    })
    listener.on('custom', (ev) => {
      this.state.emitter.emit('customEvent', ev.event)
    })
    return listener
  }

  private async _disconnectConversation(listener: hitl.SignalListener): Promise<void> {
    await listener.disconnect() // close the SSE stream
    listener.cleanup() // remove listeners on the SSE stream
  }
}

function logUsage(method: keyof HITLClient, ...args: any[]) {
  const argString = args.map((arg) => JSON.stringify(arg)).join(', ')
  // eslint-disable-next-line no-console
  console.log(`[HITL] client.${method}(${argString})`)
}
