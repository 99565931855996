import { Button as ThemeButton } from '@radix-ui/themes'
import { ComponentProps, ReactNode, forwardRef } from 'react'
import { cn } from '../utils'
import { Spinner } from './Spinner'

type Variant = ComponentProps<typeof ThemeButton>['variant'] | 'minimal'

type ButtonProps = { variant?: Variant; leading?: ReactNode; trailing?: ReactNode; loading?: boolean } & Omit<
  ComponentProps<typeof ThemeButton>,
  'variant'
>

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, leading, trailing, children, loading = false, disabled, ...props }, ref) => {
    if (variant === 'minimal') {
      return (
        <ThemeButton
          {...props}
          variant="outline"
          disabled={disabled || loading}
          className={cn(
            'relative cursor-pointer text-gray-11 shadow-none hover:text-accent-11 disabled:cursor-auto disabled:opacity-50',
            { '[&>*]:invisible': loading },
            className
          )}
          ref={ref}
        >
          {leading}
          {children}
          {trailing}
          {loading && (
            <Spinner
              size={'4'}
              className={cn(
                '!visible absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 [&_circle]:stroke-current'
              )}
            />
          )}
        </ThemeButton>
      )
    }
    return (
      // The loading spinner will only work if all childrends are wrapped in an html element (like a div) since we are applying visibility:hidden to the children
      <ThemeButton
        {...props}
        disabled={disabled || loading}
        variant={variant}
        className={cn('relative cursor-pointer disabled:cursor-auto', { '[&>*]:invisible': loading }, className)}
        ref={ref}
      >
        {leading}
        {children}
        {trailing}
        {loading && (
          <Spinner
            size={'4'}
            className={cn(
              '!visible absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 [&_circle]:stroke-current'
            )}
          />
        )}
      </ThemeButton>
    )
  }
)

export { Button }
