// this file was automatically generated, do not edit
/* eslint-disable */

export interface ConfigureIntegrationRequestHeaders {}

export interface ConfigureIntegrationRequestQuery {}

export interface ConfigureIntegrationRequestParams {}

export interface ConfigureIntegrationRequestBody {
  /**
   * Unique identifier of the integration that was installed on the bot
   */
  identifier: string;
}

export type ConfigureIntegrationInput = ConfigureIntegrationRequestBody & ConfigureIntegrationRequestHeaders & ConfigureIntegrationRequestQuery & ConfigureIntegrationRequestParams

export type ConfigureIntegrationRequest = {
  headers: ConfigureIntegrationRequestHeaders;
  query: ConfigureIntegrationRequestQuery;
  params: ConfigureIntegrationRequestParams;
  body: ConfigureIntegrationRequestBody;
}

export const parseReq = (input: ConfigureIntegrationInput): ConfigureIntegrationRequest & { path: string } => {
  return {
    path: `/v1/chat/integrations/configure`,
    headers: {  },
    query: {  },
    params: {  },
    body: { 'identifier': input['identifier'] },
  }
}

export interface ConfigureIntegrationResponse {}

