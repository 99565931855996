// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListIntegrationsRequestHeaders {}

export interface ListIntegrationsRequestQuery {
  nextToken?: string;
  name?: string;
  version?: string;
  dev?: boolean;
}

export interface ListIntegrationsRequestParams {}

export interface ListIntegrationsRequestBody {}

export type ListIntegrationsInput = ListIntegrationsRequestBody & ListIntegrationsRequestHeaders & ListIntegrationsRequestQuery & ListIntegrationsRequestParams

export type ListIntegrationsRequest = {
  headers: ListIntegrationsRequestHeaders;
  query: ListIntegrationsRequestQuery;
  params: ListIntegrationsRequestParams;
  body: ListIntegrationsRequestBody;
}

export const parseReq = (input: ListIntegrationsInput): ListIntegrationsRequest & { path: string } => {
  return {
    path: `/v1/admin/integrations`,
    headers: {  },
    query: { 'nextToken': input['nextToken'], 'name': input['name'], 'version': input['version'], 'dev': input['dev'] },
    params: {  },
    body: {  },
  }
}

export interface ListIntegrationsResponse {
  integrations: {
    /**
     * ID of the [Integration](#schema_integration)
     */
    id: string;
    /**
     * Name of the [Integration](#schema_integration)
     */
    name: string;
    /**
     * Version of the [Integration](#schema_integration)
     */
    version: string;
    /**
     * Creation date of the [Integration](#schema_integration) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Updating date of the [Integration](#schema_integration) in ISO 8601 format
     */
    updatedAt: string;
    /**
     * Title of the integration. This is the name that will be displayed in the UI
     */
    title: string;
    /**
     * Description of the integration. This is the description that will be displayed in the UI
     */
    description: string;
    /**
     * URL of the icon of the integration. This is the icon that will be displayed in the UI
     */
    iconUrl: string;
    /**
     * Idicates if the integration is public. Public integrations are available to all and cannot be updated without creating a new version.
     */
    public: boolean;
    /**
     * Status of the integration version verification
     */
    verificationStatus: "unapproved" | "pending" | "approved" | "rejected";
  }[];
  meta: {
    /**
     * The token to use to retrieve the next page of results, passed as a query string parameter (value should be URL-encoded) to this API endpoint.
     */
    nextToken?: string;
  };
}

