import cx from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { ComponentProps } from 'react'
import { HiX } from 'react-icons/hi'
import { Callout, IconButton } from '~/elementsv2'

type ToastProps = {
  content?: React.ReactNode
  isVisible?: boolean
  onDismiss?: () => void
} & ComponentProps<typeof Callout>

const MotionCallout = motion(Callout)

export const Toast = ({ color = 'gray', children, isVisible, onDismiss }: ToastProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <MotionCallout
          key="notification"
          variants={{
            open: {
              opacity: 1,
              translateY: 0,
            },
            closed: {
              opacity: 0,
              translateY: '-50%',
            },
          }}
          initial="closed"
          animate="open"
          exit="closed"
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
          color={color}
          className={cx(
            'z-[999] flex w-full max-w-[32em] items-center border bg-accent-3 shadow-lg [word-break:break-word]'
          )}
          calloutAction={<IconButton variant="ghost" icon={HiX} size={'1'} onClick={onDismiss} />}
        >
          {children}
        </MotionCallout>
      )}
    </AnimatePresence>
  )
}
