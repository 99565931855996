import { Container } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { BotConfigurationVariables } from '~/features/bots/components'
import { useIsAuthorized } from '~/hooks'
import { botQueryOptions } from '~/queries'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/configuration-variables')({
  component: () => {
    const { workspaceId, botId } = Route.useParams()
    const bot = useSuspenseQuery(botQueryOptions({ botId, workspaceId })).data
    const { user } = Route.useRouteContext()
    const authorize = useIsAuthorized({ workspaceId, userId: user.id })

    return (
      <Container size={'4'} pt={'8'} px="5">
        <BotConfigurationVariables bot={bot} workspaceId={workspaceId} authorize={authorize} />
      </Container>
    )
  },
})
