import { useState } from 'react'
import { getIntegrationsFromBot } from '~/features/bots/services'
import { webchatIntegrationConfigSchema, type WebchatIntegrationConfig } from '~/features/webchat/schemas'
import { useUpdateBot } from '~/hooks/bots'
import { showSuccessToast, useSuspenseQuery } from '~/services'

export const useWebchatConfig = ({ botId, workspaceId }: { botId: string; workspaceId: string }) => {
  const bot = useSuspenseQuery('workspaces_/$workspaceId_/bots_/$botId_', { botId, workspaceId }).data
  const webchatIntegration = getIntegrationsFromBot(bot).find((bi) => bi.name === 'webchat')
  const baseConfig = webchatIntegrationConfigSchema.safeParse(webchatIntegration?.configuration ?? {})
  const [config, setConfig] = useState<WebchatIntegrationConfig>(
    baseConfig.success ? baseConfig.data : { frontendVersion: 'v1', theme: 'eggplant' }
  )

  const { mutate: updateBot, isPending } = useUpdateBot()

  const updateWebchatIntegration = (
    configuration: Record<string, any>,
    options: { showToast?: boolean } = { showToast: true }
  ) => {
    if (!webchatIntegration) {
      return
    }
    updateBot({
      id: botId,
      workspaceId,
      integrations: {
        [webchatIntegration.id]: { enabled: webchatIntegration.enabled, configuration },
      },

      onSuccess: () => {
        if (options.showToast) {
          showSuccessToast('The integration configuration has been updated.')
        }
      },
    })
  }

  return { config, setConfig, updateWebchatIntegration, isPending, webchatIntegration }
}
