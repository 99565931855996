import { ComponentProps, createRef } from 'react'
import { create } from 'zustand'
import { type Callout } from '../elementsv2'

export type PortalStore = {
  bannerPortalRef: React.RefObject<HTMLDivElement>
} & Pick<ComponentProps<typeof Callout>, 'icon' | 'size' | 'variant' | 'color' | 'highContrast'>

export const usePortalStore = create<PortalStore>(() => ({
  bannerPortalRef: createRef<HTMLDivElement>(),
}))
