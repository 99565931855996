import { useSuspenseQuery } from '@tanstack/react-query'
import { Link, createFileRoute, useNavigate } from '@tanstack/react-router'
import { HiArrowLeft } from 'react-icons/hi2'
import { PublicIntegration } from '~/features/integrations'
import { HubIntegration } from '~/features/integrations/components'
import { trackEvent } from '~/providers'
import { getPublicIntegrationQueryOptions } from '~/queries'

export const Route = createFileRoute('/hub/integrations/$integrationId')({
  loader: ({ context, params }) => {
    void trackEvent({ type: 'integration_viewed', integration: { id: params.integrationId } })
    return context.queryClient.ensureQueryData(
      getPublicIntegrationQueryOptions({ integrationId: params.integrationId })
    )
  },
  component: () => {
    const { integrationId } = Route.useParams()
    const integration = useSuspenseQuery(getPublicIntegrationQueryOptions({ integrationId })).data
    const navigate = useNavigate()
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const navigateToBotIntegration = (workspaceId: string, botId: string, integrationId: string) => {
      void navigate({
        to: '/workspaces/$workspaceId/bots/$botId/integrations/$integrationId',
        params: { workspaceId, botId, integrationId },
      })
    }
    const navigateToHome = () => {
      void navigate({ to: '/' })
    }
    const navigateToHub = () => {
      void navigate({ to: '/hub' })
    }
    const navigateToMaintainerProfile = (workspace: PublicIntegration['ownerWorkspace']) => {
      void navigate({
        to: '/profile/$workspaceId',
        // @ts-ignore: this is a mask tanstack router should not enforce type literal but it does
        mask: { to: `/${workspace.handle ?? workspace.id}`, unmaskOnReload: false },
        params: { workspaceId: workspace.id },
      })
    }

    return (
      <HubIntegration
        integration={integration}
        backLinkElement={<HubLink />}
        {...{ navigateToBotIntegration, navigateToHome, navigateToHub, navigateToMaintainerProfile }}
      />
    )
  },
})

// Volountary use of same classes as in ~/pages/hub/HubIntegrationPage.tsx to make it look the same
const HubLink = () => (
  <Link
    to="/hub"
    className=" mb-8 mt-6 flex w-fit items-center gap-2 text-sm text-accent-11 decoration-accent-10 hover:underline"
  >
    <HiArrowLeft className="h-4 w-4 " />
    <span>Back to Hub</span>
  </Link>
)
