// this file was automatically generated, do not edit
/* eslint-disable */

export interface ListActivitiesRequestHeaders {}

export interface ListActivitiesRequestQuery {
  nextToken?: string;
  taskId: string;
  botId: string;
}

export interface ListActivitiesRequestParams {}

export interface ListActivitiesRequestBody {}

export type ListActivitiesInput = ListActivitiesRequestBody & ListActivitiesRequestHeaders & ListActivitiesRequestQuery & ListActivitiesRequestParams

export type ListActivitiesRequest = {
  headers: ListActivitiesRequestHeaders;
  query: ListActivitiesRequestQuery;
  params: ListActivitiesRequestParams;
  body: ListActivitiesRequestBody;
}

export const parseReq = (input: ListActivitiesInput): ListActivitiesRequest & { path: string } => {
  return {
    path: `/v1/admin/activities`,
    headers: {  },
    query: { 'nextToken': input['nextToken'], 'taskId': input['taskId'], 'botId': input['botId'] },
    params: {  },
    body: {  },
  }
}

export interface ListActivitiesResponse {
  activities: {
    id: string;
    description: string;
    taskId: string;
    category:
      | "unknown"
      | "capture"
      | "bot_message"
      | "user_message"
      | "agent_message"
      | "event"
      | "action"
      | "task_status"
      | "subtask_status"
      | "exception";
    data: {
      [k: string]: any;
    };
    /**
     * Creation date of the activity in ISO 8601 format
     */
    createdAt: string;
  }[];
  meta: {
    /**
     * The token to use to retrieve the next page of results, passed as a query string parameter (value should be URL-encoded) to this API endpoint.
     */
    nextToken?: string;
  };
}

