// this file was automatically generated, do not edit
/* eslint-disable */

export interface UpdateMessageRequestHeaders {}

export interface UpdateMessageRequestQuery {}

export interface UpdateMessageRequestParams {
  id: string;
}

export interface UpdateMessageRequestBody {
  /**
   * Set of [Tags](/docs/developers/concepts/tags) that you can attach to a [Conversation](#schema_conversation). The set of [Tags](/docs/developers/concepts/tags) available on a [Conversation](#schema_conversation) is restricted by the list of [Tags](/docs/developers/concepts/tags) defined previously by the [Bot](#schema_bot). Individual keys can be unset by posting an empty value to them.
   */
  tags: {
    [k: string]: string;
  };
}

export type UpdateMessageInput = UpdateMessageRequestBody & UpdateMessageRequestHeaders & UpdateMessageRequestQuery & UpdateMessageRequestParams

export type UpdateMessageRequest = {
  headers: UpdateMessageRequestHeaders;
  query: UpdateMessageRequestQuery;
  params: UpdateMessageRequestParams;
  body: UpdateMessageRequestBody;
}

export const parseReq = (input: UpdateMessageInput): UpdateMessageRequest & { path: string } => {
  return {
    path: `/v1/chat/messages/${encodeURIComponent(input['id'])}`,
    headers: {  },
    query: {  },
    params: { 'id': input['id'] },
    body: { 'tags': input['tags'] },
  }
}

export interface UpdateMessageResponse {
  /**
   * The Message object represents a message in a [Conversation](#schema_conversation) for a specific [User](#schema_user).
   */
  message: {
    /**
     * Id of the [Message](#schema_message)
     */
    id: string;
    /**
     * Creation date of the [Message](#schema_message) in ISO 8601 format
     */
    createdAt: string;
    /**
     * Type of the [Message](#schema_message) represents the resource type that the message is related to
     */
    type: string;
    /**
     * Payload is the content type of the message. Accepted payload options: Text, Image, Choice, Dropdown, Card, Carousel, File, Audio, Video, Location
     */
    payload: {
      [k: string]: any;
    };
    /**
     * Direction of the message (`incoming` or `outgoing`).
     */
    direction: "incoming" | "outgoing";
    /**
     * ID of the [User](#schema_user)
     */
    userId: string;
    /**
     * ID of the [Conversation](#schema_conversation)
     */
    conversationId: string;
    /**
     * Set of [Tags](/docs/developers/concepts/tags) that you can attach to a [Conversation](#schema_conversation). The set of [Tags](/docs/developers/concepts/tags) available on a [Conversation](#schema_conversation) is restricted by the list of [Tags](/docs/developers/concepts/tags) defined previously by the [Bot](#schema_bot). Individual keys can be unset by posting an empty value to them.
     */
    tags: {
      [k: string]: string;
    };
  };
}

