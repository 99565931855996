import { Select as RadixSelect } from '@radix-ui/themes'
import { ComponentProps, ReactNode } from 'react'

type SelectItem = {
  type: 'item'
  value: string
  leadingIcon?: ReactNode
  disabled?: boolean
  content: ReactNode
}

type SelectSeparator = {
  type: 'separator'
}

export type SelectMenuItems = SelectItem | SelectSeparator

type TriggerProps = Pick<ComponentProps<typeof RadixSelect.Trigger>, 'variant' | 'color' | 'radius'>
type ContentProps = Pick<
  ComponentProps<typeof RadixSelect.Content>,
  'variant' | 'color' | 'position' | 'side' | 'sideOffset'
>

type Props = {
  items: SelectMenuItems[]
} & Pick<
  ComponentProps<typeof RadixSelect.Root>,
  'value' | 'defaultValue' | 'onValueChange' | 'open' | 'onOpenChange' | 'disabled' | 'dir' | 'size' | 'autoComplete'
> & {
    triggerColor?: TriggerProps['color']
    triggerVariant?: TriggerProps['variant']
    triggerRadius?: TriggerProps['radius']
  } & ContentProps

export const Select = ({
  items,
  triggerColor,
  triggerVariant,
  triggerRadius,
  color,
  variant,
  position = 'popper',
  side,
  sideOffset,
  ...props
}: Props) => {
  return (
    <RadixSelect.Root {...props}>
      <RadixSelect.Trigger variant={triggerVariant} color={triggerColor} radius={triggerRadius} />
      <RadixSelect.Content {...{ color, variant, position, side, sideOffset }}>
        {items.map((item, index) => {
          if (item.type === 'separator') {
            return <RadixSelect.Separator key={index} />
          }
          return (
            <RadixSelect.Item
              key={item.value}
              value={item.value}
              disabled={item.disabled}
              className="flex-row-reverse justify-end gap-2 px-3 *:relative last:*:grow"
            >
              <span>{item.content}</span>
            </RadixSelect.Item>
          )
        })}
      </RadixSelect.Content>
    </RadixSelect.Root>
  )
}
