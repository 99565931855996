import { generateHtmlTagsWithScriptUrl } from '@botpress/webchat-generator'
import { Flex } from '@radix-ui/themes'
import { useQuery } from '@tanstack/react-query'
import { createFileRoute } from '@tanstack/react-router'
import { useEffect, useMemo } from 'react'
import { getApiClient } from '~/client'
import { CodeSnippet } from '~/components'
import { Spinner } from '~/elementsv2'
import { ConfigSection } from '~/features/webchat/components'
import { useWebchatConfig, useWebchatV2 } from '~/hooks'
import { config } from '~/shared'

export const Route = createFileRoute('/workspaces/$workspaceId/bots/$botId/webchat/share')({
  component: Component,
})

function Component() {
  const { botId, workspaceId } = Route.useParams()
  const { isWebchatV2Enabled } = useWebchatV2()
  const { config: webchatConfig, updateWebchatIntegration } = useWebchatConfig({ botId, workspaceId })

  const embedScripts = useMemo(
    () =>
      generateHtmlTagsWithScriptUrl({
        injectUrl: `${config.cdnBaseUrl}/webchat/v2.1/inject.js`,
        scriptUrl: `${config.mediaBaseUrl}/${botId}/webchat/v2.1/config.js`,
      }),
    [botId]
  )

  const shareableLink = useMemo(() => `${config.cdnBaseUrl}/webchat/v2.1/shareable.html?botId=${botId}`, [botId])

  // This is there to check is the file is in our S3 bucket, if not we create it by calling updateBot
  useEffect(() => {
    if (isWebchatV2Enabled) {
      fetch(`${config.mediaBaseUrl}/${botId}/webchat/v2.1/config.js`).then((response) => {
        if (!response.ok) {
          updateWebchatIntegration(webchatConfig, { showToast: false })
        }
      })
    }
  }, [])

  return (
    <Flex direction={'column'} gap={'6'}>
      <ConfigSection
        title="Shareable Link"
        description="Share the following link with people that would like to quickly test your chatbot."
        linkUrl={shareableLink}
        linkLabel="View"
      >
        {isWebchatV2Enabled ? (
          <CodeSnippet code={shareableLink} />
        ) : (
          <WebChatCode {...{ workspaceId, botId }} codeType="sharableUrl" />
        )}
      </ConfigSection>
      <ConfigSection
        title="Embed code"
        description="Copy and paste this code on your webpage."
        linkUrl="https://botpress.com/docs/developers/webchat-v2/embedded/"
        linkLabel="Documentation"
      >
        {isWebchatV2Enabled ? (
          <CodeSnippet code={embedScripts} />
        ) : (
          <WebChatCode {...{ workspaceId, botId }} codeType="preconfigured" />
        )}
      </ConfigSection>
    </Flex>
  )
}

/** @deprecated this is legacy code from v1 */
const WebChatCode = ({
  codeType,
  workspaceId,
  botId,
}: {
  codeType: 'preconfigured' | 'configurable' | 'sharableUrl'
  workspaceId: string
  botId: string
}) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['webchat', codeType],
    queryFn: () => getApiClient(workspaceId).getBotWebchat({ id: botId, type: codeType }),
  })

  if (isLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Spinner />
      </div>
    )
  }
  //TODO: make this look nicer
  if (isError) {
    return <CodeSnippet code={'Unable to load webchat code'} />
  }
  return <CodeSnippet code={data?.code ?? ''} />
}
