import { cn } from '~/utils'
import { Badge, Text } from '@radix-ui/themes'
import { useSuspenseQuery } from '@tanstack/react-query'
import { listAllIssuesQueryOptions } from '~/queries'
import { useParams } from '@tanstack/react-router'
import { match, P } from 'ts-pattern'

type Props = { className?: string }

export const NotificationBadge = ({ className, ...props }: Props) => {
  const looseParams = useParams({ strict: false })

  const { workspaceId, botId } = match(looseParams)
    .with({ workspaceId: P.string, botId: P.string }, (p) => p)
    .otherwise(() => ({ workspaceId: '', botId: '' }))

  const issueCount = useSuspenseQuery(listAllIssuesQueryOptions({ botId, workspaceId })).data.length

  return issueCount > 0 ? (
    <Badge size={'1'} color="red" variant="soft" {...props} className={cn('mt-0.5', className)}>
      <Text size={'1'}>{issueCount}</Text>
    </Badge>
  ) : null
}
