import React, { ComponentProps } from 'react'

type Props = ComponentProps<'svg'>

export const Changelog = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" {...props}>
      <title>Empty state Icon</title>
      <g strokeWidth="1.5" fill="none" fill-rule="evenodd">
        <g transform="translate(10 10)" stroke-linecap="round" stroke-linejoin="round">
          <g stroke="var(--accent-7)">
            <path d="M12 5h20M12 1h10M12 16h20M12 12h10M12 23h10M12 27h10" />
          </g>
          <rect stroke="var(--accent-10)" width="6" height="6" rx="3" />
          <rect stroke="var(--accent-10)" y="11" width="6" height="6" rx="3" />
          <rect stroke="var(--accent-10)" y="22" width="6" height="6" rx="3" />
        </g>
        <g stroke-linecap="round" stroke-linejoin="round">
          <path d="M44.05 36.013a8 8 0 110 16 8 8 0 010-16z" stroke="var(--accent-10)" />
          <path stroke="var(--accent-10)" d="M44 40v4h3" />
          <path d="M32 43H7c-1.228 0-2-.84-2-2V7c0-1.16.772-2 2-2h38c1.228 0 2 .84 2 2v25" stroke="var(--accent-7)" />
        </g>
        <path stroke="var(--accent-10)" d="M13.013 16v5M13.013 27v5" />
      </g>
    </svg>
  )
}
