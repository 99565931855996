import { createFileRoute } from '@tanstack/react-router'
import { Button, Card, PageSection, Spinner } from '~/elementsv2'
import { Badge, Flex, Grid, Text } from '@radix-ui/themes'
import { Color } from '~/types'
import { ReactNode } from 'react'
import { ColorAccentProvider } from '~/componentsV2'
import { cn } from '~/utils'
import { ThemeColor } from '~/providers/ThemeProvider'
import { ClientReturn } from 'botpress-client'
import { billingPlan } from '~/features/billing/constants'
import { HiOutlineCheck } from 'react-icons/hi2'
import { COMMUNITY_PLAN_PRODUCT_ID, TEAM_PLAN_PRODUCT_ID, useBilling } from '~/features/usage/useBilling'
import { getQueryOptions, showConfirmationDialog } from '~/services'
import { useCheckout } from '~/hooks'
import { computeTotalCost } from '~/features/billing/utils'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/workspaces/$workspaceId/settings/billing/plans')({
  component: Component,
})

function Component() {
  const { workspaceId } = Route.useParams()
  const billingProps = useBilling(workspaceId)

  //TODO: This spinner has been done the lazy way, it should be replaced with a skeleton loader
  return billingProps.isLoading ? (
    <Flex width={'100%'} align={'center'} justify={'center'} height={'9'}>
      <Spinner />
    </Flex>
  ) : (
    <PlanSelection {...billingProps} />
  )
}

type BillingProps = ReturnType<typeof useBilling>
const PlanSelection = (props: BillingProps) => {
  const { workspaceId } = Route.useParams()

  //Avoid using cache since we can't invalidate it when customer changes plan in stripe customer portal
  const { plan } = useSuspenseQuery({
    ...getQueryOptions('workspaces_/$workspaceId_', { workspaceId }),
    queryKey: [],
    gcTime: 0,
    staleTime: 0,
  }).data

  const { setProductQuantity, productQuantityMap, ...billingProps } = props

  const { mutate: handleCheckout } = useCheckout(workspaceId, {
    productQuantityMap,
    ...billingProps,
  })

  const teamPlanPrice = (productQuantityMap[TEAM_PLAN_PRODUCT_ID]?.productDetails.price.unit_amount ?? 0) / 100

  return (
    <PageSection title="Pricing plans" className="@container">
      <Grid gap={'6'} className="grid-cols-1 @3xl:grid-cols-3">
        <PlanCard
          plan="community"
          price="Free"
          action={
            <Button
              disabled={plan === 'community' || plan === 'enterprise'}
              variant="outline"
              onClick={() => {
                setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 1)
                setProductQuantity(TEAM_PLAN_PRODUCT_ID, 0)
                showConfirmationDialog({
                  confirmLabel: 'Update plan',
                  title: 'Do you want to proceed?',
                  content: (
                    <Text>
                      After updating to the Pay-as-you-go plan, your new monthly bill will be
                      <Text weight={'bold'}> ${computeTotalCost(productQuantityMap)}</Text> per month. Are you sure you
                      want to proceed?
                    </Text>
                  ),
                  onConfirm: () => {
                    handleCheckout()
                  },
                })
              }}
            >
              {plan === 'community' ? 'Current plan' : 'Update plan'}
            </Button>
          }
        />
        <PlanCard
          color={plan !== 'enterprise' ? 'green' : undefined}
          plan="team"
          badge={plan !== 'enterprise' ? 'Best value' : undefined}
          price={
            <Flex gap={'1'} align={'baseline'}>
              <Text>${teamPlanPrice}</Text>
              <Text size={'2'} color="gray" weight={'regular'}>
                /month
              </Text>
            </Flex>
          }
          action={
            <Button
              disabled={plan === 'team' || plan === 'enterprise'}
              onClick={() => {
                setProductQuantity(TEAM_PLAN_PRODUCT_ID, 1)
                setProductQuantity(COMMUNITY_PLAN_PRODUCT_ID, 0)
                showConfirmationDialog({
                  confirmLabel: 'Upgrade to Team plan',
                  title: 'Do you want to proceed?',
                  content: (
                    <Text>
                      After upgrading to the Team plan, your new monthly bill will be
                      <Text weight={'bold'}> ${computeTotalCost(productQuantityMap)}</Text> per month. Are you sure you
                      want to proceed?
                    </Text>
                  ),
                  onConfirm: () => {
                    handleCheckout()
                  },
                })
              }}
            >
              {plan === 'team' ? 'Current plan' : 'Buy plan'}
            </Button>
          }
        />
        <PlanCard
          plan="enterprise"
          color={plan === 'enterprise' ? 'iris' : undefined}
          badge={plan === 'enterprise' ? 'Current plan' : undefined}
          price="Custom"
          action={
            <a href="https://botpress.com/contact-us" target="_blank" rel="noreferrer">
              <Button className="w-full" variant="outline">
                Contact us
              </Button>
            </a>
          }
        />
      </Grid>
    </PageSection>
  )
}

type PlanCardProps = {
  color?: Color
  plan: ClientReturn<'getWorkspace'>['plan']
  badge?: ReactNode
  price?: ReactNode
  action: ReactNode
}

const PlanCard = ({ color, action, price, plan, badge }: PlanCardProps) => {
  return (
    <ColorAccentProvider color={color ?? ThemeColor}>
      <Card className={cn('p-7', { 'border-2 border-accent-6': color })}>
        <Flex direction={'column'} gap={'4'}>
          <Flex justify={'between'}>
            <Text weight={'medium'} className={cn({ 'text-accent-11': color })}>
              {billingPlan[plan].name}
            </Text>
            {badge && <Badge>{badge}</Badge>}
          </Flex>
          <Text size={'1'} color="gray">
            {billingPlan[plan].description}
          </Text>
          <Text size={'5'} weight={'bold'}>
            {price}
          </Text>
          {action}
          <Flex direction={'column'} gap={'3'}>
            {billingPlan[plan].inclusions.map((feature) => (
              <Flex key={feature} align={'start'} gap={'2'}>
                <HiOutlineCheck className="h-5 flex-none text-accent-9" />
                <Text size={'2'} color="gray">
                  {feature}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Card>
    </ColorAccentProvider>
  )
}
