import { Container, Flex, Separator, Text } from '@radix-ui/themes'
import { ReactNode } from 'react'

type Props = {
  title?: ReactNode
  actions?: ReactNode
  justify?: 'start' | 'center' | 'end' | 'between'
  children?: ReactNode
}

export const Page = ({ title, actions, children, justify = 'between' }: Props) => {
  return (
    <Container size={'4'} p={'5'}>
      <Flex align={'center'} justify={justify} gap={'4'}>
        <Text size={'4'} weight={'medium'}>
          {title}
        </Text>
        <Flex gap={'4'}>{actions}</Flex>
      </Flex>
      <Separator size={'4'} my={'2'} mb={'4'} />
      {children}
    </Container>
  )
}
