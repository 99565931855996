import React, { SyntheticEvent, useState } from 'react'
import { Button, Modal } from '../elements'
import { Variant as ButtonVariant } from '../elements/Button'

type ConfirmationModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  onConfirm: () => Promise<boolean | void> | boolean | void
  title: string
  dialogVariant?: 'info' | 'warning' | 'danger'
  okButtonText?: string
  okButtonVariant?: ButtonVariant
  cancelButtonText?: string
  cancelButtonVariant?: ButtonVariant
  buttonsDisabled?: boolean
}

/**
 * @deprecated Components in /components and deprecated in favor of componentsV2 or directly from Radix/themes if not in componentsV2
 */
export function ConfirmationModal({
  setOpen,
  open,
  onConfirm,
  title,
  children,
  okButtonVariant = 'primary',
  cancelButtonVariant = 'secondary',
  okButtonText = 'OK',
  cancelButtonText = 'Cancel',
  buttonsDisabled = false,
}: React.PropsWithChildren<ConfirmationModalProps>) {
  const [disabled, setDisabled] = useState(false)

  async function handleConfirm(event: SyntheticEvent): Promise<boolean | void> {
    event.preventDefault()
    setDisabled(true)

    try {
      const result = await onConfirm()
      setDisabled(typeof result === 'boolean' ? result : true)
      return result
    } catch (err) {
      setDisabled(false)
      return false
    }
  }

  return (
    <Modal open={open} setOpen={setOpen}>
      <Modal.Header>
        <h2 className="text-xl">{title}</h2>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <div />
        <div className="flex">
          <Button
            variant={cancelButtonVariant}
            className="mr-2"
            disabled={disabled || buttonsDisabled}
            onClick={() => setOpen(false)}
          >
            {cancelButtonText}
          </Button>
          <Button
            variant={okButtonVariant}
            disabled={disabled || buttonsDisabled}
            onClick={(e) => void handleConfirm(e)}
          >
            {okButtonText}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
