import { z } from 'zod'

const configSchema = z
  .object({
    composerPlaceholder: z.string().optional(),
    botConversationDescription: z.string().optional(),
    botId: z.string(),
    hostUrl: z.string(),
    messagingUrl: z.string(),
    clientId: z.string(),
    lazySocket: z.boolean().optional(),
    botName: z.string().optional(),
    avatarUrl: z.string().optional(),
    phoneNumber: z
      .string()
      .optional()
      .transform((val) => {
        if (val) {
          return { title: val, link: `tel:${val}` }
        }
      }),
    termsConditions: z
      .string()
      .optional()
      .transform((val) => {
        if (val) {
          return { title: 'Terms of service', link: val }
        }
      }),
    privacyPolicy: z
      .string()
      .optional()
      .transform((val) => {
        if (val) {
          return { title: 'Privacy policy', link: val }
        }
      }),
    emailAddress: z
      .string()
      .optional()
      .transform((val) => {
        if (val) {
          return { title: val, link: `mailto:${val}` }
        }
      }),
    website: z
      .string()
      .optional()
      .transform((val) => {
        if (val) {
          return { title: val, link: val }
        }
      }),
    theme: z.enum(['prism', 'galaxy', 'dusk', 'eggplant', 'dawn', 'midnight']).catch(() => 'prism' as const),
    themeColor: z.string().optional(),
    showPoweredBy: z.boolean().optional(),
  })
  .transform((val) => {
    return {
      ...val,
      ...(val.avatarUrl && { botAvatar: val.avatarUrl }),
      ...(val.botConversationDescription && { botDescription: val.botConversationDescription }),
      ...(val.emailAddress && { email: val.emailAddress }),
      ...(val.termsConditions && { termsOfService: val.termsConditions }),
      ...(val.phoneNumber && { phone: val.phoneNumber }),
    }
  })

export type WebchatConfig = z.infer<typeof configSchema>
export function parseWebchatScript(script: string) {
  const initRegex = /window\.botpressWebChat\.init\(([\s\S]*?)\);/gm
  const match = initRegex.exec(script)

  if (match?.[1]) {
    const initObject = JSON.parse(match[1])
    const config = configSchema.parse(initObject)
    return config
  }

  return
}
