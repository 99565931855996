import { Link, createFileRoute } from '@tanstack/react-router'
import { Button, List, EmptyState } from '~/elementsv2'
import { Badge, Container, Flex, Text } from '@radix-ui/themes'
import { Boundrary, UserAvatar } from '~/componentsV2'
import { auditMessages } from '~/features/audits/components'
import { DateTime } from 'luxon'
import { Color, Variant } from '~/types'
import { Changelog } from '~/assets'
import { Skeleton } from '~/elements'
import { ClientReturn } from 'botpress-client'
import { useSuspenseInfiniteQuery } from '@tanstack/react-query'
import { listAuditsInfiniteQueryOptions } from '~/queries'
import { useInfiniteScroll } from '~/hooks/useInfiniteScroll'

export const Route = createFileRoute('/workspaces/$workspaceId/audits')({
  component: Component,
})

function Component() {
  return (
    <Container size={'3'} pt={'8'}>
      <Boundrary className="h-96" suspenseFallback={<List items={Array(15).fill(null)}>{<AuditRowSkeleton />}</List>}>
        <Audit />
      </Boundrary>
    </Container>
  )
}

const Audit = () => {
  const { workspaceId } = Route.useParams()
  const {
    data: fetchedAudits,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useSuspenseInfiniteQuery(listAuditsInfiniteQueryOptions({ workspaceId }))

  const bottomRef = useInfiniteScroll(fetchNextPage, hasNextPage)

  const audits = fetchedAudits?.pages.flatMap((p) => p.records)

  return audits.length ? (
    <Flex direction={'column'} gap={'4'}>
      <List items={audits}>{(audit) => <AuditRow record={audit} />}</List>
      <div ref={bottomRef} />
      {isFetching && <AuditRowSkeleton />}
    </Flex>
  ) : (
    <EmptyState
      p={'8'}
      iconSize={6}
      icon={Changelog}
      title="You don't have any audits"
      description="You have not performed any actions that are audited. Keep using Botpress to see your actions here."
      primaryAction={
        <Link to="/workspaces/$workspaceId" params={{ workspaceId }}>
          <Button size={'2'}>Back to workspace</Button>
        </Link>
      }
    />
  )
}

const AuditRow = ({ record }: { record: ClientReturn<'getAuditRecords'>['records'][number] }) => {
  const message = auditMessages[record.action] ?? auditMessages.UNKNOWN
  const { workspaceId } = Route.useParams()
  const auditDate = DateTime.fromISO(record.recordedAt).toLocaleString(DateTime.DATETIME_MED)

  return (
    <Flex align={'center'} p={'4'} gap={'4'}>
      <UserAvatar size={'2'} userId={record.userId ?? undefined} workspaceId={workspaceId} />
      <Flex direction={'column'} gap={'1'}>
        <Text size={'2'} color="gray">
          <b>{record.userEmail}</b> {message.render(record)}
        </Text>
        <Text color="gray" size={'1'}>
          {auditDate}
        </Text>
      </Flex>
      <Badge ml={'auto'} variant="surface" color={variantToColor(message.variant)}>
        {message.label}
      </Badge>
    </Flex>
  )
}

const AuditRowSkeleton = () => {
  return (
    <Flex align={'center'} p={'4'} gap={'4'}>
      <Skeleton height={32} width={32} />
      <Flex direction={'column'} gap={'2'}>
        <Skeleton height={8} width={400} />
        <Skeleton height={8} width={180} />
      </Flex>
      <Skeleton className="ml-auto" width={96} />
    </Flex>
  )
}

function variantToColor(variant: Variant): Color {
  switch (variant) {
    case 'default':
      return 'gray' as const
    case 'info':
      return 'blue' as const
    case 'success':
      return 'green' as const
    case 'warning':
      return 'yellow' as const
    case 'danger':
      return 'red' as const
    default:
      return 'gray' as const
  }
}
